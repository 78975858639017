.input-lg, .right-to-left .input-group-lg > .form-control,
.right-to-left .input-group-lg > .input-group-addon,
.right-to-left .input-group-lg > .input-group-btn > .btn {
  height: 45px;
  padding: 10px 16px;
  font-size: 17px;
  line-height: 1.33;
  border-radius: 3px; }

select.input-lg, .right-to-left .input-group-lg > select.form-control,
.right-to-left .input-group-lg > select.input-group-addon,
.right-to-left .input-group-lg > .input-group-btn > select.btn {
  height: 45px;
  line-height: 45px; }

textarea.input-lg, .right-to-left .input-group-lg > textarea.form-control,
.right-to-left .input-group-lg > textarea.input-group-addon,
.right-to-left .input-group-lg > .input-group-btn > textarea.btn,
select[multiple].input-lg,
.right-to-left .input-group-lg > select[multiple].form-control,
.right-to-left .input-group-lg > select[multiple].input-group-addon,
.right-to-left .input-group-lg > .input-group-btn > select[multiple].btn {
  height: auto; }

.input-sm, .right-to-left .input-group-sm > .form-control,
.right-to-left .input-group-sm > .input-group-addon,
.right-to-left .input-group-sm > .input-group-btn > .btn {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 2px; }

select.input-sm, .right-to-left .input-group-sm > select.form-control,
.right-to-left .input-group-sm > select.input-group-addon,
.right-to-left .input-group-sm > .input-group-btn > select.btn {
  height: 30px;
  line-height: 30px; }

textarea.input-sm, .right-to-left .input-group-sm > textarea.form-control,
.right-to-left .input-group-sm > textarea.input-group-addon,
.right-to-left .input-group-sm > .input-group-btn > textarea.btn,
select[multiple].input-sm,
.right-to-left .input-group-sm > select[multiple].form-control,
.right-to-left .input-group-sm > select[multiple].input-group-addon,
.right-to-left .input-group-sm > .input-group-btn > select[multiple].btn {
  height: auto; }

.right-to-left {
  direction: rtl !important;
  /******************************************************************
   ******************************************************************
   ******************************************************************/ }
  .right-to-left .pull-right {
    float: left !important; }
  .right-to-left .pull-left {
    float: right !important; }
  .right-to-left .col-xs-1,
  .right-to-left .col-xs-2,
  .right-to-left .col-xs-3,
  .right-to-left .col-xs-4,
  .right-to-left .col-xs-5,
  .right-to-left .col-xs-6,
  .right-to-left .col-xs-7,
  .right-to-left .col-xs-8,
  .right-to-left .col-xs-9,
  .right-to-left .col-xs-10,
  .right-to-left .col-xs-11 {
    float: right; }
  @media (min-width: 768px) {
    .right-to-left .col-sm-1,
    .right-to-left .col-sm-2,
    .right-to-left .col-sm-3,
    .right-to-left .col-sm-4,
    .right-to-left .col-sm-5,
    .right-to-left .col-sm-6,
    .right-to-left .col-sm-7,
    .right-to-left .col-sm-8,
    .right-to-left .col-sm-9,
    .right-to-left .col-sm-10,
    .right-to-left .col-sm-11 {
      float: right; }
    .right-to-left .col-sm-push-1,
    .right-to-left .col-sm-push-2,
    .right-to-left .col-sm-push-3,
    .right-to-left .col-sm-push-4,
    .right-to-left .col-sm-push-5,
    .right-to-left .col-sm-push-6,
    .right-to-left .col-sm-push-7,
    .right-to-left .col-sm-push-8,
    .right-to-left .col-sm-push-9,
    .right-to-left .col-sm-push-10,
    .right-to-left .col-sm-push-11,
    .right-to-left .col-sm-pull-1,
    .right-to-left .col-sm-pull-2,
    .right-to-left .col-sm-pull-3,
    .right-to-left .col-sm-pull-4,
    .right-to-left .col-sm-pull-5,
    .right-to-left .col-sm-pull-6,
    .right-to-left .col-sm-pull-7,
    .right-to-left .col-sm-pull-8,
    .right-to-left .col-sm-pull-9,
    .right-to-left .col-sm-pull-10,
    .right-to-left .col-sm-pull-11 {
      left: auto;
      right: auto; }
    .right-to-left .col-sm-offset-1 {
      margin-left: 0; }
    .right-to-left .col-sm-offset-2 {
      margin-left: 0; }
    .right-to-left .col-sm-offset-3 {
      margin-left: 0; }
    .right-to-left .col-sm-offset-4 {
      margin-left: 0; }
    .right-to-left .col-sm-offset-5 {
      margin-left: 0; }
    .right-to-left .col-sm-offset-6 {
      margin-left: 0; }
    .right-to-left .col-sm-offset-7 {
      margin-left: 0; }
    .right-to-left .col-sm-offset-8 {
      margin-left: 0; }
    .right-to-left .col-sm-offset-9 {
      margin-left: 0; }
    .right-to-left .col-sm-offset-10 {
      margin-left: 0; }
    .right-to-left .col-sm-offset-11 {
      margin-left: 0; }
    .right-to-left .col-sm-push-1 {
      right: 8.33333%; }
    .right-to-left .col-sm-push-2 {
      right: 16.66667%; }
    .right-to-left .col-sm-push-3 {
      right: 25%; }
    .right-to-left .col-sm-push-4 {
      right: 33.33333%; }
    .right-to-left .col-sm-push-5 {
      right: 41.66667%; }
    .right-to-left .col-sm-push-6 {
      right: 50%; }
    .right-to-left .col-sm-push-7 {
      right: 58.33333%; }
    .right-to-left .col-sm-push-8 {
      right: 66.66667%; }
    .right-to-left .col-sm-push-9 {
      right: 75%; }
    .right-to-left .col-sm-push-10 {
      right: 83.33333%; }
    .right-to-left .col-sm-push-11 {
      right: 91.66667%; }
    .right-to-left .col-sm-pull-1 {
      left: 8.33333%; }
    .right-to-left .col-sm-pull-2 {
      left: 16.66667%; }
    .right-to-left .col-sm-pull-3 {
      left: 25%; }
    .right-to-left .col-sm-pull-4 {
      left: 33.33333%; }
    .right-to-left .col-sm-pull-5 {
      left: 41.66667%; }
    .right-to-left .col-sm-pull-6 {
      left: 50%; }
    .right-to-left .col-sm-pull-7 {
      left: 58.33333%; }
    .right-to-left .col-sm-pull-8 {
      left: 66.66667%; }
    .right-to-left .col-sm-pull-9 {
      left: 75%; }
    .right-to-left .col-sm-pull-10 {
      left: 83.33333%; }
    .right-to-left .col-sm-pull-11 {
      left: 91.66667%; }
    .right-to-left .col-sm-offset-1 {
      margin-right: 8.33333%; }
    .right-to-left .col-sm-offset-2 {
      margin-right: 16.66667%; }
    .right-to-left .col-sm-offset-3 {
      margin-right: 25%; }
    .right-to-left .col-sm-offset-4 {
      margin-right: 33.33333%; }
    .right-to-left .col-sm-offset-5 {
      margin-right: 41.66667%; }
    .right-to-left .col-sm-offset-6 {
      margin-right: 50%; }
    .right-to-left .col-sm-offset-7 {
      margin-right: 58.33333%; }
    .right-to-left .col-sm-offset-8 {
      margin-right: 66.66667%; }
    .right-to-left .col-sm-offset-9 {
      margin-right: 75%; }
    .right-to-left .col-sm-offset-10 {
      margin-right: 83.33333%; }
    .right-to-left .col-sm-offset-11 {
      margin-right: 91.66667%; } }
  @media (min-width: 992px) {
    .right-to-left .col-md-1,
    .right-to-left .col-md-2,
    .right-to-left .col-md-3,
    .right-to-left .col-md-4,
    .right-to-left .col-md-5,
    .right-to-left .col-md-6,
    .right-to-left .col-md-7,
    .right-to-left .col-md-8,
    .right-to-left .col-md-9,
    .right-to-left .col-md-10,
    .right-to-left .col-md-11 {
      float: right; }
    .right-to-left .col-md-push-1,
    .right-to-left .col-md-push-2,
    .right-to-left .col-md-push-3,
    .right-to-left .col-md-push-4,
    .right-to-left .col-md-push-5,
    .right-to-left .col-md-push-6,
    .right-to-left .col-md-push-7,
    .right-to-left .col-md-push-8,
    .right-to-left .col-md-push-9,
    .right-to-left .col-md-push-10,
    .right-to-left .col-md-push-11,
    .right-to-left .col-md-pull-1,
    .right-to-left .col-md-pull-2,
    .right-to-left .col-md-pull-3,
    .right-to-left .col-md-pull-4,
    .right-to-left .col-md-pull-5,
    .right-to-left .col-md-pull-6,
    .right-to-left .col-md-pull-7,
    .right-to-left .col-md-pull-8,
    .right-to-left .col-md-pull-9,
    .right-to-left .col-md-pull-10,
    .right-to-left .col-md-pull-11 {
      left: auto;
      right: auto; }
    .right-to-left .col-md-offset-1 {
      margin-left: 0; }
    .right-to-left .col-md-offset-2 {
      margin-left: 0; }
    .right-to-left .col-md-offset-3 {
      margin-left: 0; }
    .right-to-left .col-md-offset-4 {
      margin-left: 0; }
    .right-to-left .col-md-offset-5 {
      margin-left: 0; }
    .right-to-left .col-md-offset-6 {
      margin-left: 0; }
    .right-to-left .col-md-offset-7 {
      margin-left: 0; }
    .right-to-left .col-md-offset-8 {
      margin-left: 0; }
    .right-to-left .col-md-offset-9 {
      margin-left: 0; }
    .right-to-left .col-md-offset-10 {
      margin-left: 0; }
    .right-to-left .col-md-offset-11 {
      margin-left: 0; }
    .right-to-left .col-md-push-0 {
      right: auto; }
    .right-to-left .col-md-push-1 {
      right: 8.33333%; }
    .right-to-left .col-md-push-2 {
      right: 16.66667%; }
    .right-to-left .col-md-push-3 {
      right: 25%; }
    .right-to-left .col-md-push-4 {
      right: 33.33333%; }
    .right-to-left .col-md-push-5 {
      right: 41.66667%; }
    .right-to-left .col-md-push-6 {
      right: 50%; }
    .right-to-left .col-md-push-7 {
      right: 58.33333%; }
    .right-to-left .col-md-push-8 {
      right: 66.66667%; }
    .right-to-left .col-md-push-9 {
      right: 75%; }
    .right-to-left .col-md-push-10 {
      right: 83.33333%; }
    .right-to-left .col-md-push-11 {
      right: 91.66667%; }
    .right-to-left .col-md-pull-0 {
      left: auto; }
    .right-to-left .col-md-pull-1 {
      left: 8.33333%; }
    .right-to-left .col-md-pull-2 {
      left: 16.66667%; }
    .right-to-left .col-md-pull-3 {
      left: 25%; }
    .right-to-left .col-md-pull-4 {
      left: 33.33333%; }
    .right-to-left .col-md-pull-5 {
      left: 41.66667%; }
    .right-to-left .col-md-pull-6 {
      left: 50%; }
    .right-to-left .col-md-pull-7 {
      left: 58.33333%; }
    .right-to-left .col-md-pull-8 {
      left: 66.66667%; }
    .right-to-left .col-md-pull-9 {
      left: 75%; }
    .right-to-left .col-md-pull-10 {
      left: 83.33333%; }
    .right-to-left .col-md-pull-11 {
      left: 91.66667%; }
    .right-to-left .col-md-offset-0 {
      margin-right: 0; }
    .right-to-left .col-md-offset-1 {
      margin-right: 8.33333%; }
    .right-to-left .col-md-offset-2 {
      margin-right: 16.66667%; }
    .right-to-left .col-md-offset-3 {
      margin-right: 25%; }
    .right-to-left .col-md-offset-4 {
      margin-right: 33.33333%; }
    .right-to-left .col-md-offset-5 {
      margin-right: 41.66667%; }
    .right-to-left .col-md-offset-6 {
      margin-right: 50%; }
    .right-to-left .col-md-offset-7 {
      margin-right: 58.33333%; }
    .right-to-left .col-md-offset-8 {
      margin-right: 66.66667%; }
    .right-to-left .col-md-offset-9 {
      margin-right: 75%; }
    .right-to-left .col-md-offset-10 {
      margin-right: 83.33333%; }
    .right-to-left .col-md-offset-11 {
      margin-right: 91.66667%; } }
  @media (min-width: 1200px) {
    .right-to-left .col-lg-1,
    .right-to-left .col-lg-2,
    .right-to-left .col-lg-3,
    .right-to-left .col-lg-4,
    .right-to-left .col-lg-5,
    .right-to-left .col-lg-6,
    .right-to-left .col-lg-7,
    .right-to-left .col-lg-8,
    .right-to-left .col-lg-9,
    .right-to-left .col-lg-10,
    .right-to-left .col-lg-11 {
      float: right; }
    .right-to-left .col-lg-push-1,
    .right-to-left .col-lg-push-2,
    .right-to-left .col-lg-push-3,
    .right-to-left .col-lg-push-4,
    .right-to-left .col-lg-push-5,
    .right-to-left .col-lg-push-6,
    .right-to-left .col-lg-push-7,
    .right-to-left .col-lg-push-8,
    .right-to-left .col-lg-push-9,
    .right-to-left .col-lg-push-10,
    .right-to-left .col-lg-push-11,
    .right-to-left .col-lg-pull-1,
    .right-to-left .col-lg-pull-2,
    .right-to-left .col-lg-pull-3,
    .right-to-left .col-lg-pull-4,
    .right-to-left .col-lg-pull-5,
    .right-to-left .col-lg-pull-6,
    .right-to-left .col-lg-pull-7,
    .right-to-left .col-lg-pull-8,
    .right-to-left .col-lg-pull-9,
    .right-to-left .col-lg-pull-10,
    .right-to-left .col-lg-pull-11 {
      left: auto;
      right: auto; }
    .right-to-left .col-lg-offset-1 {
      margin-left: 0; }
    .right-to-left .col-lg-offset-2 {
      margin-left: 0; }
    .right-to-left .col-lg-offset-3 {
      margin-left: 0; }
    .right-to-left .col-lg-offset-4 {
      margin-left: 0; }
    .right-to-left .col-lg-offset-5 {
      margin-left: 0; }
    .right-to-left .col-lg-offset-6 {
      margin-left: 0; }
    .right-to-left .col-lg-offset-7 {
      margin-left: 0; }
    .right-to-left .col-lg-offset-8 {
      margin-left: 0; }
    .right-to-left .col-lg-offset-9 {
      margin-left: 0; }
    .right-to-left .col-lg-offset-10 {
      margin-left: 0; }
    .right-to-left .col-lg-offset-11 {
      margin-left: 0; }
    .right-to-left .col-lg-push-0 {
      right: auto; }
    .right-to-left .col-lg-push-1 {
      right: 8.33333%; }
    .right-to-left .col-lg-push-2 {
      right: 16.66667%; }
    .right-to-left .col-lg-push-3 {
      right: 25%; }
    .right-to-left .col-lg-push-4 {
      right: 33.33333%; }
    .right-to-left .col-lg-push-5 {
      right: 41.66667%; }
    .right-to-left .col-lg-push-6 {
      right: 50%; }
    .right-to-left .col-lg-push-7 {
      right: 58.33333%; }
    .right-to-left .col-lg-push-8 {
      right: 66.66667%; }
    .right-to-left .col-lg-push-9 {
      right: 75%; }
    .right-to-left .col-lg-push-10 {
      right: 83.33333%; }
    .right-to-left .col-lg-push-11 {
      right: 91.66667%; }
    .right-to-left .col-lg-pull-0 {
      left: auto; }
    .right-to-left .col-lg-pull-1 {
      left: 8.33333%; }
    .right-to-left .col-lg-pull-2 {
      left: 16.66667%; }
    .right-to-left .col-lg-pull-3 {
      left: 25%; }
    .right-to-left .col-lg-pull-4 {
      left: 33.33333%; }
    .right-to-left .col-lg-pull-5 {
      left: 41.66667%; }
    .right-to-left .col-lg-pull-6 {
      left: 50%; }
    .right-to-left .col-lg-pull-7 {
      left: 58.33333%; }
    .right-to-left .col-lg-pull-8 {
      left: 66.66667%; }
    .right-to-left .col-lg-pull-9 {
      left: 75%; }
    .right-to-left .col-lg-pull-10 {
      left: 83.33333%; }
    .right-to-left .col-lg-pull-11 {
      left: 91.66667%; }
    .right-to-left .col-lg-offset-0 {
      margin-right: 0; }
    .right-to-left .col-lg-offset-1 {
      margin-right: 8.33333%; }
    .right-to-left .col-lg-offset-2 {
      margin-right: 16.66667%; }
    .right-to-left .col-lg-offset-3 {
      margin-right: 25%; }
    .right-to-left .col-lg-offset-4 {
      margin-right: 33.33333%; }
    .right-to-left .col-lg-offset-5 {
      margin-right: 41.66667%; }
    .right-to-left .col-lg-offset-6 {
      margin-right: 50%; }
    .right-to-left .col-lg-offset-7 {
      margin-right: 58.33333%; }
    .right-to-left .col-lg-offset-8 {
      margin-right: 66.66667%; }
    .right-to-left .col-lg-offset-9 {
      margin-right: 75%; }
    .right-to-left .col-lg-offset-10 {
      margin-right: 83.33333%; }
    .right-to-left .col-lg-offset-11 {
      margin-right: 91.66667%; } }
  .right-to-left .breadcrumb > li + li:before {
    content: "\f053";
    left: 1px; }
  .right-to-left .breadcrumb li.active {
    padding-left: 0;
    padding-right: 15px; }
    .right-to-left .breadcrumb li.active:before {
      left: auto;
      right: -1px; }
  .right-to-left .breadcrumb-label {
    float: right;
    padding-left: 15px;
    padding-right: 0; }
  .right-to-left #main-navbar .navbar-brand {
    padding: 0 14px 0 0; }
  .right-to-left #main-navbar .navbar-toggle .navbar-icon {
    float: right; }
  .right-to-left #main-navbar .navbar-toggle {
    left: 0;
    right: auto; }
  .right-to-left #main-navbar .navbar-nav {
    padding: 0; }
  .right-to-left #main-navbar .navbar-nav.pull-right {
    float: none !important; }
  .right-to-left #main-navbar .dropdown > a:after {
    float: left; }
  .right-to-left #main-navbar .user-menu > span {
    margin-left: 0;
    margin-right: 7px; }
  .right-to-left #main-navbar #main-menu-toggle .navbar-icon, .right-to-left.mme.main-menu-right #main-navbar #main-menu-toggle .navbar-icon {
    left: 26px; }
  .right-to-left #main-navbar #main-menu-toggle .navbar-icon:after, .right-to-left.mme.main-menu-right #main-navbar #main-menu-toggle .navbar-icon:after {
    content: "\f0d9";
    right: 16px; }
  .right-to-left.mme #main-navbar #main-menu-toggle .navbar-icon, .right-to-left.main-menu-right #main-navbar #main-menu-toggle .navbar-icon {
    left: 20px; }
  .right-to-left.mme #main-navbar #main-menu-toggle .navbar-icon:after, .right-to-left.main-menu-right #main-navbar #main-menu-toggle .navbar-icon:after {
    content: "\f0da";
    right: -8px; }
  @media (min-width: 768px) {
    .right-to-left #main-navbar .navbar-header {
      float: right; }
    .right-to-left #main-navbar .navbar-brand {
      padding-left: 14px; }
    .right-to-left #main-navbar .navbar-nav {
      float: right; }
      .right-to-left #main-navbar .navbar-nav > li {
        float: right; }
    .right-to-left #main-navbar .navbar-nav.pull-right {
      float: left !important; }
    .right-to-left #main-navbar .right {
      padding-left: 16px;
      padding-right: 0; }
    .right-to-left #main-navbar .navbar-collapse {
      overflow: hidden;
      float: none; }
    .right-to-left #main-navbar .dropdown > .dropdown-menu {
      right: 0;
      left: auto; }
    .right-to-left #main-navbar .right .dropdown > .dropdown-menu {
      right: auto;
      left: 0; }
    .right-to-left #main-navbar #main-menu-toggle .navbar-icon, .right-to-left.mme #main-navbar #main-menu-toggle .navbar-icon, .right-to-left.mmc.main-menu-right #main-navbar #main-menu-toggle .navbar-icon, .right-to-left.mme.mmc.main-menu-right #main-navbar #main-menu-toggle .navbar-icon {
      left: 20px; }
    .right-to-left #main-navbar #main-menu-toggle .navbar-icon:after, .right-to-left.mme #main-navbar #main-menu-toggle .navbar-icon:after, .right-to-left.mmc.main-menu-right #main-navbar #main-menu-toggle .navbar-icon:after, .right-to-left.mme.mmc.main-menu-right #main-navbar #main-menu-toggle .navbar-icon:after {
      content: "\f0da";
      right: -8px; }
    .right-to-left.mmc #main-navbar #main-menu-toggle .navbar-icon, .right-to-left.mme.mmc #main-navbar #main-menu-toggle .navbar-icon, .right-to-left.main-menu-right #main-navbar #main-menu-toggle .navbar-icon, .right-to-left.mme.main-menu-right #main-navbar #main-menu-toggle .navbar-icon {
      left: 26px; }
    .right-to-left.mmc #main-navbar #main-menu-toggle .navbar-icon:after, .right-to-left.mme.mmc #main-navbar #main-menu-toggle .navbar-icon:after, .right-to-left.main-menu-right #main-navbar #main-menu-toggle .navbar-icon:after, .right-to-left.mme.main-menu-right #main-navbar #main-menu-toggle .navbar-icon:after {
      content: "\f0d9";
      right: 16px; } }
  .right-to-left #main-menu .navigation a,
  .right-to-left #main-menu .mmc-dropdown-open-ul a {
    text-align: right; }
  .right-to-left #main-menu .navigation .menu-icon,
  .right-to-left #main-menu .mmc-dropdown-open-ul .menu-icon {
    margin-left: 5px;
    margin-right: 0; }
  .right-to-left #main-menu .navigation .badge,
  .right-to-left #main-menu .navigation .label,
  .right-to-left #main-menu .mmc-dropdown-open-ul .badge,
  .right-to-left #main-menu .mmc-dropdown-open-ul .label {
    margin-left: 0;
    margin-right: 5px;
    float: left; }
  .right-to-left #main-menu .mm-dropdown > a {
    padding-left: 30px !important;
    padding-right: 14px; }
    .right-to-left #main-menu .mm-dropdown > a:before {
      content: "\f104";
      left: 14px;
      right: auto; }
  .right-to-left #main-menu .mm-dropdown ul .menu-icon {
    margin-right: -5px; }
  .right-to-left #main-menu .mm-dropdown > ul > li > a {
    padding-left: 14px;
    padding-right: 40px; }
  .right-to-left #main-menu .mm-dropdown > ul > .mm-dropdown > ul > li > a {
    padding-left: 14px;
    padding-right: 60px; }
  .right-to-left #main-menu .mm-dropdown > ul > .mm-dropdown > ul > .mm-dropdown > ul > li > a {
    padding-left: 14px;
    padding-right: 80px; }
  .right-to-left #main-menu .mm-dropdown > ul > .mm-dropdown > ul > .mm-dropdown > ul > .mm-dropdown > ul > li > a {
    padding-left: 14px;
    padding-right: 100px; }
  .right-to-left #main-menu .mm-dropdown > ul > .mm-dropdown > ul > .mm-dropdown > ul > .mm-dropdown > ul > .mm-dropdown > ul > li > a {
    padding-left: 14px;
    padding-right: 120px; }
  .right-to-left #main-menu .mm-dropdown.open > a:before {
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg); }
  .right-to-left #main-menu .mmc-dropdown-open-ul {
    left: auto;
    right: 100%; }
  @media (min-width: 480px) {
    .right-to-left #main-menu .navigation > li > a {
      text-align: center;
      padding-left: 0 !important;
      padding-right: 0 !important; }
      .right-to-left #main-menu .navigation > li > a > .menu-icon {
        margin: 0; }
    .right-to-left #main-menu .navigation > .mm-dropdown > a {
      padding-left: 14px; }
      .right-to-left #main-menu .navigation > .mm-dropdown > a:before {
        left: 7px; }
    .right-to-left #main-menu .navigation > .mm-dropdown.open > a:before {
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg); }
    .right-to-left.main-menu-right #main-menu .navigation > .mm-dropdown > a:before {
      content: "\f105";
      left: auto;
      right: 7px; }
    .right-to-left.mme #main-menu .navigation > li > a {
      text-align: right; }
      .right-to-left.mme #main-menu .navigation > li > a:before {
        left: 14px; }
      .right-to-left.mme #main-menu .navigation > li > a > .menu-icon {
        margin-left: 5px; }
    .right-to-left.mme #main-menu .navigation > .mm-dropdown > a {
      padding-left: 30px;
      padding-right: 14px; }
      .right-to-left.mme #main-menu .navigation > .mm-dropdown > a:before {
        content: "\f104";
        left: 14px;
        right: auto; }
    .right-to-left.mme #main-menu .navigation > .mm-dropdown.open > a:before {
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg); } }
  @media (min-width: 768px) {
    .right-to-left #main-menu ul.navigation > li > a {
      text-align: right; }
      .right-to-left #main-menu ul.navigation > li > a:before {
        right: auto;
        left: 14px; }
      .right-to-left #main-menu ul.navigation > li > a > .menu-icon {
        margin-left: 5px; }
    .right-to-left #main-menu ul.navigation > .mm-dropdown > a {
      padding-left: 30px;
      padding-right: 14px; }
      .right-to-left #main-menu ul.navigation > .mm-dropdown > a:before {
        content: "\f104";
        left: 14px;
        right: auto; }
    .right-to-left #main-menu ul.navigation > .mm-dropdown.open > a:before {
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg); }
    .right-to-left.mmc #main-menu ul.navigation > li > a {
      text-align: center;
      padding-left: 0 !important;
      padding-right: 0 !important; }
      .right-to-left.mmc #main-menu ul.navigation > li > a:before {
        right: auto;
        left: 7px; }
      .right-to-left.mmc #main-menu ul.navigation > li > a > .menu-icon {
        margin: 0; }
    .right-to-left.mmc #main-menu ul.navigation > .mm-dropdown > a {
      padding-left: 14px; }
    .right-to-left.mmc #main-menu ul.navigation > .mm-dropdown.open > a:before {
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg); }
    .right-to-left.mme.mmc #main-menu ul.navigation > .mm-dropdown > a:before {
      left: 7px; }
    .right-to-left.main-menu-right #main-menu ul.navigation > .mm-dropdown > a:before {
      content: "\f104";
      left: 14px;
      right: auto; }
    .right-to-left.mmc.main-menu-right #main-menu ul.navigation > .mm-dropdown > a:before, .right-to-left.mmc.mme.main-menu-right #main-menu ul.navigation > .mm-dropdown > a:before {
      content: "\f105";
      left: auto;
      right: 7px; } }
  .right-to-left #main-menu,
  .right-to-left #main-menu-bg {
    left: auto;
    right: -80%; }
  .right-to-left #main-navbar .navbar-header {
    padding-left: 0;
    padding-right: 56px; }
  .right-to-left #main-navbar .navbar-inner,
  .right-to-left #content-wrapper {
    left: auto;
    right: 0%; }
  .right-to-left.mme #main-navbar .navbar-inner, .right-to-left.mme #content-wrapper {
    left: auto;
    right: 80%; }
  .right-to-left.mme #main-navbar .navbar-header {
    padding-right: 0; }
  .right-to-left.mme #main-menu, .right-to-left.mme #main-menu-bg {
    left: auto;
    right: 0%; }
  .right-to-left #main-navbar #main-menu-toggle {
    left: auto;
    right: 0; }
  @media (min-width: 480px) {
    .right-to-left #main-wrapper {
      padding-left: 0;
      padding-right: 56px; }
    .right-to-left #main-menu-bg {
      right: 0; }
    .right-to-left #main-menu {
      right: 0; }
      .right-to-left #main-menu .mmc-dropdown-open-ul {
        left: auto;
        right: 100%; }
    .right-to-left.mme #content-wrapper {
      left: 0;
      right: 184px; }
    .right-to-left.mme #main-navbar .navbar-inner {
      right: 240px; }
    .right-to-left.mme #main-navbar #main-menu-toggle {
      width: 240px; } }
  @media (min-width: 768px) {
    .right-to-left.mme #main-wrapper,
    .right-to-left #main-wrapper {
      padding-left: 0;
      padding-right: 240px; }
    .right-to-left.mme #content-wrapper {
      right: 0; }
    .right-to-left.mme #main-navbar .navbar-inner {
      right: 0; }
    .right-to-left.mmc #main-wrapper, .right-to-left.mme.mmc #main-wrapper {
      padding-left: 0;
      padding-right: 56px; }
    .right-to-left #main-navbar .navbar-header {
      padding-left: 0 !important;
      padding-right: 56px !important; } }
  .right-to-left.main-menu-right #main-menu-bg {
    left: -80%;
    right: auto; }
  .right-to-left.main-menu-right #main-menu {
    left: -80%;
    right: auto; }
    .right-to-left.main-menu-right #main-menu .mmc-dropdown-open-ul {
      right: auto;
      left: 100%; }
  .right-to-left.main-menu-right #main-navbar .navbar-header {
    padding-right: 0; }
  .right-to-left.main-menu-right #main-navbar .navbar-inner {
    left: 0;
    right: auto; }
  .right-to-left.main-menu-right #main-navbar #main-menu-toggle {
    left: 0;
    right: auto; }
  .right-to-left.main-menu-right #main-navbar .navbar-toggle {
    right: auto;
    left: 56px; }
  .right-to-left.main-menu-right #content-wrapper {
    left: 0;
    right: auto; }
  .right-to-left.main-menu-right.mme #main-navbar .navbar-inner, .right-to-left.main-menu-right.mme #content-wrapper {
    left: 80%;
    right: auto; }
  .right-to-left.main-menu-right.mme #main-menu, .right-to-left.main-menu-right.mme #main-menu-bg {
    left: 0%;
    right: auto; }
  .right-to-left.main-menu-right.mme #main-navbar .navbar-toggle {
    right: auto;
    left: 0; }
  @media (min-width: 480px) {
    .right-to-left.main-menu-right #main-wrapper {
      padding-left: 56px;
      padding-right: 0; }
    .right-to-left.main-menu-right #main-menu,
    .right-to-left.main-menu-right #main-menu-bg {
      left: 0;
      right: auto; }
    .right-to-left.main-menu-right.mme #content-wrapper {
      left: 184px;
      right: auto; }
    .right-to-left.main-menu-right.mme #main-navbar .navbar-inner {
      left: 240px;
      right: auto; } }
  @media (min-width: 768px) {
    .right-to-left.main-menu-right.mme #main-wrapper,
    .right-to-left.main-menu-right #main-wrapper {
      padding-left: 240px;
      padding-right: 0; }
    .right-to-left.main-menu-right.mme #content-wrapper,
    .right-to-left.main-menu-right #content-wrapper {
      left: 0;
      right: auto; }
    .right-to-left.main-menu-right #main-navbar .navbar-inner, .right-to-left.main-menu-right.mme #main-navbar .navbar-inner, .right-to-left.main-menu-right.mme.mmc #main-navbar .navbar-inner {
      right: auto;
      left: 0;
      margin-right: 0;
      margin-left: 56px; }
    .right-to-left.main-menu-right.mmc #main-wrapper, .right-to-left.main-menu-right.mme.mmc #main-wrapper {
      padding-right: 0;
      padding-left: 56px; }
    .right-to-left.main-menu-right #main-navbar .navbar-header {
      padding-right: 0 !important; } }
  .right-to-left .progress-bar {
    float: right; }
  .right-to-left .navbar .nav-icon-btn .label,
  .right-to-left .navbar .nav-icon-btn .badge,
  .right-to-left .navbar .dropdown-menu .label.pull-right,
  .right-to-left .navbar .dropdown-menu .badge.pull-right {
    float: left; }
  @media (min-width: 768px) {
    .right-to-left .navbar .nav-icon-btn .label {
      right: auto;
      left: 10px; } }
  @media (max-width: 768px) {
    .right-to-left .navbar-nav .open .dropdown-menu > li > a,
    .right-to-left .navbar-nav .open .dropdown-menu .dropdown-header {
      padding: 5px 25px 5px 15px; } }
  .right-to-left .dropdown-menu {
    right: 0;
    left: auto;
    text-align: right; }
    .right-to-left .dropdown-menu.pull-right {
      right: auto;
      left: 0; }
    .right-to-left .dropdown-menu > li > a > .label,
    .right-to-left .dropdown-menu > li > a > .badge {
      float: left;
      margin-left: -3px;
      margin-right: 3px; }
  .right-to-left .pull-right .dropdown-menu {
    right: auto;
    left: 0; }
  .right-to-left .dropdown-menu-left {
    left: auto;
    right: 0; }
  .right-to-left .dropdown-menu-right {
    left: 0;
    right: auto; }
  .right-to-left .label.label-tag {
    margin-left: 0;
    margin-right: 12px;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .right-to-left .label.label-tag:before {
    border-left-width: 12px;
    border-right-width: 0;
    margin: 0;
    right: -13px; }
  .right-to-left .label.label-tag:after {
    margin: -12px 0 0 0;
    right: -5px; }
  .ie8 .right-to-left .label.label-tag:before, .ie9 .right-to-left .label.label-tag:before {
    border-left-width: 0;
    border-right-width: 12px; }
  .right-to-left .nav-tabs > li > a > .badge,
  .right-to-left .nav-tabs > li > a > .label,
  .right-to-left .nav-pills > li > a > .badge,
  .right-to-left .nav-pills > li > a > .label {
    margin-left: -5px;
    margin-right: 5px; }
  .right-to-left .nav-tabs.nav-justified > li,
  .right-to-left .nav-pills.nav-justified > li {
    float: none; }
    .right-to-left .nav-tabs.nav-justified > li > a,
    .right-to-left .nav-pills.nav-justified > li > a {
      margin: 0; }
  .right-to-left .nav-tabs.nav-tabs-sm > li > a > .badge,
  .right-to-left .nav-tabs.nav-tabs-sm > li > a > .label {
    margin-left: -4px;
    margin-right: 4px; }
  .right-to-left .nav-tabs.nav-tabs-xs > li > a > .badge,
  .right-to-left .nav-tabs.nav-tabs-xs > li > a > .label {
    margin-left: -3px;
    margin-right: 3px; }
  .right-to-left .nav-tabs {
    margin: 0;
    padding: 0; }
    .right-to-left .nav-tabs > li {
      float: right; }
      .right-to-left .nav-tabs > li > a {
        margin-right: 0;
        margin-left: 6px; }
  .right-to-left .nav-pills {
    margin: 0;
    padding: 0; }
    .right-to-left .nav-pills > li {
      float: right;
      margin-left: 6px;
      margin-right: 0; }
    .right-to-left .nav-pills.nav-stacked > li {
      float: none; }
    .right-to-left .nav-pills.nav-stacked > li + li {
      margin: 4px 0 0 0; }
  .right-to-left .alert .close {
    float: left; }
  .right-to-left table > thead > tr > th,
  .right-to-left table > thead > tr > td,
  .right-to-left table > tbody > tr > th,
  .right-to-left table > tbody > tr > td,
  .right-to-left table > tfoot > tr > th,
  .right-to-left table > tfoot > tr > td {
    text-align: right; }
  .right-to-left .accordion-toggle {
    padding-left: 35px;
    padding-right: 20px; }
    .right-to-left .accordion-toggle:after {
      left: 15px;
      right: auto; }
  .right-to-left .popover {
    text-align: right; }
  .right-to-left .list-group {
    padding: 0; }
  .right-to-left .list-group-item > .badge {
    float: left; }
  .right-to-left .form-horizontal .form-group label {
    text-align: left; }
  .right-to-left .radio, .right-to-left .checkbox {
    padding-left: 0;
    padding-right: 20px; }
  .right-to-left .radio label,
  .right-to-left .radio-inline label,
  .right-to-left .checkbox label,
  .right-to-left .checkbox-inline label {
    text-align: right !important; }
  .right-to-left .radio input[type="radio"],
  .right-to-left .radio-inline input[type="radio"],
  .right-to-left .checkbox input[type="checkbox"],
  .right-to-left .checkbox-inline input[type="checkbox"] {
    float: right;
    margin-left: 0;
    margin-right: -20px; }
  .right-to-left .radio-inline, .right-to-left .checkbox-inline {
    padding-left: 0;
    padding-right: 20px; }
  .right-to-left .has-warning .help-block:before, .right-to-left .has-error .help-block:before, .right-to-left .has-success .help-block:before {
    left: auto;
    right: 16px; }
  .right-to-left .has-warning .help-block:after, .right-to-left .has-error .help-block:after, .right-to-left .has-success .help-block:after {
    left: auto;
    right: 17px; }
  .right-to-left .radio-inline + .radio-inline,
  .right-to-left .checkbox-inline + .checkbox-inline {
    margin-left: 0;
    margin-right: 10px; }
  .right-to-left .form-control-feedback {
    left: 0;
    right: auto; }
  .right-to-left .has-feedback .form-control {
    padding-left: 32px;
    padding-right: 12px; }
  .gt-ie8 .right-to-left input.px {
    left: auto !important;
    right: -1000000px !important; }
  .gt-ie8 .right-to-left input.px + .lbl {
    padding-left: 0;
    padding-right: 1px;
    margin-left: 0; }
  .gt-ie8 .right-to-left input.px + .lbl:before {
    left: auto;
    right: 0; }
  .gt-ie8 .right-to-left input.px + .lbl:before,
  .gt-ie8 .right-to-left input.px + .lbl:after {
    margin: 2px -20px -3px 3px !important; }
  .gt-ie8 .right-to-left input[type="checkbox"].px + .lbl:after {
    left: auto;
    right: 1px; }
  .gt-ie8 .right-to-left input[type="radio"].px + .lbl:after {
    left: auto;
    right: 5px; }
  @media (min-width: 768px) {
    .gt-ie8 .right-to-left .form-inline .radio input.px + .lbl, .gt-ie8 .right-to-left .form-inline .checkbox input.px + .lbl {
      margin-left: 0; } }
  .right-to-left .px-single input.px {
    margin: 0 !important; }
    .right-to-left .px-single input.px + .lbl:before, .right-to-left .px-single input.px + .lbl:after {
      margin: 0 !important; }
  .right-to-left .px-single input.px + .lbl {
    padding: 0 !important; }
  .right-to-left .pagination > li {
    float: right; }
  .right-to-left .pager .previous > a {
    float: right; }
  .right-to-left .pager .next > a {
    float: left; }
  .right-to-left .btn-group > .btn {
    float: right; }
  .right-to-left .btn-group .btn + .btn,
  .right-to-left .btn-group .btn + .btn-group,
  .right-to-left .btn-group .btn-group + .btn,
  .right-to-left .btn-group .btn-group + .btn-group {
    margin-left: 0;
    margin-right: -1px; }
  .right-to-left .btn-group .dropdown-toggle {
    margin-bottom: -1px; }
  .right-to-left .btn-group.btn-group-justified > .btn {
    float: none; }
  .right-to-left .btn-toolbar .btn-group {
    float: right; }
    .right-to-left .btn-toolbar .btn-group.btn-group-justified {
      float: none; }
  .right-to-left .btn-toolbar > .btn,
  .right-to-left .btn-toolbar > .btn-group {
    margin-left: 0; }
    .right-to-left .btn-toolbar > .btn + .btn,
    .right-to-left .btn-toolbar > .btn + .btn-group,
    .right-to-left .btn-toolbar > .btn-group + .btn,
    .right-to-left .btn-toolbar > .btn-group + .btn-group {
      margin-left: 0;
      margin-right: 5px; }
  .right-to-left .btn-group > .btn:first-child {
    margin-right: 0; }
  .right-to-left .btn-group > .btn-group {
    float: right; }
  .right-to-left .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-radius: 2px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .right-to-left .btn-group > .btn:last-child:not(:first-child), .right-to-left .btn-group > .dropdown-toggle:not(:first-child) {
    border-radius: 2px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .right-to-left .btn-group > .btn-group:first-child > .btn:last-child, .right-to-left .btn-group > .btn-group:first-child > .dropdown-toggle {
    border-radius: 2px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .right-to-left .btn-group > .btn-group:last-child > .btn:first-child {
    border-radius: 2px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .right-to-left .btn-group-lg > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-radius: 3px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .right-to-left .btn-group-lg > .btn:last-child:not(:first-child), .right-to-left .btn-group-lg > .dropdown-toggle:not(:first-child) {
    border-radius: 3px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .right-to-left .btn-group-lg > .btn-group:first-child > .btn:last-child, .right-to-left .btn-group-lg > .btn-group:first-child > .dropdown-toggle {
    border-radius: 3px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .right-to-left .btn-group-lg > .btn-group:last-child > .btn:first-child {
    border-radius: 3px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .right-to-left .btn-group-sm > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-radius: 2px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .right-to-left .btn-group-sm > .btn:last-child:not(:first-child), .right-to-left .btn-group-sm > .dropdown-toggle:not(:first-child) {
    border-radius: 2px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .right-to-left .btn-group-sm > .btn-group:first-child > .btn:last-child, .right-to-left .btn-group-sm > .btn-group:first-child > .dropdown-toggle {
    border-radius: 2px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .right-to-left .btn-group-sm > .btn-group:last-child > .btn:first-child {
    border-radius: 2px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .right-to-left .btn-group.btn-xs > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-radius: 2px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .right-to-left .btn-group-xs > .btn:last-child:not(:first-child), .right-to-left .btn-group-xs > .dropdown-toggle:not(:first-child) {
    border-radius: 2px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .right-to-left .btn-group-xs > .btn-group:first-child > .btn:last-child, .right-to-left .btn-group-xs > .btn-group:first-child > .dropdown-toggle {
    border-radius: 2px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .right-to-left .btn-group-xs > .btn-group:last-child > .btn:first-child {
    border-radius: 2px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .right-to-left .input-sm, .right-to-left .input-group-sm > .form-control,
  .right-to-left .input-group-sm > .input-group-addon,
  .right-to-left .input-group-sm > .input-group-btn > .btn {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 2px; }
  .right-to-left select.input-sm, .right-to-left .input-group-sm > select.form-control,
  .right-to-left .input-group-sm > select.input-group-addon,
  .right-to-left .input-group-sm > .input-group-btn > select.btn {
    height: 30px;
    line-height: 30px; }
  .right-to-left textarea.input-sm, .right-to-left .input-group-sm > textarea.form-control,
  .right-to-left .input-group-sm > textarea.input-group-addon,
  .right-to-left .input-group-sm > .input-group-btn > textarea.btn,
  .right-to-left select[multiple].input-sm,
  .right-to-left .input-group-sm > select[multiple].form-control,
  .right-to-left .input-group-sm > select[multiple].input-group-addon,
  .right-to-left .input-group-sm > .input-group-btn > select[multiple].btn {
    height: auto; }
  .right-to-left .input-lg, .right-to-left .input-group-lg > .form-control,
  .right-to-left .input-group-lg > .input-group-addon,
  .right-to-left .input-group-lg > .input-group-btn > .btn {
    height: 45px;
    padding: 10px 16px;
    font-size: 17px;
    line-height: 1.33;
    border-radius: 3px; }
  .right-to-left select.input-lg, .right-to-left .input-group-lg > select.form-control,
  .right-to-left .input-group-lg > select.input-group-addon,
  .right-to-left .input-group-lg > .input-group-btn > select.btn {
    height: 45px;
    line-height: 45px; }
  .right-to-left textarea.input-lg, .right-to-left .input-group-lg > textarea.form-control,
  .right-to-left .input-group-lg > textarea.input-group-addon,
  .right-to-left .input-group-lg > .input-group-btn > textarea.btn,
  .right-to-left select[multiple].input-lg,
  .right-to-left .input-group-lg > select[multiple].form-control,
  .right-to-left .input-group-lg > select[multiple].input-group-addon,
  .right-to-left .input-group-lg > .input-group-btn > select[multiple].btn {
    height: auto; }
  .right-to-left .input-group-addon {
    border: 1px solid #ccc;
    border-radius: 2px; }
    .right-to-left .input-group-addon.input-sm,
    .right-to-left .input-group-sm > .input-group-addon,
    .right-to-left .input-group-sm > .input-group-btn > .input-group-addon.btn {
      border-radius: 2px; }
    .right-to-left .input-group-addon.input-lg,
    .right-to-left .input-group-lg > .input-group-addon,
    .right-to-left .input-group-lg > .input-group-btn > .input-group-addon.btn {
      border-radius: 3px; }
  .right-to-left .input-group .form-control:first-child,
  .right-to-left .input-group-addon:first-child,
  .right-to-left .input-group-btn:first-child > .btn,
  .right-to-left .input-group-btn:first-child > .btn-group > .btn,
  .right-to-left .input-group-btn:first-child > .dropdown-toggle,
  .right-to-left .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .right-to-left .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
    border-radius: 2px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
    .right-to-left .input-group .form-control:first-child.input-sm, .right-to-left .input-group .input-group-sm > .form-control:first-child,
    .right-to-left .input-group .input-group-sm > .input-group-btn > .form-control.btn:first-child,
    .right-to-left .input-group-addon:first-child.input-sm,
    .right-to-left .input-group-sm > .input-group-addon:first-child,
    .right-to-left .input-group-sm > .input-group-btn > .input-group-addon.btn:first-child,
    .right-to-left .input-group-btn:first-child > .btn.input-sm,
    .right-to-left .input-group-sm.input-group-btn:first-child > .btn.form-control,
    .right-to-left .input-group-sm.input-group-btn:first-child > .btn.input-group-addon,
    .right-to-left .input-group-sm > .input-group-btn:first-child > .btn,
    .right-to-left .input-group-btn:first-child > .btn-group > .btn.input-sm,
    .right-to-left .input-group-btn:first-child > .input-group-sm.btn-group > .btn.form-control,
    .right-to-left .input-group-btn:first-child > .input-group-sm.btn-group > .btn.input-group-addon,
    .right-to-left .input-group-sm.input-group-btn:first-child > .input-group-btn.btn-group > .btn,
    .right-to-left .input-group-btn:first-child > .dropdown-toggle.input-sm,
    .right-to-left .input-group-sm.input-group-btn:first-child > .dropdown-toggle.form-control,
    .right-to-left .input-group-sm.input-group-btn:first-child > .dropdown-toggle.input-group-addon,
    .right-to-left .input-group-sm > .input-group-btn:first-child > .dropdown-toggle.btn,
    .right-to-left .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle).input-sm,
    .right-to-left .input-group-sm.input-group-btn:last-child > .btn.form-control:not(:last-child):not(.dropdown-toggle),
    .right-to-left .input-group-sm.input-group-btn:last-child > .btn.input-group-addon:not(:last-child):not(.dropdown-toggle),
    .right-to-left .input-group-sm > .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
    .right-to-left .input-group-btn:last-child > .btn-group:not(:last-child) > .btn.input-sm,
    .right-to-left .input-group-btn:last-child > .input-group-sm.btn-group:not(:last-child) > .btn.form-control,
    .right-to-left .input-group-btn:last-child > .input-group-sm.btn-group:not(:last-child) > .btn.input-group-addon,
    .right-to-left .input-group-sm.input-group-btn:last-child > .input-group-btn.btn-group:not(:last-child) > .btn {
      border-radius: 2px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0; }
    .right-to-left .input-group .form-control:first-child.input-lg, .right-to-left .input-group .input-group-lg > .form-control:first-child,
    .right-to-left .input-group .input-group-lg > .input-group-btn > .form-control.btn:first-child,
    .right-to-left .input-group-addon:first-child.input-lg,
    .right-to-left .input-group-lg > .input-group-addon:first-child,
    .right-to-left .input-group-lg > .input-group-btn > .input-group-addon.btn:first-child,
    .right-to-left .input-group-btn:first-child > .btn.input-lg,
    .right-to-left .input-group-lg.input-group-btn:first-child > .btn.form-control,
    .right-to-left .input-group-lg.input-group-btn:first-child > .btn.input-group-addon,
    .right-to-left .input-group-lg > .input-group-btn:first-child > .btn,
    .right-to-left .input-group-btn:first-child > .btn-group > .btn.input-lg,
    .right-to-left .input-group-btn:first-child > .input-group-lg.btn-group > .btn.form-control,
    .right-to-left .input-group-btn:first-child > .input-group-lg.btn-group > .btn.input-group-addon,
    .right-to-left .input-group-lg.input-group-btn:first-child > .input-group-btn.btn-group > .btn,
    .right-to-left .input-group-btn:first-child > .dropdown-toggle.input-lg,
    .right-to-left .input-group-lg.input-group-btn:first-child > .dropdown-toggle.form-control,
    .right-to-left .input-group-lg.input-group-btn:first-child > .dropdown-toggle.input-group-addon,
    .right-to-left .input-group-lg > .input-group-btn:first-child > .dropdown-toggle.btn,
    .right-to-left .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle).input-lg,
    .right-to-left .input-group-lg.input-group-btn:last-child > .btn.form-control:not(:last-child):not(.dropdown-toggle),
    .right-to-left .input-group-lg.input-group-btn:last-child > .btn.input-group-addon:not(:last-child):not(.dropdown-toggle),
    .right-to-left .input-group-lg > .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
    .right-to-left .input-group-btn:last-child > .btn-group:not(:last-child) > .btn.input-lg,
    .right-to-left .input-group-btn:last-child > .input-group-lg.btn-group:not(:last-child) > .btn.form-control,
    .right-to-left .input-group-btn:last-child > .input-group-lg.btn-group:not(:last-child) > .btn.input-group-addon,
    .right-to-left .input-group-lg.input-group-btn:last-child > .input-group-btn.btn-group:not(:last-child) > .btn {
      border-radius: 3px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0; }
  .right-to-left .input-group-addon:first-child {
    border-left: 0; }
  .right-to-left .input-group .form-control:last-child,
  .right-to-left .input-group-addon:last-child,
  .right-to-left .input-group-btn:last-child > .btn,
  .right-to-left .input-group-btn:last-child > .btn-group > .btn,
  .right-to-left .input-group-btn:last-child > .dropdown-toggle,
  .right-to-left .input-group-btn:first-child > .btn:not(:first-child),
  .right-to-left .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
    border-radius: 2px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
    .right-to-left .input-group .form-control:last-child.input-sm, .right-to-left .input-group .input-group-sm > .form-control:last-child,
    .right-to-left .input-group .input-group-sm > .input-group-btn > .form-control.btn:last-child,
    .right-to-left .input-group-addon:last-child.input-sm,
    .right-to-left .input-group-sm > .input-group-addon:last-child,
    .right-to-left .input-group-sm > .input-group-btn > .input-group-addon.btn:last-child,
    .right-to-left .input-group-btn:last-child > .btn.input-sm,
    .right-to-left .input-group-sm.input-group-btn:last-child > .btn.form-control,
    .right-to-left .input-group-sm.input-group-btn:last-child > .btn.input-group-addon,
    .right-to-left .input-group-sm > .input-group-btn:last-child > .btn,
    .right-to-left .input-group-btn:last-child > .btn-group > .btn.input-sm,
    .right-to-left .input-group-btn:last-child > .input-group-sm.btn-group > .btn.form-control,
    .right-to-left .input-group-btn:last-child > .input-group-sm.btn-group > .btn.input-group-addon,
    .right-to-left .input-group-sm.input-group-btn:last-child > .input-group-btn.btn-group > .btn,
    .right-to-left .input-group-btn:last-child > .dropdown-toggle.input-sm,
    .right-to-left .input-group-sm.input-group-btn:last-child > .dropdown-toggle.form-control,
    .right-to-left .input-group-sm.input-group-btn:last-child > .dropdown-toggle.input-group-addon,
    .right-to-left .input-group-sm > .input-group-btn:last-child > .dropdown-toggle.btn,
    .right-to-left .input-group-btn:first-child > .btn:not(:first-child).input-sm,
    .right-to-left .input-group-sm.input-group-btn:first-child > .btn.form-control:not(:first-child),
    .right-to-left .input-group-sm.input-group-btn:first-child > .btn.input-group-addon:not(:first-child),
    .right-to-left .input-group-sm > .input-group-btn:first-child > .btn:not(:first-child),
    .right-to-left .input-group-btn:first-child > .btn-group:not(:first-child) > .btn.input-sm,
    .right-to-left .input-group-btn:first-child > .input-group-sm.btn-group:not(:first-child) > .btn.form-control,
    .right-to-left .input-group-btn:first-child > .input-group-sm.btn-group:not(:first-child) > .btn.input-group-addon,
    .right-to-left .input-group-sm.input-group-btn:first-child > .input-group-btn.btn-group:not(:first-child) > .btn {
      border-radius: 2px;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0; }
    .right-to-left .input-group .form-control:last-child.input-lg, .right-to-left .input-group .input-group-lg > .form-control:last-child,
    .right-to-left .input-group .input-group-lg > .input-group-btn > .form-control.btn:last-child,
    .right-to-left .input-group-addon:last-child.input-lg,
    .right-to-left .input-group-lg > .input-group-addon:last-child,
    .right-to-left .input-group-lg > .input-group-btn > .input-group-addon.btn:last-child,
    .right-to-left .input-group-btn:last-child > .btn.input-lg,
    .right-to-left .input-group-lg.input-group-btn:last-child > .btn.form-control,
    .right-to-left .input-group-lg.input-group-btn:last-child > .btn.input-group-addon,
    .right-to-left .input-group-lg > .input-group-btn:last-child > .btn,
    .right-to-left .input-group-btn:last-child > .btn-group > .btn.input-lg,
    .right-to-left .input-group-btn:last-child > .input-group-lg.btn-group > .btn.form-control,
    .right-to-left .input-group-btn:last-child > .input-group-lg.btn-group > .btn.input-group-addon,
    .right-to-left .input-group-lg.input-group-btn:last-child > .input-group-btn.btn-group > .btn,
    .right-to-left .input-group-btn:last-child > .dropdown-toggle.input-lg,
    .right-to-left .input-group-lg.input-group-btn:last-child > .dropdown-toggle.form-control,
    .right-to-left .input-group-lg.input-group-btn:last-child > .dropdown-toggle.input-group-addon,
    .right-to-left .input-group-lg > .input-group-btn:last-child > .dropdown-toggle.btn,
    .right-to-left .input-group-btn:first-child > .btn:not(:first-child).input-lg,
    .right-to-left .input-group-lg.input-group-btn:first-child > .btn.form-control:not(:first-child),
    .right-to-left .input-group-lg.input-group-btn:first-child > .btn.input-group-addon:not(:first-child),
    .right-to-left .input-group-lg > .input-group-btn:first-child > .btn:not(:first-child),
    .right-to-left .input-group-btn:first-child > .btn-group:not(:first-child) > .btn.input-lg,
    .right-to-left .input-group-btn:first-child > .input-group-lg.btn-group:not(:first-child) > .btn.form-control,
    .right-to-left .input-group-btn:first-child > .input-group-lg.btn-group:not(:first-child) > .btn.input-group-addon,
    .right-to-left .input-group-lg.input-group-btn:first-child > .input-group-btn.btn-group:not(:first-child) > .btn {
      border-radius: 3px;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0; }
  .right-to-left .input-group-addon:last-child {
    border-right: 0; }
  .right-to-left .input-group-btn > .btn {
    position: relative; }
    .right-to-left .input-group-btn > .btn + .btn {
      margin-left: 0;
      margin-right: -1px; }
  .right-to-left .input-group-btn:first-child > .btn,
  .right-to-left .input-group-btn:first-child > .btn-group {
    margin-left: -1px;
    margin-right: 0; }
  .right-to-left .input-group-btn:last-child > .btn,
  .right-to-left .input-group-btn:last-child > .btn-group {
    margin-left: 0;
    margin-right: -1px; }
  .right-to-left .input-group > .input-group-btn + input {
    margin-left: 0;
    margin-right: -1px; }
  .right-to-left .input-group > input + .input-group-btn .btn:first-child {
    border-left-width: 1px;
    border-right-width: 0; }
  .right-to-left .panel-heading .nav-tabs {
    left: 9px;
    right: auto; }
  .right-to-left .panel-title-icon {
    margin-left: 7px;
    margin-right: 0; }
  .right-to-left .panel-heading-controls {
    float: left; }
    .right-to-left .panel-heading-controls > * {
      float: right; }
      .right-to-left .panel-heading-controls > * + * {
        margin-left: 0;
        margin-right: 4px; }
    .right-to-left .panel-heading-controls > .form-control,
    .right-to-left .panel-heading-controls .input-group .form-control,
    .right-to-left .panel-heading-controls .input-group .btn {
      height: 26px;
      padding: 3px 8px; }
  .right-to-left .panel-dark .panel-heading-controls .input-group .form-control {
    border-left-color: #d5d5d5;
    border-right-color: transparent; }
  .right-to-left .modal-dialog .close {
    float: left; }
  .right-to-left .modal-footer {
    text-align: left; }
    .right-to-left .modal-footer .btn + .btn {
      margin-left: 0;
      margin-right: 5px; }
  .right-to-left .note {
    border-left-width: 0;
    border-right-width: 3px; }
  .right-to-left .btn.btn-labeled {
    position: relative; }
    .right-to-left .btn.btn-labeled .btn-label {
      border-radius: 0;
      float: right;
      margin-left: 12px;
      margin-right: -13px;
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px; }
    .right-to-left .btn.btn-labeled.btn-lg .btn-label {
      margin-left: 18px;
      margin-right: -19px;
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px; }
    .right-to-left .btn.btn-labeled.btn-sm .btn-label {
      margin-left: 9px;
      margin-right: -10px;
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px; }
    .right-to-left .btn.btn-labeled.btn-xs .btn-label {
      margin-left: 9px;
      margin-right: -8px;
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px; }
  .right-to-left .btn.btn-rounded.btn-labeled .btn-label,
  .right-to-left .btn.btn-lg.btn-rounded.btn-labeled .btn-label,
  .right-to-left .btn.btn-sm.btn-rounded.btn-labeled .btn-label,
  .right-to-left .btn.btn-xs.btn-rounded.btn-labeled .btn-label {
    border-radius: 0;
    border-bottom-right-radius: 9999px;
    border-top-right-radius: 9999px; }
  .right-to-left .stat-counters[class*='bg-'] {
    border-color: #fff;
    border-color: rgba(0, 0, 0, 0.2); }
    .right-to-left .stat-counters[class*='bg-'] .stat-cell {
      border-color: #fff;
      border-color: rgba(0, 0, 0, 0.2); }
  .right-to-left .stat-cell .bg-icon {
    left: 0;
    right: auto; }
  .right-to-left .stat-cell .bg-icon.bg-icon-left {
    left: auto;
    right: 0; }
  .right-to-left .stat-counters > .stat-cell + .stat-cell {
    border-left: none;
    border-right: 1px solid #e4e4e4; }
  .right-to-left .counter-separated span {
    float: right; }
    .right-to-left .counter-separated span + span {
      margin-left: 0;
      margin-right: 4px; }
  .right-to-left .counter-separated.counter-sm span + span {
    margin-right: 3px; }
  .right-to-left .no-border-r {
    border-left-width: 0 !important; }
  .right-to-left .no-border-l {
    border-right-width: 0 !important; }
  .right-to-left .text-left {
    text-align: right; }
  .right-to-left .text-right {
    text-align: left; }
  @media (min-width: 768px) {
    .right-to-left .text-left-sm {
      text-align: right; }
    .right-to-left .text-center-sm {
      text-align: center; }
    .right-to-left .text-right-sm {
      text-align: left; } }
  @media (min-width: 992px) {
    .right-to-left .text-left-md {
      text-align: right; }
    .right-to-left .text-center-md {
      text-align: center; }
    .right-to-left .text-right-md {
      text-align: left; } }
  @media (min-width: 1200px) {
    .right-to-left .text-left-lg {
      text-align: right; }
    .right-to-left .text-center-lg {
      text-align: center; }
    .right-to-left .text-right-lg {
      text-align: left; } }
  .right-to-left .widget-threads .thread-avatar {
    float: right; }
  .right-to-left .widget-threads .thread-body {
    margin-left: 0;
    margin-right: 42px; }
  .right-to-left .widget-threads .thread-time {
    float: left; }
  .right-to-left .widget-tasks .task .action-checkbox {
    float: right; }
  .right-to-left .widget-tasks .task .task-title span {
    margin-left: 0;
    margin-right: 5px; }
  .right-to-left .widget-tasks .task .label {
    left: 0;
    right: auto; }
  .right-to-left .widget-tasks .task .task-sort-icon {
    float: right;
    margin-left: 0;
    margin-right: -11px; }
  .right-to-left .panel.widget-tasks .task .label,
  .right-to-left .panel .widget-tasks .task .label {
    left: 20px;
    right: auto; }
  .right-to-left .widget-support-tickets a.ticket-title {
    padding: 0 0 0 90px; }
    .right-to-left .widget-support-tickets a.ticket-title i {
      margin: 0 0 0 5px; }
    .right-to-left .widget-support-tickets a.ticket-title span {
      padding-left: 0;
      padding-right: 4px; }
  .right-to-left .widget-support-tickets .ticket-label {
    float: left; }
  .right-to-left .widget-profile.widget-profile-centered {
    text-align: center; }
    .right-to-left .widget-profile.widget-profile-centered .widget-profile-avatar {
      width: 70px;
      height: 70px;
      float: none;
      margin: 0 auto 8px auto;
      display: block; }
  .right-to-left .widget-profile .widget-profile-avatar {
    float: right;
    margin-left: 12px;
    margin-right: 0; }
  .right-to-left .widget-profile .widget-profile-bg-icon {
    left: 5px;
    right: auto;
    text-align: left; }
  .right-to-left .widget-profile .widget-profile-counters > div + div {
    border-left: none;
    border-right: 1px solid #e4e4e4; }
  .right-to-left .widget-notifications .notification-title,
  .right-to-left .widget-notifications .notification-description,
  .right-to-left .widget-notifications .notification-ago {
    margin-left: 35px;
    margin-right: 0; }
  .right-to-left .widget-notifications .notification-icon {
    left: 0;
    right: auto;
    margin-left: 12px;
    margin-right: 0; }
  .right-to-left .widget-messages .message .action-checkbox,
  .right-to-left .widget-messages .message .from {
    float: right; }
  .right-to-left .widget-messages .message .from {
    padding-left: 0;
    padding-right: 5px; }
  .right-to-left .widget-messages .message .title {
    padding-left: 60px;
    padding-right: 0; }
  .right-to-left .widget-messages .message .date {
    left: 0;
    right: auto;
    text-align: left; }
  .right-to-left .widget-messages .message .message-title-icon {
    margin-left: 7px;
    margin-right: 0; }
  .right-to-left .panel.widget-messages .message .date,
  .right-to-left .panel .widget-messages .message .date {
    left: 20px;
    right: auto; }
  .right-to-left .widget-messages-alt .message-avatar {
    left: auto;
    right: 12px; }
  .right-to-left .widget-messages-alt .message-subject,
  .right-to-left .widget-messages-alt .message-description {
    padding-left: 0;
    padding-right: 42px; }
  .right-to-left .widget-comments .comment-avatar {
    float: right; }
  .right-to-left .widget-comments .comment-body {
    margin-left: 0;
    margin-right: 45px; }
  .right-to-left .widget-comments .comment-actions i {
    margin-left: 5px;
    margin-right: 0; }
  .right-to-left .widget-comments .comment-actions a {
    margin-left: 10px;
    margin-right: 0; }
  .right-to-left .widget-chat .message-body {
    margin-left: 0;
    margin-right: 55px; }
  .right-to-left .widget-chat .message-avatar {
    float: right; }
  .right-to-left .widget-chat .message-text:before {
    border-color: transparent transparent transparent #f4f4f4;
    border-width: 6px 0 6px 6px;
    left: auto;
    right: -6px; }
  .right-to-left .widget-chat .message.right .message-avatar {
    float: left; }
  .right-to-left .widget-chat .message.right .message-body {
    margin-left: 55px;
    margin-right: 0; }
  .right-to-left .widget-chat .message.right .message-text:before {
    border-color: transparent #f4f4f4 transparent transparent;
    border-width: 6px 6px 6px 0;
    left: -6px;
    right: auto; }
  .right-to-left .widget-chat .chat-controls .chat-controls-input {
    margin-left: 90px;
    margin-right: 0; }
  .right-to-left .widget-chat .chat-controls .chat-controls-btn {
    left: 20px;
    right: auto; }
  .right-to-left .widget-article-comments .comment > .comment {
    margin-left: -20px !important;
    margin-right: 26px !important; }
  .right-to-left .widget-article-comments .comment-avatar {
    float: right; }
  .right-to-left .widget-article-comments .comment-heading span {
    margin-left: 0;
    margin-right: 5px; }
  .right-to-left .widget-article-comments .comment-body {
    margin-left: 0;
    margin-right: 46px; }
    .right-to-left .widget-article-comments .comment-body:before {
      border-color: transparent transparent transparent #e4e4e4;
      border-width: 6px 0 6px 6px;
      left: auto;
      right: -6px; }
  .right-to-left .widget-followers .follower-avatar {
    float: right;
    margin-left: 10px;
    margin-right: 0; }
  .right-to-left .widget-followers .follower-controls {
    float: left; }
  .right-to-left.page-invoice .invoice-header address {
    border-left: none;
    border-right: 5px solid #e8e8e8;
    padding-left: 0;
    padding-right: 10px; }
  .right-to-left.page-invoice .invoice-header h3 {
    margin: 0 40px 0 0; }
  .right-to-left.page-invoice .invoice-header .invoice-logo {
    margin-left: 0;
    margin-right: -40px; }
  .right-to-left.page-invoice .invoice-recipient {
    padding-left: 0;
    padding-right: 70px; }
  .right-to-left.page-invoice .invoice-recipient:before {
    margin-left: 0;
    margin-right: -70px; }
  .right-to-left.page-invoice .invoice-total span {
    float: left; }
  .right-to-left.page-invoice table th,
  .right-to-left.page-invoice table td {
    border-left: none;
    border-right: 1px solid #fff;
    text-align: right;
    padding-left: 0;
    padding-right: 15px; }
  @media (min-width: 768px) {
    .right-to-left.page-invoice .invoice-header h3 {
      float: right; }
    .right-to-left.page-invoice .invoice-header address {
      margin-left: 0;
      margin-right: 30px;
      float: right; }
    .right-to-left.page-invoice .invoice-date {
      float: left; }
    .right-to-left.page-invoice .invoice-recipient {
      float: right; }
    .right-to-left.page-invoice .invoice-total {
      float: left; } }
  .right-to-left.page-invoice.page-invoice-print .invoice-header h3 {
    float: right; }
  .right-to-left.page-invoice.page-invoice-print .invoice-header address {
    margin-left: 0;
    margin-right: 30px;
    float: right; }
  .right-to-left.page-invoice.page-invoice-print .invoice-date {
    float: left; }
  .right-to-left.page-invoice.page-invoice-print .invoice-recipient {
    float: right; }
  .right-to-left.page-invoice.page-invoice-print .invoice-total {
    float: left;
    text-align: left; }
    .right-to-left.page-invoice.page-invoice-print .invoice-total span {
      margin-left: 0;
      margin-right: 15px; }
  .right-to-left.page-404 .search-form .search-input,
  .right-to-left.page-404 .search-form .search-btn, .right-to-left.page-500 .search-form .search-input,
  .right-to-left.page-500 .search-form .search-btn {
    float: right; }
  .right-to-left.page-404 .search-form .search-btn, .right-to-left.page-500 .search-form .search-btn {
    margin-left: 0;
    margin-right: 10px; }
  .right-to-left.page-pricing .plan-period {
    margin-left: 0;
    margin-right: 5px; }
  @media (min-width: 768px) {
    .right-to-left.page-pricing .plan-col + .plan-col .plan-features > li {
      border-left-width: 1px;
      border-right-width: 0;
      -webkit-box-shadow: 1px 0 0 #e4e4e4;
      box-shadow: 1px 0 0 #e4e4e4; }
    .right-to-left.page-pricing .plan-col + .plan-col .plan-features > a {
      margin-left: 0;
      margin-right: -1px; } }
  .right-to-left.page-search .search-classic .search-tags span,
  .right-to-left.page-search .search-classic .search-tags a {
    float: right; }
  .right-to-left.page-search .search-classic .search-tags .search-tags-text {
    margin-left: 10px;
    margin-right: 0; }
  .right-to-left.page-search .search-classic .search-tags .label + .label, .right-to-left.page-search .search-classic .search-tags .label + .badge,
  .right-to-left.page-search .search-classic .search-tags .badge + .label,
  .right-to-left.page-search .search-classic .search-tags .badge + .badge {
    margin-left: 0;
    margin-right: 5px; }
  .right-to-left.page-signin .signin-info ul .signin-icon {
    float: right;
    margin-left: 6px;
    margin-right: 0; }
  .right-to-left.page-signin .signin-form .form-group.w-icon .signin-form-icon {
    border-left: 1px solid #d6d6d6;
    border-right: none;
    left: auto;
    right: 0; }
  .right-to-left.page-signin .signin-form .form-group.w-icon input[type="text"],
  .right-to-left.page-signin .signin-form .form-group.w-icon input[type="password"] {
    padding-left: 16px;
    padding-right: 56px !important; }
  .right-to-left.page-signin .signin-form .form-actions a.forgot-password {
    margin-left: 0;
    margin-right: 10px; }
  .right-to-left.page-signin .close {
    float: left; }
  @media (min-width: 768px) {
    .right-to-left.page-signin .signin-info {
      text-align: right; } }
  .right-to-left.page-signup .signup-form .form-group.w-icon .signup-form-icon {
    border-left: 1px solid #d6d6d6;
    border-right: none;
    left: auto;
    right: 0; }
  .right-to-left.page-signup .signup-form .form-group.w-icon input[type="text"],
  .right-to-left.page-signup .signup-form .form-group.w-icon input[type="password"] {
    padding-left: 16px;
    padding-right: 56px !important; }
  .right-to-left.page-signin-alt {
    padding-bottom: 40px; }
    .right-to-left.page-signin-alt .signin-header {
      padding-left: 0;
      padding-right: 16px; }
      .right-to-left.page-signin-alt .signin-header .btn {
        left: 16px;
        right: auto; }
    .right-to-left.page-signin-alt .signin-password {
      position: relative; }
      .right-to-left.page-signin-alt .signin-password input {
        padding-left: 70px;
        padding-right: 16px; }
      .right-to-left.page-signin-alt .signin-password .forgot {
        left: 10px;
        right: auto; }
  @media (min-width: 480px) {
    .right-to-left.page-signin-alt .signin-header {
      padding-right: 0;
      text-align: center; } }
  .right-to-left.page-signup-alt {
    padding-bottom: 40px; }
    .right-to-left.page-signup-alt .signup-header {
      padding-left: 0;
      padding-right: 16px; }
      .right-to-left.page-signup-alt .signup-header .btn {
        left: 16px;
        right: auto; }
  @media (min-width: 480px) {
    .right-to-left.page-signup-alt .signup-header {
      padding-right: 0;
      text-align: center; } }
  .right-to-left .timeline:before, .right-to-left .timeline:after {
    left: auto;
    right: 30px; }
  .right-to-left .timeline:before {
    margin-left: 0;
    margin-right: -2px; }
  .right-to-left .timeline:after {
    margin-left: 0;
    margin-right: -6px; }
  .right-to-left .tl-header {
    left: auto;
    right: 0;
    margin-left: 0; }
  .right-to-left .tl-entry {
    margin-left: 0;
    margin-right: 30px;
    padding-left: 0;
    padding-right: 36px; }
    .right-to-left .tl-entry:before {
      left: auto;
      right: -3px; }
  .right-to-left .tl-body:before {
    border-width: 8px 0 8px 9px;
    left: auto;
    right: -9px; }
  .right-to-left .tl-body:after {
    border-width: 7px 0 7px 8px;
    border-color: transparent transparent transparent #fff;
    left: auto;
    right: -8px; }
  .right-to-left .tl-time {
    text-align: left; }
  .right-to-left .tl-icon {
    left: auto;
    right: -20px; }
  @media (min-width: 768px) {
    .right-to-left .timeline:before,
    .right-to-left .timeline:after {
      left: auto;
      right: 100px; }
    .right-to-left .tl-header {
      left: auto;
      right: 100px;
      margin-left: 0;
      margin-right: -70px; }
    .right-to-left .tl-entry {
      margin-left: 0;
      margin-right: 100px; }
    .right-to-left .tl-time {
      left: 100%;
      right: auto;
      margin-left: 32px;
      margin-right: 0; }
    .right-to-left .timeline.centered:before, .right-to-left .timeline.centered:after {
      left: auto;
      right: 50%; }
    .right-to-left .timeline.centered .tl-header {
      left: auto;
      right: 50%; }
    .right-to-left .timeline.centered .tl-entry {
      margin-left: 0;
      margin-right: 50%; }
    .right-to-left .timeline.centered .tl-entry.left {
      margin-left: 50%;
      margin-right: 0;
      padding-left: 36px;
      padding-right: 0; }
    .right-to-left .timeline.centered .tl-entry.left:before {
      left: -3px;
      right: auto; }
    .right-to-left .timeline.centered .tl-entry.left .tl-body:before {
      border-width: 8px 9px 8px 0;
      left: -9px;
      right: auto; }
    .right-to-left .timeline.centered .tl-entry.left .tl-body:after {
      border-width: 7px 8px 7px 0;
      border-color: transparent #fff transparent transparent;
      left: -8px;
      right: auto; }
    .right-to-left .timeline.centered .tl-entry.left .tl-time {
      left: auto;
      right: 100%;
      margin-left: 0;
      margin-right: 32px; }
    .right-to-left .timeline.centered .tl-entry.left .tl-icon {
      left: -20px;
      right: auto; } }
  .right-to-left.page-mail .mail-nav .navigation.open li.active:after {
    -ms-transform: rotate(-90deg);
    /* IE 9 */
    -webkit-transform: rotate(-90deg);
    /* Chrome, Safari, Opera */
    transform: rotate(-90deg); }
  .right-to-left.page-mail .mail-nav .sections li.active:after {
    content: "\f104";
    left: 16px;
    right: auto; }
  .right-to-left.page-mail .mail-nav .sections li.active .label, .right-to-left.page-mail .mail-nav .sections li.active .badge {
    margin-left: 36px;
    margin-right: 0; }
  .right-to-left.page-mail .mail-nav .sections .label,
  .right-to-left.page-mail .mail-nav .sections .badge {
    margin-left: 16px;
    margin-right: 0; }
  .right-to-left.page-mail .mail-nav .sections a {
    padding: 0 16px 0 0; }
  .right-to-left.page-mail .m-nav-icon {
    margin-left: 10px;
    margin-right: 0; }
  .right-to-left.page-mail .mail-nav-header {
    padding-left: 0;
    padding-right: 16px; }
  .right-to-left.page-mail .mail-nav-lbl {
    margin-left: 10px;
    margin-right: 0; }
  .right-to-left.page-mail .mail-controls .btn-group + .btn-group {
    margin-left: 0;
    margin-right: 12px; }
  .right-to-left.page-mail .mail-controls .pages {
    margin-left: 13px;
    margin-right: 0; }
  @media (min-width: 992px) {
    .right-to-left.page-mail .mail-nav {
      border-left-width: 1px;
      border-right-width: 0; }
      .right-to-left.page-mail .mail-nav:before {
        border-right: none;
        border-left: 1px solid; }
      .right-to-left.page-mail .mail-nav .navigation .label {
        margin-left: 16px !important;
        margin-right: 0 !important; }
    .right-to-left.page-mail .mail-container {
      margin-left: 0;
      margin-right: 200px; } }
  .right-to-left.page-mail .mail-item {
    padding: 12px 46px 12px 14px; }
  .right-to-left.page-mail .m-chck {
    left: auto;
    right: 15px; }
  .right-to-left.page-mail .m-star {
    left: auto;
    right: 15px; }
  .right-to-left.page-mail .m-from,
  .right-to-left.page-mail .m-subject {
    margin-left: 80px;
    margin-right: 0; }
  .right-to-left.page-mail .m-date {
    left: 15px;
    right: auto;
    text-align: left; }
  @media (min-width: 992px) {
    .right-to-left.page-mail .mail-item {
      padding-left: 100px;
      padding-right: 220px; }
    .right-to-left.page-mail .m-star {
      left: auto;
      right: 40px; }
    .right-to-left.page-mail .m-from {
      left: auto;
      right: 70px; } }
  .right-to-left.page-mail .m-details-star {
    margin-left: 12px;
    margin-right: 0;
    float: right; }
  .right-to-left.page-mail .mail-info .avatar {
    float: right; }
  .right-to-left.page-mail .mail-info .from {
    float: right;
    margin-left: 0;
    margin-right: 10px; }
  .right-to-left.page-mail .mail-info .date {
    float: left; }
  .right-to-left.page-profile .profile-skills .label {
    float: right;
    margin-left: 4px;
    margin-right: 0; }
  @media (min-width: 768px) {
    .right-to-left.page-profile .left-col {
      float: right; }
    .right-to-left.page-profile .right-col {
      padding-left: 0;
      padding-right: 20px; }
    .right-to-left.page-profile .profile-full-name {
      padding-left: 0;
      padding-right: 240px;
      text-align: right; } }
  .right-to-left .ui-accordion-header:after {
    float: left; }
  .right-to-left .ui-menu .ui-menu {
    right: 100%; }
  .right-to-left .ui-menu .ui-menu-item a[aria-haspopup="true"]:after {
    content: "\f0d9";
    float: left; }
  .right-to-left .ui-spinner-input {
    padding-right: 12px;
    padding-left: 32px; }
  .right-to-left .ui-spinner-button {
    right: auto;
    left: 0;
    border-left: none;
    border-right: 1px solid;
    margin-right: 0;
    margin-left: 1px; }
  .right-to-left .ui-datepicker th,
  .right-to-left .ui-datepicker td {
    text-align: center !important; }
  .right-to-left .ui-datepicker td {
    border-left: 1px solid #eee; }
  .right-to-left .ui-datepicker tr td:last-child {
    border-left: none; }
  .right-to-left .ui-datepicker-prev {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    right: 0;
    border-right: none;
    left: auto; }
    .right-to-left .ui-datepicker-prev:after {
      content: "\f054"; }
  .right-to-left .ui-datepicker-next {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    left: 0;
    border-left: none;
    right: auto; }
    .right-to-left .ui-datepicker-next:after {
      content: "\f053"; }
  .right-to-left .ui-datepicker-title select:first-child {
    margin-right: 0;
    margin-left: 5px; }
  .right-to-left .ui-datepicker-buttonpane {
    direction: ltr !important;
    text-align: left; }
  .right-to-left .ui-datepicker-group {
    float: right; }
    .right-to-left .ui-datepicker-group .ui-datepicker-title,
    .right-to-left .ui-datepicker-group table {
      border-left: 1px solid rgba(0, 0, 0, 0.2); }
  .right-to-left .switcher-state-on {
    margin-left: 0;
    margin-right: -100%; }
  .right-to-left .switcher.checked .switcher-state-on {
    margin-right: 0; }
  .right-to-left .switcher-toggler {
    left: auto;
    right: 0; }
  .right-to-left .switcher.checked .switcher-toggler {
    left: auto;
    right: 100%; }
  .right-to-left .switcher > input[type="checkbox"] {
    left: auto;
    right: -100000px; }
  .right-to-left .switcher-state-on {
    padding-left: 20px;
    padding-right: 0; }
  .right-to-left .switcher-state-off {
    padding-left: 0;
    padding-right: 20px; }
  .right-to-left .switcher-toggler {
    margin-left: 0;
    margin-right: 1px; }
  .right-to-left .switcher.checked .switcher-toggler {
    margin-left: 0;
    margin-right: -21px; }
  .right-to-left .switcher-theme-square .switcher-toggler {
    margin-left: 0;
    margin-right: 2px; }
  .right-to-left .switcher-theme-square.switcher.checked .switcher-toggler {
    margin-left: 0;
    margin-right: -22px; }
  .right-to-left .switcher-theme-modern .switcher-toggler {
    margin-left: 0;
    margin-right: -3px; }
  .right-to-left .switcher-theme-modern .switcher-state-on {
    padding-left: 17px;
    padding-right: 0; }
  .right-to-left .switcher-theme-modern .switcher-state-off {
    padding-left: 0;
    padding-right: 17px; }
  .right-to-left .switcher-theme-modern.switcher.checked .switcher-toggler {
    margin-left: 0;
    margin-right: -17px; }
  .right-to-left .switcher-sm .switcher-toggler {
    margin-left: 0;
    margin-right: 1px; }
  .right-to-left .switcher-sm .switcher-state-on {
    padding-left: 17px;
    padding-right: 0; }
  .right-to-left .switcher-sm .switcher-state-off {
    padding-left: 0;
    padding-right: 17px; }
  .right-to-left .switcher-sm.checked .switcher-toggler {
    margin-left: 0;
    margin-right: -18px; }
  .right-to-left .switcher-sm.switcher-theme-square .switcher-toggler {
    margin-left: 0;
    margin-right: 2px; }
  .right-to-left .switcher-sm.switcher-theme-square.switcher.checked .switcher-toggler {
    margin-left: 0;
    margin-right: -19px; }
  .right-to-left .switcher-sm.switcher.switcher-theme-modern .switcher-toggler {
    margin-left: 0;
    margin-right: -3px; }
  .right-to-left .switcher-sm.switcher.switcher-theme-modern .switcher-state-on {
    padding-left: 15px;
    padding-right: 0; }
  .right-to-left .switcher-sm.switcher.switcher-theme-modern .switcher-state-off {
    padding-left: 0;
    padding-right: 15px; }
  .right-to-left .switcher-sm.switcher.switcher-theme-modern.switcher.checked .switcher-toggler {
    margin-left: 0;
    margin-right: -15px; }
  .right-to-left .switcher-lg .switcher-toggler {
    margin-left: 0;
    margin-right: 1px; }
  .right-to-left .switcher-lg .switcher-state-on {
    padding-left: 26px;
    padding-right: 0; }
  .right-to-left .switcher-lg .switcher-state-off {
    padding-left: 0;
    padding-right: 26px; }
  .right-to-left .switcher-lg.checked .switcher-toggler {
    margin-left: 0;
    margin-right: -27px; }
  .right-to-left .switcher-lg.switcher-theme-square .switcher-toggler {
    margin-left: 0;
    margin-right: 2px; }
  .right-to-left .switcher-lg.switcher-theme-square.switcher.checked .switcher-toggler {
    margin-left: 0;
    margin-right: -28px; }
  .right-to-left .switcher-lg.switcher.switcher-theme-modern .switcher-toggler {
    margin-left: 0;
    margin-right: -3px; }
  .right-to-left .switcher-lg.switcher.switcher-theme-modern .switcher-state-on {
    padding-left: 26px;
    padding-right: 0; }
  .right-to-left .switcher-lg.switcher.switcher-theme-modern .switcher-state-off {
    padding-left: 0;
    padding-right: 26px; }
  .right-to-left .switcher-lg.switcher.switcher-theme-modern.switcher.checked .switcher-toggler {
    margin-left: 0;
    margin-right: -24px; }
  .right-to-left .wizard-steps {
    float: right;
    right: 0;
    -webkit-transition: right 0.3s;
    -o-transition: right 0.3s;
    transition: right 0.3s; }
    .right-to-left .wizard-steps > li {
      padding: 0 50px 0 10px; }
      .right-to-left .wizard-steps > li + li:before {
        margin-right: 0;
        margin-right: -51px; }
  .right-to-left .wizard-step-number {
    margin-left: 0;
    margin-right: -40px; }
  .right-to-left .pfi-actions {
    right: auto;
    left: 0;
    padding-right: 10px;
    padding-left: 5px; }
    .right-to-left .pfi-actions > * + * {
      margin-left: 0;
      margin-right: 4px; }
  .right-to-left .select2-container .select2-choice > .select2-chosen {
    margin-left: 26px;
    margin-right: 0; }
  .right-to-left .select2-container .select2-choice .select2-search-choice-close {
    left: 18px;
    right: auto; }
  .right-to-left .select2-container .select2-choice .select2-arrow {
    left: 0;
    right: auto; }
  .right-to-left .select2-container.select2-allowclear .select2-choice .select2-chosen {
    margin-left: 42px;
    margin-right: 0; }
  .right-to-left .select2-search:after {
    left: 9px;
    right: auto; }
  .right-to-left .select2-search input {
    padding-left: 30px;
    padding-right: 9px; }
  .right-to-left .select2-results ul.select2-result-sub {
    padding-left: 0;
    padding-right: 0; }
    .right-to-left .select2-results ul.select2-result-sub > li .select2-result-label {
      padding-left: 0;
      padding-right: 20px; }
    .right-to-left .select2-results ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
      padding-left: 0;
      padding-right: 40px; }
    .right-to-left .select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
      padding-left: 0;
      padding-right: 60px; }
    .right-to-left .select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
      padding-left: 0;
      padding-right: 80px; }
    .right-to-left .select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
      padding-left: 0;
      padding-right: 100px; }
    .right-to-left .select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
      padding-left: 0;
      padding-right: 110px; }
    .right-to-left .select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
      padding-left: 0;
      padding-right: 120px; }
  .right-to-left .select2-container-multi .select2-choices {
    padding-right: 0 !important;
    text-align: right; }
    .right-to-left .select2-container-multi .select2-choices li {
      float: right; }
    .right-to-left .select2-container-multi .select2-choices .select2-search-choice {
      margin: 0 5px 5px 0;
      padding: 0 6px 0 20px; }
      .right-to-left .select2-container-multi .select2-choices .select2-search-choice + .select2-search-field input {
        padding-left: 12px;
        padding-right: 6px; }
  .right-to-left .select2-search-choice-close {
    left: 3px;
    right: auto; }
    .right-to-left .select2-search-choice-close:before {
      content: "\f00d"; }
  .right-to-left .datepicker-dropdown {
    right: auto; }
  .right-to-left .datepicker {
    direction: rtl; }
    .right-to-left .datepicker table tr td span {
      float: right; }
    .right-to-left .datepicker table tr td:last-child {
      border-right: 1px solid #eee; }
    .right-to-left .datepicker table tr td:first-child {
      border-right: none; }
    .right-to-left .datepicker table tr td span {
      float: right; }
    .right-to-left .datepicker thead th.prev {
      border-left: 1px solid;
      border-right: none;
      left: auto;
      right: 0; }
      .right-to-left .datepicker thead th.prev:after {
        content: "\f054"; }
    .right-to-left .datepicker thead th.next {
      border-right: 1px solid;
      border-left: none;
      left: 0;
      right: auto; }
      .right-to-left .datepicker thead th.next:after {
        content: "\f053"; }
    .right-to-left .datepicker > .datepicker-days thead tr:first-child th.cw + .prev {
      border-right: 1px solid;
      left: auto;
      right: 29px; }
    .right-to-left .datepicker > .datepicker-days thead tr:last-child th.cw + th {
      border-left: none;
      border-right: 1px solid; }
  .right-to-left .pagination a.dp-nav.dp-nav-left:after {
    content: "\f054"; }
  .right-to-left .pagination a.dp-nav.dp-nav-right:after {
    content: "\f053"; }
  .right-to-left #dp-calendar {
    left: 0;
    right: auto;
    padding: 0 18px 0 8px; }
  .right-to-left .tabdrop {
    margin: 0 6px -1px 0; }
  .right-to-left .tabdrop > .dropdown-toggle {
    margin-left: 0;
    margin-right: 6px; }
    .right-to-left .tabdrop > .dropdown-toggle > .caret {
      margin-left: 0;
      margin-right: 5px; }
  .right-to-left .minicolors .minicolors-grid,
  .right-to-left .minicolors .minicolors-slider,
  .right-to-left .minicolors .minicolors-opacity-slider {
    float: left; }
  .right-to-left .minicolors-with-opacity .minicolors-opacity-slider {
    display: block; }
  .right-to-left .minicolors-with-opacity .minicolors-slider {
    margin-right: 1px;
    margin-left: 22px; }
  .right-to-left .minicolors-opacity-slider {
    left: 0;
    right: auto; }
  .right-to-left .minicolors-slider,
  .right-to-left .minicolors-opacity-slider {
    margin: 1px 0 1px 1px; }
  .right-to-left .editable-input .form-control {
    padding-right: 10px !important; }
  .right-to-left .editable-buttons {
    margin-left: auto;
    margin-right: 7px; }
    .right-to-left .editable-buttons.editable-buttons-bottom {
      margin-right: 0; }
    .right-to-left .editable-buttons .editable-cancel {
      margin-left: 0;
      margin-right: 5px; }
  .right-to-left .editable-inline .add-on .icon-th {
    margin-left: 0;
    margin-right: 1px; }
  .right-to-left .editable-clear-x {
    right: auto;
    left: 6px; }
  .right-to-left .editable-address label span,
  .right-to-left .editable-address input {
    float: right; }
  .right-to-left .editable-address label span {
    padding-right: 0;
    padding-left: 10px;
    text-align: left; }
  .right-to-left .dataTables_wrapper .table-header {
    padding-left: 0;
    padding-right: 15px; }
    .right-to-left .dataTables_wrapper .table-header .table-caption {
      float: right;
      padding-left: 10px;
      padding-right: 0; }
  .right-to-left .DT-per-page {
    border-left: 1px solid #eee;
    border-right: none;
    padding-left: 10px;
    padding-right: 0; }
  .right-to-left .DT-label {
    float: right; }
  .right-to-left .DT-pagination {
    float: left; }
  .right-to-left .DT-search {
    margin-left: 10px;
    margin-right: 0; }
  .right-to-left .DT-per-page {
    margin-left: 10px;
    margin-right: 0; }
  .right-to-left .DT-lf-right {
    float: left; }
  .right-to-left table.dataTable th:after {
    left: 0;
    right: auto; }
  .right-to-left > input[type="file"] {
    left: auto !important;
    right: 0 !important; }
  .right-to-left .dz-default.dz-message {
    padding-left: 0;
    padding-right: 90px; }
  .right-to-left .dropzone-box .fa.fa-cloud-upload {
    left: auto;
    right: 0; }
  .right-to-left .note-editor .note-toolbar .note-para .dropdown-menu > div:first-child {
    margin-left: 5px;
    margin-right: 0; }
  .right-to-left.main-menu-right #growls {
    position: fixed;
    right: 0;
    top: 10px;
    z-index: 5000; }
  .right-to-left #growls {
    left: 0;
    right: auto; }
  .right-to-left .growl .growl-close {
    float: left; }
  .right-to-left .pa-flot-info span {
    margin-left: 10px;
    margin-right: 0;
    padding-left: 0;
    padding-right: 24px; }
  .right-to-left .pa-flot-info i {
    left: auto;
    right: 10px; }

/*# sourceMappingURL=rtl.css.map */
