.input-lg {
  height: 45px;
  padding: 10px 16px;
  font-size: 17px;
  line-height: 1.33;
  border-radius: 3px; }

select.input-lg {
  height: 45px;
  line-height: 45px; }

textarea.input-lg,
select[multiple].input-lg {
  height: auto; }

.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 2px; }

select.input-sm {
  height: 30px;
  line-height: 30px; }

textarea.input-sm,
select[multiple].input-sm {
  height: auto; }

.theme-default {
  background: #f6f6f6; }
.theme-default .page-header {
  background: #f6f6f6;
  border-color: #e9e9e9; }
.theme-default .table,
.theme-default .panel,
.theme-default hr {
  border-color: #e2e2e2; }
.theme-default .bordered {
  border-color: #e2e2e2; }
.theme-default .bordered[class*='bg-'],
.theme-default [class*='bg-'] .border-t {
  border-color: #fff;
  border-color: rgba(0, 0, 0, 0.2); }
.theme-default .panel.panel-transparent > .panel-heading {
  border-bottom-color: #e2e2e2 !important; }
.theme-default .breadcrumb-page {
  background: #f6f6f6;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 6px;
  padding-top: 6px; }
.theme-default #main-navbar {
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05); }
  .theme-default #main-navbar .navbar-header {
    background: #fff; }
    .theme-default #main-navbar .navbar-header a {
      color: #555; }
  .theme-default #main-navbar .navbar-brand {
    color: #555; }
  .theme-default #main-navbar .navbar-toggle,
  .theme-default #main-navbar #main-menu-toggle {
    color: #555; }
  .theme-default #main-navbar #main-menu-toggle {
    background: #f8f8f8; }
    .theme-default #main-navbar #main-menu-toggle .fa:before {
      color: #555; }
  .theme-default #main-navbar .navbar-collapse {
    border-top: 1px solid #f1f1f1;
    background: #fff;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .theme-default #main-navbar .dropdown > a,
  .theme-default #main-navbar .dropdown-menu > li > a,
  .theme-default #main-navbar .nav > li > a {
    color: #555; }
  .theme-default #main-navbar li + li,
  .theme-default #main-navbar .nav + .nav,
  .theme-default #main-navbar .nav + .right {
    border-top: 1px solid #f1f1f1; }
  .theme-default #main-navbar .dropdown.open > a {
    border-bottom: 1px solid #e4e4e4;
    border-top: 1px solid #dfdfdf;
    margin-top: -1px;
    background: #f6f6f6;
    color: #555; }
  .theme-default #main-navbar form.navbar-form {
    -webkit-box-shadow: none;
    box-shadow: none; }
    .theme-default #main-navbar form.navbar-form .form-control {
      background: #f8f8f8;
      border: none;
      color: #999; }
      .theme-default #main-navbar form.navbar-form .form-control:focus {
        color: #555;
        -webkit-box-shadow: none;
        box-shadow: none; }
@media (min-width: 768px) {
  .theme-default #main-navbar .navbar-header {
    background: #fff; }
  .theme-default #main-navbar .navbar-collapse {
    background: #fff;
    border: none; }
    .theme-default #main-navbar .navbar-collapse > div > .navbar-nav {
      border-left: 1px solid #f1f1f1; }
  .theme-default #main-navbar .right > .navbar-nav > li {
    border-left: 1px solid #f1f1f1;
    border-right: none; }
  .theme-default #main-navbar .navbar-nav > li {
    border-right: 1px solid #f1f1f1; }
    .theme-default #main-navbar .navbar-nav > li > a {
      color: #555; }
    .theme-default #main-navbar .navbar-nav > li > a:hover, .theme-default #main-navbar .navbar-nav > li.active > a, .theme-default #main-navbar .navbar-nav > li.dropdown.open > a {
      background: #f8f8f8;
      border-top: none !important;
      border-bottom: none !important; }
  .theme-default #main-navbar li + li,
  .theme-default #main-navbar .nav + .nav,
  .theme-default #main-navbar .nav + .right {
    border-top: none; }
  .theme-default #main-navbar .dropdown.open > a {
    margin: 0;
    border-top: none; }
  .theme-default #main-navbar .dropdown-menu {
    background: #fff;
    padding: 5px 0; }
    .theme-default #main-navbar .dropdown-menu .divider {
      background-color: #f1f1f1; }
    .theme-default #main-navbar .dropdown-menu > li > a {
      color: #555; }
      .theme-default #main-navbar .dropdown-menu > li > a:hover {
        background: #f1f1f1; }
  .theme-default #main-navbar .widget-notifications a {
    color: !important; }
  .theme-default #main-navbar .widget-notifications .notification,
  .theme-default #main-navbar .widget-notifications .notifications-link {
    border-color: #f1f1f1;
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }
  .theme-default #main-navbar .widget-notifications .notification-description {
    color: #999; }
  .theme-default #main-navbar .widget-notifications .notification-ago {
    color: #bbb; }
  .theme-default #main-navbar .widget-notifications .notifications-link:hover {
    background: #f1f1f1; }
  .theme-default #main-navbar .widget-messages-alt a {
    color: !important; }
  .theme-default #main-navbar .widget-messages-alt .message,
  .theme-default #main-navbar .widget-messages-alt .messages-link {
    border-color: #f1f1f1;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }
  .theme-default #main-navbar .widget-messages-alt .message-subject {
    color: #555 !important; }
  .theme-default #main-navbar .widget-messages-alt .message-description {
    color: #aaa; }
    .theme-default #main-navbar .widget-messages-alt .message-description a {
      color: #888; }
  .theme-default #main-navbar .widget-messages-alt .messages-link:hover {
    background: #f1f1f1; }
  .theme-default.main-menu-right #main-navbar .navbar-header, .theme-default.mmc #main-navbar .navbar-header {
    background: #fff; }
  .theme-default.main-menu-right #main-navbar .navbar-header {
    border-right: 1px solid #f1f1f1; } }
@media (min-width: 768px) {
  .theme-default.right-to-left #main-navbar .right > .navbar-nav > li {
    border-right: 1px solid #f1f1f1;
    border-left: none; } }
.theme-default #main-menu-bg {
  background-color: #23272d; }
.theme-default #main-menu .navigation a,
.theme-default #main-menu .mmc-dropdown-open-ul a {
  color: #808b9c;
  position: relative;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s; }
  .theme-default #main-menu .navigation a:after,
  .theme-default #main-menu .mmc-dropdown-open-ul a:after {
    content: "";
    top: 0;
    bottom: 0;
    width: 4px;
    position: absolute;
    right: auto;
    background: #1a7ab9;
    display: none;
    left: 0; }
  .theme-default #main-menu .navigation a:hover,
  .theme-default #main-menu .mmc-dropdown-open-ul a:hover {
    color: #fff; }
    .theme-default #main-menu .navigation a:hover:after,
    .theme-default #main-menu .mmc-dropdown-open-ul a:hover:after {
      display: block !important; }
.theme-default #main-menu li.active > a {
  background: #181b1f;
  color: #fff; }
  .theme-default #main-menu li.active > a .menu-icon {
    color: #1d89cf; }
  .theme-default #main-menu li.active > a:after {
    display: block; }
.theme-default #main-menu .mm-dropdown.active > a {
  background: none; }
.theme-default #main-menu .mm-dropdown.active > a:after {
  display: none; }
.theme-default #main-menu .mm-dropdown > ul,
.theme-default #main-menu .mmc-dropdown-open-ul {
  background: #2a2e36; }
.theme-default #main-menu .mmc-dropdown-open-ul .mmc-title {
  background: #181b1f;
  color: #fff; }
.theme-default #main-menu .mmc-dropdown-open > a {
  background: #181b1f !important; }
  .theme-default #main-menu .mmc-dropdown-open > a,
  .theme-default #main-menu .mmc-dropdown-open > a .menu-icon {
    color: #fff !important; }
  .theme-default #main-menu .mmc-dropdown-open > a:hover:after {
    display: none !important; }
.theme-default #main-menu .menu-content {
  border-color: #2e333b; }
  .theme-default #main-menu .menu-content .btn-outline {
    border-color: #2e333b;
    color: #808b9c; }
    .theme-default #main-menu .menu-content .btn-outline:hover {
      color: #fff; }
.theme-default .mmc-dropdown-open .mm-dropdown.open > a, .theme-default.mme #main-menu .mm-dropdown.open > a {
  color: #fff; }
@media (min-width: 768px) {
  .theme-default #main-menu .mm-dropdown.open > a {
    color: #fff; }
  .theme-default.mmc #main-menu .navigation > .mm-dropdown.open > a {
    color: #808b9c; } }
.theme-default.right-to-left #main-menu .navigation a:after {
  left: auto;
  right: 0; }
.theme-default #main-navbar .navbar-brand div,
.theme-default .demo-logo {
  background: #1d89cf; }
.theme-default #main-navbar .navbar-header a {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600; }
.theme-default.page-signin .signin-info, .theme-default.page-signup .signup-header {
  background: #1d89cf;
  background: rgba(29, 137, 207, 0.8); }
.theme-default .timeline:before,
.theme-default .timeline:after,
.theme-default .tl-header,
.theme-default .tl-icon {
  background: #dddddd; }
.theme-default .tl-header,
.theme-default .tl-icon {
  box-shadow: 0 0 0 4px #f6f6f6; }
.theme-default .tl-entry:before {
  background: #1d89cf;
  box-shadow: 0 0 0 3px #f6f6f6; }
.theme-default .tl-body:before {
  border-color: transparent #e2e2e2 transparent transparent; }
@media (min-width: 768px) {
  .theme-default .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent transparent transparent #e2e2e2; }
  .theme-default.right-to-left .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent #e2e2e2 transparent transparent; } }
.theme-default.right-to-left .tl-body:before {
  border-color: transparent transparent transparent #e2e2e2; }
.theme-default.page-mail .mail-nav,
.theme-default.page-mail .mail-nav:before {
  background: #f6f6f6; }
.theme-default.page-mail .mail-nav .sections li.active .label,
.theme-default.page-mail .mail-nav .sections li.active .badge {
  background: #1d89cf; }
.theme-default.page-mail .mail-nav,
.theme-default.page-mail .mail-nav .compose-btn,
.theme-default.page-mail .mail-nav .sections li.active,
.theme-default.page-mail .mail-nav:before {
  border-color: #e2e2e2; }
.theme-default.page-mail .mail-nav .navigation.open .sections li.active {
  background: #ececec; }
.theme-default.page-mail .mail-nav li.divider {
  background: #e2e2e2; }
.theme-default.page-mail .mail-nav .sections li.active a {
  color: #444444; }
.theme-default.page-mail .mail-nav .sections li.active:after {
  color: #aaaaaa; }
.theme-default.page-mail .mail-nav .sections a {
  color: #6a6a6a; }
.theme-default.page-mail .mail-nav-header {
  color: #aaaaaa; }
.theme-default.page-mail .mail-nav .add-more a {
  color: #aaaaaa; }
@media (min-width: 992px) {
  .theme-default.page-mail .mail-nav .navigation .sections li.active {
    background: #ececec; }
  .theme-default.page-mail .mail-nav .sections a:hover {
    color: #444444; } }
.theme-default .nav-icon-btn.nav-icon-btn-primary .label {
  background: #1d89cf; }
.theme-default .btn-primary {
  color: #fff; }
  .theme-default .btn-primary, .theme-default .btn-primary:focus {
    border-color: #1a7ab9;
    border-bottom-color: #16689e;
    background: #1d89cf;
    background-image: -webkit-linear-gradient(top, #1e8cd3 0%, #1a7ab9 100%);
    background-image: linear-gradient(to bottom, #1e8cd3 0%, #1a7ab9 100%);
    background-repeat: repeat-x; }
  .theme-default .btn-primary:hover {
    color: #fff;
    border-color: #1871ab;
    border-bottom-color: #12547e;
    background: #1a7ab9 !important;
    background-image: -webkit-linear-gradient(top, #1c83c6 0%, #1871ab 100%) !important;
    background-image: linear-gradient(to bottom, #1c83c6 0%, #1871ab 100%) !important;
    background-repeat: repeat-x; }
  .theme-default .btn-primary:active, .theme-default .btn-primary.active, .open .theme-default .btn-primary.dropdown-toggle {
    background: none;
    background: #1a7dbd !important;
    border-color: #146090;
    border-bottom-color: #146090 !important;
    color: #fff;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset; }
  .theme-default .btn-primary.btn-flat {
    background: #1b80c2; }
    .theme-default .btn-primary.btn-flat:hover, .theme-default .btn-primary.btn-flat:active, .theme-default .btn-primary.btn-flat.active, .open .theme-default .btn-primary.btn-flat.dropdown-toggle {
      background: #1b80c2 !important;
      color: #fff; }
  .theme-default .btn-primary.btn-outline:hover, .theme-default .btn-primary.btn-outline.btn-flat:hover {
    color: #fff;
    border-color: #1871ab;
    border-bottom-color: #12547e; }
  .theme-default .btn-primary.btn-outline:active, .theme-default .btn-primary.btn-outline.btn-flat:active {
    border-color: #146090; }
  .btn-group .theme-default .btn-primary, .btn-group .theme-default .btn-primary:focus, .input-group-btn .theme-default .btn-primary, .input-group-btn .theme-default .btn-primary:focus {
    border-left-color: #1871ab;
    border-right-color: #1871ab; }
  .btn-group .theme-default .btn-primary:hover, .btn-group.open .theme-default .btn-primary.dropdown-toggle, .input-group-btn .theme-default .btn-primary:hover, .input-group-btn.open .theme-default .btn-primary.dropdown-toggle {
    border-color: #1871ab;
    border-bottom-color: #12547e; }
  .btn-group .theme-default .btn-primary:active, .btn-group .theme-default .btn-primary.active, .input-group-btn .theme-default .btn-primary:active, .input-group-btn .theme-default .btn-primary.active {
    border-left-color: #146090;
    border-right-color: #146090; }
.theme-default .open .btn.btn-primary.dropdown-toggle {
  color: #fff;
  border-bottom-color: #146090 !important;
  background: none;
  background: #1a7dbd !important; }
.theme-default .progress-bar {
  background: #1d89cf;
  border-color: #1977b4; }
.theme-default .progress-striped .progress-bar {
  background-color: #1d89cf;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px; }
.theme-default .label.label-primary {
  background: #1d89cf; }
  .theme-default .label.label-primary[href]:hover, .theme-default .label.label-primary[href]:focus {
    background-color: #176ba2; }
  .theme-default .label.label-primary.label-tag {
    border: 1px solid #1d89cf; }
    .theme-default .label.label-primary.label-tag:before {
      border-color: transparent #1d89cf transparent transparent; }
    .theme-default .label.label-primary.label-tag[href]:hover, .theme-default .label.label-primary.label-tag[href]:focus {
      border-color: #176ba2; }
    .theme-default .label.label-primary.label-tag[href]:hover:before, .theme-default .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent #176ba2 transparent transparent; }
    .right-to-left .theme-default .label.label-primary.label-tag:before {
      border-color: transparent transparent transparent #1d89cf; }
    .right-to-left .theme-default .label.label-primary.label-tag[href]:hover:before, .right-to-left .theme-default .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent transparent transparent #176ba2; }
    .ie8 .right-to-left .theme-default .label.label-primary.label-tag:before, .ie9 .right-to-left .theme-default .label.label-primary.label-tag:before {
      border-color: transparent #1d89cf transparent transparent; }
    .ie8 .right-to-left .theme-default .label.label-primary.label-tag[href]:hover:before, .ie8 .right-to-left .theme-default .label.label-primary.label-tag[href]:focus:before, .ie9 .right-to-left .theme-default .label.label-primary.label-tag[href]:hover:before, .ie9 .right-to-left .theme-default .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent #176ba2 transparent transparent; }
.theme-default.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent transparent transparent #1d89cf; }
.theme-default.right-to-left .label.label-primary.label-tag:hover:before, .theme-default.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent transparent transparent #176ba2; }
.ie8 .theme-default.right-to-left .label.label-primary.label-tag:before, .ie9 .theme-default.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent #1d89cf transparent transparent; }
.ie8 .theme-default.right-to-left .label.label-primary.label-tag:hover:before, .ie8 .theme-default.right-to-left .label.label-primary.label-tag:focus:before, .ie9 .theme-default.right-to-left .label.label-primary.label-tag:hover:before, .ie9 .theme-default.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent #176ba2 transparent transparent; }
.theme-default .badge.badge-primary {
  background: #1d89cf;
  border: 1px solid #1d89cf; }
  .theme-default .badge.badge-primary[href]:hover, .theme-default .badge.badge-primary[href]:focus {
    background-color: #176ba2;
    border-color: #176ba2; }
.theme-default .table-primary table {
  border-top-color: #23638c !important; }
.theme-default .table-primary thead,
.theme-default .table-primary thead tr,
.theme-default .table-primary thead th,
.theme-default .table-primary .table-header {
  border-color: #23638c !important;
  color: #fff; }
.theme-default .table-primary thead tr,
.theme-default .table-primary thead th {
  background: #2175ac; }
.theme-default .table-primary .table-header {
  background: #1d89cf; }
.theme-default .nav-tabs > li.active > a, .theme-default .nav-tabs > li.active > a:hover, .theme-default .nav-tabs > li.active > a:focus {
  background: #1d89cf;
  border-bottom: 2px solid #1a7ab9; }
.theme-default .nav-tabs .nav-tabs.nav-justified > .active > a {
  border-bottom-color: #1a7ab9; }
.theme-default .nav-pills > li.active > a, .theme-default .nav-pills > li.active > a:hover, .theme-default .nav-pills > li.active > a:focus {
  background: #1d89cf; }
.theme-default .dropdown-menu > li > a:hover,
.theme-default .dropdown-menu > li.active > a {
  background: #1d89cf; }
.theme-default .dropdown-menu > li > a:hover {
  color: #fff; }
.theme-default .btn-group .btn-primary,
.theme-default .btn-group .btn-primary:focus,
.theme-default .input-group-btn .btn-primary,
.theme-default .input-group-btn .btn-primary:focus {
  border-left-color: #1871ab;
  border-right-color: #1871ab; }
.theme-default .btn-group .btn-primary:hover,
.theme-default .btn-group.open .btn-primary.dropdown-toggle,
.theme-default .input-group-btn .btn-primary:hover,
.theme-default .input-group-btn.open .btn-primary.dropdown-toggle {
  border-color: #146090; }
.theme-default .btn-group .btn-primary:active,
.theme-default .btn-group .btn-primary.active,
.theme-default .input-group-btn .btn-primary:active,
.theme-default .input-group-btn .btn-primary.active {
  border-left-color: #146090;
  border-right-color: #146090; }
.theme-default .pagination > li > a:hover {
  border-color: #1d89cf; }
.theme-default .pagination > li.active > a,
.theme-default .pagination > li.active > a:hover,
.theme-default .pagination > li.active > a:focus,
.theme-default .pagination > li.active > span {
  background: #1d89cf;
  border-color: #1d89cf; }
.theme-default .pager li > a:hover {
  border-color: #1d89cf; }
.theme-default a.list-group-item.active {
  background: #1d89cf;
  border-color: #1d89cf; }
  .theme-default a.list-group-item.active .list-group-item-text {
    color: #c2e2f6; }
.theme-default .form-control:focus,
.theme-default .form-control.focus {
  border-color: #1d89cf;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }
.gt-ie8 .theme-default input.px:focus + .lbl:before, .gt-ie8 .theme-default input.px + .lbl:hover:before {
  border-color: #1d89cf; }
.gt-ie8 .theme-default input[type="checkbox"].px + .lbl:after {
  color: #1d89cf; }
.gt-ie8 .theme-default input[type="radio"].px + .lbl:after {
  background: #1d89cf; }
.theme-default .text-primary, .theme-default .text-primary:hover, .theme-default .text-primary:active, .theme-default .text-primary:focus,
.theme-default .text-primary a,
.theme-default .text-primary a:focus {
  color: #1d89cf; }
.theme-default .text-primary a:hover,
.theme-default .text-primary a:active {
  color: #145d8c; }
.theme-default .bg-primary {
  background: #1d89cf !important; }
  .theme-default .bg-primary[href]:hover {
    background: #227fbb !important; }
  .theme-default .bg-primary.darken {
    background: #227fbb !important; }
    .theme-default .bg-primary.darken[href]:hover {
      background: #1f76ae !important; }
  .theme-default .bg-primary.darker {
    background: #1f76ae !important; }
    .theme-default .bg-primary.darker[href]:hover {
      background: #1d6da1 !important; }
  .theme-default .bg-primary, .theme-default .bg-primary:hover, .theme-default .bg-primary:active, .theme-default .bg-primary:focus,
  .theme-default .bg-primary a,
  .theme-default .bg-primary a:focus {
    color: #fff; }
  .theme-default .bg-primary a:hover,
  .theme-default .bg-primary a:active {
    color: #d9d9d9; }
  .theme-default .bg-primary[href]:hover,
  .theme-default .bg-primary [href]:hover {
    color: #fff; }
  .theme-default .bg-primary * {
    border-color: #2e9be2; }
.theme-default .ui-slider-handle.ui-state-active:before {
  background: #1d89cf; }
.theme-default .ui-slider-range {
  background: #1d89cf; }
.theme-default .ui-autocomplete > li > a:hover,
.theme-default .ui-autocompletev > li.active > a {
  background: #1d89cf; }
.theme-default .ui-autocomplete > li > a:hover {
  color: #fff; }
.theme-default .ui-autocomplete .ui-state-focus {
  background: #1d89cf;
  color: #fff; }
.theme-default .ui-menu .ui-menu-item a:hover,
.theme-default .ui-menu .ui-menu-item a.ui-state-focus,
.theme-default .ui-menu .ui-menu-item a.ui-state-active {
  background: #1d89cf; }
.theme-default .ui-progressbar-value {
  border-color: #1977b4;
  background-color: #1d89cf;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px; }
.theme-default .ui-tabs-nav > .ui-tabs-active > a, .theme-default .ui-tabs-nav > .ui-tabs-active > a:hover, .theme-default .ui-tabs-nav > .ui-tabs-active > a:focus {
  color: #fff;
  background: #1d89cf;
  border-bottom: 2px solid #1a7ab9; }
.theme-default .ui-datepicker .ui-state-active {
  background: #1d89cf !important; }
.theme-default .ui-datepicker-title {
  background: #1d89cf; }
.theme-default .ui-datepicker th {
  background: #2f76a4; }
.theme-default .ui-datepicker-buttonpane button.ui-priority-primary {
  color: #fff; }
  .theme-default .ui-datepicker-buttonpane button.ui-priority-primary, .theme-default .ui-datepicker-buttonpane button.ui-priority-primary:focus {
    border-color: #1a7ab9;
    border-bottom-color: #16689e;
    background: #1d89cf;
    background-image: -webkit-linear-gradient(top, #1e8cd3 0%, #1a7ab9 100%);
    background-image: linear-gradient(to bottom, #1e8cd3 0%, #1a7ab9 100%);
    background-repeat: repeat-x; }
  .theme-default .ui-datepicker-buttonpane button.ui-priority-primary:hover {
    color: #fff;
    border-color: #1871ab;
    border-bottom-color: #12547e;
    background: #1a7ab9 !important;
    background-image: -webkit-linear-gradient(top, #1c83c6 0%, #1871ab 100%) !important;
    background-image: linear-gradient(to bottom, #1c83c6 0%, #1871ab 100%) !important;
    background-repeat: repeat-x; }
  .theme-default .ui-datepicker-buttonpane button.ui-priority-primary:active, .theme-default .ui-datepicker-buttonpane button.ui-priority-primary.active, .open .theme-default .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
    background: none;
    background: #1a7dbd !important;
    border-color: #146090;
    border-bottom-color: #146090 !important;
    color: #fff;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset; }
  .theme-default .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat {
    background: #1b80c2; }
    .theme-default .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:hover, .theme-default .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:active, .theme-default .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.active, .open .theme-default .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.dropdown-toggle {
      background: #1b80c2 !important;
      color: #fff; }
  .theme-default .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:hover, .theme-default .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:hover {
    color: #fff;
    border-color: #1871ab;
    border-bottom-color: #12547e; }
  .theme-default .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:active, .theme-default .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:active {
    border-color: #146090; }
  .btn-group .theme-default .ui-datepicker-buttonpane button.ui-priority-primary, .btn-group .theme-default .ui-datepicker-buttonpane button.ui-priority-primary:focus, .input-group-btn .theme-default .ui-datepicker-buttonpane button.ui-priority-primary, .input-group-btn .theme-default .ui-datepicker-buttonpane button.ui-priority-primary:focus {
    border-left-color: #1871ab;
    border-right-color: #1871ab; }
  .btn-group .theme-default .ui-datepicker-buttonpane button.ui-priority-primary:hover, .btn-group.open .theme-default .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle, .input-group-btn .theme-default .ui-datepicker-buttonpane button.ui-priority-primary:hover, .input-group-btn.open .theme-default .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
    border-color: #1871ab;
    border-bottom-color: #12547e; }
  .btn-group .theme-default .ui-datepicker-buttonpane button.ui-priority-primary:active, .btn-group .theme-default .ui-datepicker-buttonpane button.ui-priority-primary.active, .input-group-btn .theme-default .ui-datepicker-buttonpane button.ui-priority-primary:active, .input-group-btn .theme-default .ui-datepicker-buttonpane button.ui-priority-primary.active {
    border-left-color: #146090;
    border-right-color: #146090; }
.theme-default .select2-drop-active,
.theme-default .select2-container-multi.select2-container-active .select2-choices,
.theme-default .select2-container-multi.select2-dropdown-open .select2-choices {
  border: 1px solid #1d89cf; }
.theme-default .select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid #1d89cf; }
.theme-default .select2-container-active .select2-choice,
.theme-default .select2-container-active .select2-choices {
  border: 1px solid #1d89cf; }
.theme-default .select2-dropdown-open .select2-drop-above .select2-choice,
.theme-default .select2-dropdown-open .select2-drop-above .select2-choices {
  border: 1px solid #1d89cf;
  border-top-color: #aaa; }
.theme-default .select2-results .select2-highlighted {
  background: #1d89cf; }
.theme-default .select2-primary .select2-container-multi .select2-choices .select2-search-choice {
  background: #1d89cf; }
.theme-default .select2-primary .select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #176ba2; }
.theme-default .select2-primary .select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  background: #1d89cf !important; }
.theme-default .switcher-primary .switcher-state-on {
  background: #1d89cf !important; }
.theme-default .pixel-file-input:hover {
  border-color: #1d89cf; }
.theme-default .datepicker table tr td.selected, .theme-default .datepicker table tr td.selected:hover, .theme-default .datepicker table tr td.selected.disabled, .theme-default .datepicker table tr td.selected.disabled:hover {
  background: #1d89cf; }
.theme-default .datepicker table tr td.active, .theme-default .datepicker table tr td.active:hover, .theme-default .datepicker table tr td.active.disabled, .theme-default .datepicker table tr td.active.disabled:hover {
  background: #1d89cf; }
.theme-default .datepicker table tr td span.active, .theme-default .datepicker table tr td span.active:hover, .theme-default .datepicker table tr td span.active.disabled, .theme-default .datepicker table tr td span.active.disabled:hover {
  background: #1d89cf; }
.theme-default .datepicker thead tr {
  background: #2f76a4; }
  .theme-default .datepicker thead tr:first-child {
    background: #1d89cf !important;
    border-bottom-color: #30607f !important; }
.theme-default .datepicker thead th.prev,
.theme-default .datepicker thead th.next {
  background: #1d89cf; }
.theme-default .datepicker thead th.prev {
  border-color: #30607f; }
.theme-default .datepicker thead th.next {
  border-color: #30607f; }
.theme-default .datepicker > .datepicker-days thead tr:first-child th.cw + .prev {
  border-color: #30607f; }
.theme-default .datepicker > .datepicker-days thead tr:last-child th.cw + th {
  border-color: #30607f; }
.theme-default .bootstrap-timepicker-widget table td a:hover {
  background: #1d89cf;
  border-color: #1d89cf; }
.theme-default .pagination a.dp-today,
.theme-default .pagination a.dp-today:hover {
  background: #3ba1e4;
  border-color: #2597e0; }
.theme-default .pagination a.dp-today:hover {
  border-color: #1d89cf !important; }
.theme-default .pagination a.dp-selected {
  background: #1d89cf !important;
  border-color: #1d89cf !important; }
.theme-default .tt-suggestion.tt-is-under-cursor {
  background: #1d89cf; }
.theme-default .table-primary .dataTables_wrapper .DT-per-page {
  border-color: #23638c !important; }
.theme-default .dropzone-box.dz-drag-hover {
  border-color: #1d89cf; }
.theme-default .dropzone-box:hover .fa.fa-cloud-upload {
  background: #1d89cf; }
.theme-default .md-editor.active {
  border-color: #1d89cf; }
.theme-default .widget-rating .active a {
  color: #1d89cf; }

.theme-asphalt {
  background: #f3f3f3; }
.theme-asphalt .page-header {
  background: #fff;
  border-color: #e9e9e9; }
.theme-asphalt .table,
.theme-asphalt .panel,
.theme-asphalt hr {
  border-color: #e2e2e2; }
.theme-asphalt .bordered {
  border-color: #e2e2e2; }
.theme-asphalt .bordered[class*='bg-'],
.theme-asphalt [class*='bg-'] .border-t {
  border-color: #fff;
  border-color: rgba(0, 0, 0, 0.2); }
.theme-asphalt .panel.panel-transparent > .panel-heading {
  border-bottom-color: #e2e2e2 !important; }
.theme-asphalt .breadcrumb-page {
  background: #fff;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 6px;
  padding-top: 6px; }
.theme-asphalt #main-navbar .navbar-header {
  background: #333; }
  .theme-asphalt #main-navbar .navbar-header a {
    color: #fff; }
.theme-asphalt #main-navbar .navbar-brand {
  color: #fff; }
.theme-asphalt #main-navbar .navbar-toggle,
.theme-asphalt #main-navbar #main-menu-toggle {
  color: #fff; }
.theme-asphalt #main-navbar #main-menu-toggle {
  background: #3bc2a9; }
  .theme-asphalt #main-navbar #main-menu-toggle .fa:before {
    color: #fff; }
.theme-asphalt #main-navbar .navbar-collapse {
  border-top: 1px solid #424242;
  background: #333;
  -webkit-box-shadow: none;
  box-shadow: none; }
.theme-asphalt #main-navbar .dropdown > a,
.theme-asphalt #main-navbar .dropdown-menu > li > a,
.theme-asphalt #main-navbar .nav > li > a {
  color: #b3b3b3; }
.theme-asphalt #main-navbar li + li,
.theme-asphalt #main-navbar .nav + .nav,
.theme-asphalt #main-navbar .nav + .right {
  border-top: 1px solid #424242; }
.theme-asphalt #main-navbar .dropdown.open > a {
  background: #424242;
  border-bottom: 1px solid #4d4d4d;
  border-top: 1px solid #595959;
  margin-top: -1px;
  color: #fff; }
.theme-asphalt #main-navbar form.navbar-form {
  -webkit-box-shadow: none;
  box-shadow: none; }
  .theme-asphalt #main-navbar form.navbar-form .form-control {
    background: #fff;
    background: rgba(255, 255, 255, 0.05);
    border: none;
    color: #999; }
    .theme-asphalt #main-navbar form.navbar-form .form-control:focus {
      background: #fff;
      color: #555;
      -webkit-box-shadow: none;
      box-shadow: none; }
@media (min-width: 768px) {
  .theme-asphalt #main-navbar #main-menu-toggle {
    background: #35ae98; }
  .theme-asphalt #main-navbar .navbar-header {
    background: #3bc2a9; }
  .theme-asphalt #main-navbar .navbar-collapse {
    background: #333;
    border: none; }
    .theme-asphalt #main-navbar .navbar-collapse > div > .navbar-nav {
      border-left: 1px solid #292929;
      -webkit-box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03) inset;
      box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03) inset; }
  .theme-asphalt #main-navbar .right > .navbar-nav > li {
    border-left: 1px solid #292929;
    border-right: none;
    -webkit-box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03) inset;
    box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03) inset; }
  .theme-asphalt #main-navbar .navbar-nav > li {
    border-right: 1px solid #292929;
    -webkit-box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03);
    box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03); }
    .theme-asphalt #main-navbar .navbar-nav > li > a {
      color: #b3b3b3; }
    .theme-asphalt #main-navbar .navbar-nav > li > a:hover, .theme-asphalt #main-navbar .navbar-nav > li.active > a, .theme-asphalt #main-navbar .navbar-nav > li.dropdown.open > a {
      border-bottom: 3px solid #3bc2a9;
      color: #fff; }
  .theme-asphalt #main-navbar li + li,
  .theme-asphalt #main-navbar .nav + .nav,
  .theme-asphalt #main-navbar .nav + .right {
    border-top: none; }
  .theme-asphalt #main-navbar .dropdown.open > a {
    margin: 0;
    border-top: none; }
  .theme-asphalt #main-navbar .dropdown-menu {
    background: #333;
    border: none;
    border-top: 1px solid #3bc2a9;
    padding: 5px 0; }
    .theme-asphalt #main-navbar .dropdown-menu .divider {
      background-color: #292929; }
    .theme-asphalt #main-navbar .dropdown-menu > li > a {
      color: #fff; }
      .theme-asphalt #main-navbar .dropdown-menu > li > a:hover {
        background: #3d3d3d; }
  .theme-asphalt #main-navbar .widget-notifications a:hover {
    color: #fff !important; }
  .theme-asphalt #main-navbar .widget-notifications .notification,
  .theme-asphalt #main-navbar .widget-notifications .notifications-link {
    border-color: #292929;
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }
  .theme-asphalt #main-navbar .widget-notifications .notification-description {
    color: #888; }
  .theme-asphalt #main-navbar .widget-notifications .notification-ago {
    color: #666; }
  .theme-asphalt #main-navbar .widget-notifications .notifications-link:hover {
    background: #424242; }
  .theme-asphalt #main-navbar .widget-messages-alt a:hover {
    color: #fff !important; }
  .theme-asphalt #main-navbar .widget-messages-alt .message,
  .theme-asphalt #main-navbar .widget-messages-alt .messages-link {
    border-color: #292929;
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }
  .theme-asphalt #main-navbar .widget-messages-alt .message-subject {
    color: #bbb; }
  .theme-asphalt #main-navbar .widget-messages-alt .message-description {
    color: #666; }
    .theme-asphalt #main-navbar .widget-messages-alt .message-description a {
      color: #888; }
  .theme-asphalt #main-navbar .widget-messages-alt .messages-link:hover {
    background: #424242; }
  .theme-asphalt.main-menu-right #main-navbar .navbar-header, .theme-asphalt.mmc #main-navbar .navbar-header, .theme-asphalt.no-main-menu #main-navbar .navbar-header {
    background: #333; }
  .theme-asphalt.main-menu-right #main-navbar .navbar-header {
    border-right: 1px solid #292929; } }
@media (min-width: 768px) {
  .theme-asphalt.right-to-left #main-navbar .right > .navbar-nav > li {
    border-right: 1px solid #292929;
    border-left: none;
    -webkit-box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03);
    box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03); }
  .theme-asphalt.right-to-left.mmc #main-navbar .navbar-header {
    -webkit-box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03) inset;
    box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03) inset; } }
.theme-asphalt #main-menu-bg {
  background-color: #515151; }
.theme-asphalt #main-menu .navigation a,
.theme-asphalt #main-menu .mmc-dropdown-open-ul a {
  color: #b7b7b7;
  position: relative;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s; }
  .theme-asphalt #main-menu .navigation a:after,
  .theme-asphalt #main-menu .mmc-dropdown-open-ul a:after {
    content: "";
    top: 0;
    bottom: 0;
    width: 4px;
    position: absolute;
    right: auto;
    background: #35ae98;
    display: none;
    left: 0; }
  .theme-asphalt #main-menu .navigation a:hover,
  .theme-asphalt #main-menu .mmc-dropdown-open-ul a:hover {
    color: #fff; }
    .theme-asphalt #main-menu .navigation a:hover:after,
    .theme-asphalt #main-menu .mmc-dropdown-open-ul a:hover:after {
      display: block !important; }
.theme-asphalt #main-menu li.active > a {
  background: #444444;
  color: #fff; }
  .theme-asphalt #main-menu li.active > a .menu-icon {
    color: #3bc2a9; }
  .theme-asphalt #main-menu li.active > a:after {
    display: block; }
.theme-asphalt #main-menu .mm-dropdown.active > a {
  background: none; }
.theme-asphalt #main-menu .mm-dropdown.active > a:after {
  display: none; }
.theme-asphalt #main-menu .mm-dropdown > ul,
.theme-asphalt #main-menu .mmc-dropdown-open-ul {
  background: #595959; }
.theme-asphalt #main-menu .mmc-dropdown-open-ul .mmc-title {
  background: #35ae98;
  color: #fff; }
.theme-asphalt #main-menu .mmc-dropdown-open > a {
  background: #35ae98 !important; }
  .theme-asphalt #main-menu .mmc-dropdown-open > a,
  .theme-asphalt #main-menu .mmc-dropdown-open > a .menu-icon {
    color: #fff !important; }
  .theme-asphalt #main-menu .mmc-dropdown-open > a:hover:after {
    display: none !important; }
.theme-asphalt #main-menu .menu-content {
  border-color: #5e5e5e; }
  .theme-asphalt #main-menu .menu-content .btn-outline {
    border-color: #5e5e5e;
    color: #b7b7b7; }
    .theme-asphalt #main-menu .menu-content .btn-outline:hover {
      color: #fff; }
.theme-asphalt .mmc-dropdown-open .mm-dropdown.open > a, .theme-asphalt.mme #main-menu .mm-dropdown.open > a {
  color: #fff; }
@media (min-width: 768px) {
  .theme-asphalt #main-menu .mm-dropdown.open > a {
    color: #fff; }
  .theme-asphalt.mmc #main-menu .navigation > .mm-dropdown.open > a {
    color: #b7b7b7; } }
.theme-asphalt.right-to-left #main-menu .navigation a:after {
  left: auto;
  right: 0; }
.theme-asphalt #main-navbar .navbar-brand div,
.theme-asphalt .demo-logo {
  background: #3bc2a9; }
.theme-asphalt.page-signin .signin-info, .theme-asphalt.page-signup .signup-header {
  background: #52bdb4;
  background: rgba(82, 189, 180, 0.8); }
.theme-asphalt .timeline:before,
.theme-asphalt .timeline:after,
.theme-asphalt .tl-header,
.theme-asphalt .tl-icon {
  background: #dadada; }
.theme-asphalt .tl-header,
.theme-asphalt .tl-icon {
  box-shadow: 0 0 0 4px #f3f3f3; }
.theme-asphalt .tl-entry:before {
  background: #52bdb4;
  box-shadow: 0 0 0 3px #f3f3f3; }
.theme-asphalt .tl-body:before {
  border-color: transparent #e2e2e2 transparent transparent; }
@media (min-width: 768px) {
  .theme-asphalt .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent transparent transparent #e2e2e2; }
  .theme-asphalt.right-to-left .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent #e2e2e2 transparent transparent; } }
.theme-asphalt.right-to-left .tl-body:before {
  border-color: transparent transparent transparent #e2e2e2; }
.theme-asphalt.page-mail .mail-nav,
.theme-asphalt.page-mail .mail-nav:before {
  background: #f3f3f3; }
.theme-asphalt.page-mail .mail-nav .sections li.active .label,
.theme-asphalt.page-mail .mail-nav .sections li.active .badge {
  background: #52bdb4; }
.theme-asphalt.page-mail .mail-nav,
.theme-asphalt.page-mail .mail-nav .compose-btn,
.theme-asphalt.page-mail .mail-nav .sections li.active,
.theme-asphalt.page-mail .mail-nav:before {
  border-color: #e2e2e2; }
.theme-asphalt.page-mail .mail-nav .navigation.open .sections li.active {
  background: #e9e9e9; }
.theme-asphalt.page-mail .mail-nav li.divider {
  background: #e2e2e2; }
.theme-asphalt.page-mail .mail-nav .sections li.active a {
  color: #414141; }
.theme-asphalt.page-mail .mail-nav .sections li.active:after {
  color: #a7a7a7; }
.theme-asphalt.page-mail .mail-nav .sections a {
  color: #676767; }
.theme-asphalt.page-mail .mail-nav-header {
  color: #a7a7a7; }
.theme-asphalt.page-mail .mail-nav .add-more a {
  color: #a7a7a7; }
@media (min-width: 992px) {
  .theme-asphalt.page-mail .mail-nav .navigation .sections li.active {
    background: #e9e9e9; }
  .theme-asphalt.page-mail .mail-nav .sections a:hover {
    color: #414141; } }
.theme-asphalt .nav-icon-btn.nav-icon-btn-primary .label {
  background: #52bdb4; }
.theme-asphalt .btn-primary {
  color: #fff; }
  .theme-asphalt .btn-primary, .theme-asphalt .btn-primary:focus {
    border-color: #44b2a8;
    border-bottom-color: #3b9c93;
    background: #52bdb4;
    background-image: -webkit-linear-gradient(top, #56beb6 0%, #44b2a8 100%);
    background-image: linear-gradient(to bottom, #56beb6 0%, #44b2a8 100%);
    background-repeat: repeat-x; }
  .theme-asphalt .btn-primary:hover {
    color: #fff;
    border-color: #40a79e;
    border-bottom-color: #31827b;
    background: #44b2a8 !important;
    background-image: -webkit-linear-gradient(top, #4bbab1 0%, #40a79e 100%) !important;
    background-image: linear-gradient(to bottom, #4bbab1 0%, #40a79e 100%) !important;
    background-repeat: repeat-x; }
  .theme-asphalt .btn-primary:active, .theme-asphalt .btn-primary.active, .open .theme-asphalt .btn-primary.dropdown-toggle {
    background: none;
    background: #45b5ac !important;
    border-color: #379089;
    border-bottom-color: #379089 !important;
    color: #fff;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset; }
  .theme-asphalt .btn-primary.btn-flat {
    background: #47b9af; }
    .theme-asphalt .btn-primary.btn-flat:hover, .theme-asphalt .btn-primary.btn-flat:active, .theme-asphalt .btn-primary.btn-flat.active, .open .theme-asphalt .btn-primary.btn-flat.dropdown-toggle {
      background: #47b9af !important;
      color: #fff; }
  .theme-asphalt .btn-primary.btn-outline:hover, .theme-asphalt .btn-primary.btn-outline.btn-flat:hover {
    color: #fff;
    border-color: #40a79e;
    border-bottom-color: #31827b; }
  .theme-asphalt .btn-primary.btn-outline:active, .theme-asphalt .btn-primary.btn-outline.btn-flat:active {
    border-color: #379089; }
  .btn-group .theme-asphalt .btn-primary, .btn-group .theme-asphalt .btn-primary:focus, .input-group-btn .theme-asphalt .btn-primary, .input-group-btn .theme-asphalt .btn-primary:focus {
    border-left-color: #40a79e;
    border-right-color: #40a79e; }
  .btn-group .theme-asphalt .btn-primary:hover, .btn-group.open .theme-asphalt .btn-primary.dropdown-toggle, .input-group-btn .theme-asphalt .btn-primary:hover, .input-group-btn.open .theme-asphalt .btn-primary.dropdown-toggle {
    border-color: #40a79e;
    border-bottom-color: #31827b; }
  .btn-group .theme-asphalt .btn-primary:active, .btn-group .theme-asphalt .btn-primary.active, .input-group-btn .theme-asphalt .btn-primary:active, .input-group-btn .theme-asphalt .btn-primary.active {
    border-left-color: #379089;
    border-right-color: #379089; }
.theme-asphalt .open .btn.btn-primary.dropdown-toggle {
  color: #fff;
  border-bottom-color: #379089 !important;
  background: none;
  background: #45b5ac !important; }
.theme-asphalt .progress-bar {
  background: #52bdb4;
  border-color: #42aea5; }
.theme-asphalt .progress-striped .progress-bar {
  background-color: #52bdb4;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px; }
.theme-asphalt .label.label-primary {
  background: #52bdb4; }
  .theme-asphalt .label.label-primary[href]:hover, .theme-asphalt .label.label-primary[href]:focus {
    background-color: #3d9f97; }
  .theme-asphalt .label.label-primary.label-tag {
    border: 1px solid #52bdb4; }
    .theme-asphalt .label.label-primary.label-tag:before {
      border-color: transparent #52bdb4 transparent transparent; }
    .theme-asphalt .label.label-primary.label-tag[href]:hover, .theme-asphalt .label.label-primary.label-tag[href]:focus {
      border-color: #3d9f97; }
    .theme-asphalt .label.label-primary.label-tag[href]:hover:before, .theme-asphalt .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent #3d9f97 transparent transparent; }
    .right-to-left .theme-asphalt .label.label-primary.label-tag:before {
      border-color: transparent transparent transparent #52bdb4; }
    .right-to-left .theme-asphalt .label.label-primary.label-tag[href]:hover:before, .right-to-left .theme-asphalt .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent transparent transparent #3d9f97; }
    .ie8 .right-to-left .theme-asphalt .label.label-primary.label-tag:before, .ie9 .right-to-left .theme-asphalt .label.label-primary.label-tag:before {
      border-color: transparent #52bdb4 transparent transparent; }
    .ie8 .right-to-left .theme-asphalt .label.label-primary.label-tag[href]:hover:before, .ie8 .right-to-left .theme-asphalt .label.label-primary.label-tag[href]:focus:before, .ie9 .right-to-left .theme-asphalt .label.label-primary.label-tag[href]:hover:before, .ie9 .right-to-left .theme-asphalt .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent #3d9f97 transparent transparent; }
.theme-asphalt.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent transparent transparent #52bdb4; }
.theme-asphalt.right-to-left .label.label-primary.label-tag:hover:before, .theme-asphalt.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent transparent transparent #3d9f97; }
.ie8 .theme-asphalt.right-to-left .label.label-primary.label-tag:before, .ie9 .theme-asphalt.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent #52bdb4 transparent transparent; }
.ie8 .theme-asphalt.right-to-left .label.label-primary.label-tag:hover:before, .ie8 .theme-asphalt.right-to-left .label.label-primary.label-tag:focus:before, .ie9 .theme-asphalt.right-to-left .label.label-primary.label-tag:hover:before, .ie9 .theme-asphalt.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent #3d9f97 transparent transparent; }
.theme-asphalt .badge.badge-primary {
  background: #52bdb4;
  border: 1px solid #52bdb4; }
  .theme-asphalt .badge.badge-primary[href]:hover, .theme-asphalt .badge.badge-primary[href]:focus {
    background-color: #3d9f97;
    border-color: #3d9f97; }
.theme-asphalt .table-primary table {
  border-top-color: #4a8883 !important; }
.theme-asphalt .table-primary thead,
.theme-asphalt .table-primary thead tr,
.theme-asphalt .table-primary thead th,
.theme-asphalt .table-primary .table-header {
  border-color: #4a8883 !important;
  color: #fff; }
.theme-asphalt .table-primary thead tr,
.theme-asphalt .table-primary thead th {
  background: #4ca49d; }
.theme-asphalt .table-primary .table-header {
  background: #52bdb4; }
.theme-asphalt .nav-tabs > li.active > a, .theme-asphalt .nav-tabs > li.active > a:hover, .theme-asphalt .nav-tabs > li.active > a:focus {
  background: #52bdb4;
  border-bottom: 2px solid #44b2a8; }
.theme-asphalt .nav-tabs .nav-tabs.nav-justified > .active > a {
  border-bottom-color: #44b2a8; }
.theme-asphalt .nav-pills > li.active > a, .theme-asphalt .nav-pills > li.active > a:hover, .theme-asphalt .nav-pills > li.active > a:focus {
  background: #52bdb4; }
.theme-asphalt .dropdown-menu > li > a:hover,
.theme-asphalt .dropdown-menu > li.active > a {
  background: #52bdb4; }
.theme-asphalt .dropdown-menu > li > a:hover {
  color: #fff; }
.theme-asphalt .btn-group .btn-primary,
.theme-asphalt .btn-group .btn-primary:focus,
.theme-asphalt .input-group-btn .btn-primary,
.theme-asphalt .input-group-btn .btn-primary:focus {
  border-left-color: #40a79e;
  border-right-color: #40a79e; }
.theme-asphalt .btn-group .btn-primary:hover,
.theme-asphalt .btn-group.open .btn-primary.dropdown-toggle,
.theme-asphalt .input-group-btn .btn-primary:hover,
.theme-asphalt .input-group-btn.open .btn-primary.dropdown-toggle {
  border-color: #379089; }
.theme-asphalt .btn-group .btn-primary:active,
.theme-asphalt .btn-group .btn-primary.active,
.theme-asphalt .input-group-btn .btn-primary:active,
.theme-asphalt .input-group-btn .btn-primary.active {
  border-left-color: #379089;
  border-right-color: #379089; }
.theme-asphalt .pagination > li > a:hover {
  border-color: #52bdb4; }
.theme-asphalt .pagination > li.active > a,
.theme-asphalt .pagination > li.active > a:hover,
.theme-asphalt .pagination > li.active > a:focus,
.theme-asphalt .pagination > li.active > span {
  background: #52bdb4;
  border-color: #52bdb4; }
.theme-asphalt .pager li > a:hover {
  border-color: #52bdb4; }
.theme-asphalt a.list-group-item.active {
  background: #52bdb4;
  border-color: #52bdb4; }
  .theme-asphalt a.list-group-item.active .list-group-item-text {
    color: #e6f5f4; }
.theme-asphalt .form-control:focus,
.theme-asphalt .form-control.focus {
  border-color: #52bdb4;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }
.gt-ie8 .theme-asphalt input.px:focus + .lbl:before, .gt-ie8 .theme-asphalt input.px + .lbl:hover:before {
  border-color: #52bdb4; }
.gt-ie8 .theme-asphalt input[type="checkbox"].px + .lbl:after {
  color: #52bdb4; }
.gt-ie8 .theme-asphalt input[type="radio"].px + .lbl:after {
  background: #52bdb4; }
.theme-asphalt .text-primary, .theme-asphalt .text-primary:hover, .theme-asphalt .text-primary:active, .theme-asphalt .text-primary:focus,
.theme-asphalt .text-primary a,
.theme-asphalt .text-primary a:focus {
  color: #52bdb4; }
.theme-asphalt .text-primary a:hover,
.theme-asphalt .text-primary a:active {
  color: #368d85; }
.theme-asphalt .bg-primary {
  background: #52bdb4 !important; }
  .theme-asphalt .bg-primary[href]:hover {
    background: #4fb1a9 !important; }
  .theme-asphalt .bg-primary.darken {
    background: #4fb1a9 !important; }
    .theme-asphalt .bg-primary.darken[href]:hover {
      background: #4aa79f !important; }
  .theme-asphalt .bg-primary.darker {
    background: #4aa79f !important; }
    .theme-asphalt .bg-primary.darker[href]:hover {
      background: #459c95 !important; }
  .theme-asphalt .bg-primary, .theme-asphalt .bg-primary:hover, .theme-asphalt .bg-primary:active, .theme-asphalt .bg-primary:focus,
  .theme-asphalt .bg-primary a,
  .theme-asphalt .bg-primary a:focus {
    color: #fff; }
  .theme-asphalt .bg-primary a:hover,
  .theme-asphalt .bg-primary a:active {
    color: #d9d9d9; }
  .theme-asphalt .bg-primary[href]:hover,
  .theme-asphalt .bg-primary [href]:hover {
    color: #fff; }
  .theme-asphalt .bg-primary * {
    border-color: #6cc7bf; }
.theme-asphalt .ui-slider-handle.ui-state-active:before {
  background: #52bdb4; }
.theme-asphalt .ui-slider-range {
  background: #52bdb4; }
.theme-asphalt .ui-autocomplete > li > a:hover,
.theme-asphalt .ui-autocompletev > li.active > a {
  background: #52bdb4; }
.theme-asphalt .ui-autocomplete > li > a:hover {
  color: #fff; }
.theme-asphalt .ui-autocomplete .ui-state-focus {
  background: #52bdb4;
  color: #fff; }
.theme-asphalt .ui-menu .ui-menu-item a:hover,
.theme-asphalt .ui-menu .ui-menu-item a.ui-state-focus,
.theme-asphalt .ui-menu .ui-menu-item a.ui-state-active {
  background: #52bdb4; }
.theme-asphalt .ui-progressbar-value {
  border-color: #42aea5;
  background-color: #52bdb4;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px; }
.theme-asphalt .ui-tabs-nav > .ui-tabs-active > a, .theme-asphalt .ui-tabs-nav > .ui-tabs-active > a:hover, .theme-asphalt .ui-tabs-nav > .ui-tabs-active > a:focus {
  color: #fff;
  background: #52bdb4;
  border-bottom: 2px solid #44b2a8; }
.theme-asphalt .ui-datepicker .ui-state-active {
  background: #52bdb4 !important; }
.theme-asphalt .ui-datepicker-title {
  background: #52bdb4; }
.theme-asphalt .ui-datepicker th {
  background: #5c9994; }
.theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary {
  color: #fff; }
  .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary, .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary:focus {
    border-color: #44b2a8;
    border-bottom-color: #3b9c93;
    background: #52bdb4;
    background-image: -webkit-linear-gradient(top, #56beb6 0%, #44b2a8 100%);
    background-image: linear-gradient(to bottom, #56beb6 0%, #44b2a8 100%);
    background-repeat: repeat-x; }
  .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary:hover {
    color: #fff;
    border-color: #40a79e;
    border-bottom-color: #31827b;
    background: #44b2a8 !important;
    background-image: -webkit-linear-gradient(top, #4bbab1 0%, #40a79e 100%) !important;
    background-image: linear-gradient(to bottom, #4bbab1 0%, #40a79e 100%) !important;
    background-repeat: repeat-x; }
  .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary:active, .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary.active, .open .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
    background: none;
    background: #45b5ac !important;
    border-color: #379089;
    border-bottom-color: #379089 !important;
    color: #fff;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset; }
  .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat {
    background: #47b9af; }
    .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:hover, .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:active, .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.active, .open .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.dropdown-toggle {
      background: #47b9af !important;
      color: #fff; }
  .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:hover, .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:hover {
    color: #fff;
    border-color: #40a79e;
    border-bottom-color: #31827b; }
  .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:active, .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:active {
    border-color: #379089; }
  .btn-group .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary, .btn-group .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary:focus, .input-group-btn .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary, .input-group-btn .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary:focus {
    border-left-color: #40a79e;
    border-right-color: #40a79e; }
  .btn-group .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary:hover, .btn-group.open .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle, .input-group-btn .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary:hover, .input-group-btn.open .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
    border-color: #40a79e;
    border-bottom-color: #31827b; }
  .btn-group .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary:active, .btn-group .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary.active, .input-group-btn .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary:active, .input-group-btn .theme-asphalt .ui-datepicker-buttonpane button.ui-priority-primary.active {
    border-left-color: #379089;
    border-right-color: #379089; }
.theme-asphalt .select2-drop-active,
.theme-asphalt .select2-container-multi.select2-container-active .select2-choices,
.theme-asphalt .select2-container-multi.select2-dropdown-open .select2-choices {
  border: 1px solid #52bdb4; }
.theme-asphalt .select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid #52bdb4; }
.theme-asphalt .select2-container-active .select2-choice,
.theme-asphalt .select2-container-active .select2-choices {
  border: 1px solid #52bdb4; }
.theme-asphalt .select2-dropdown-open .select2-drop-above .select2-choice,
.theme-asphalt .select2-dropdown-open .select2-drop-above .select2-choices {
  border: 1px solid #52bdb4;
  border-top-color: #aaa; }
.theme-asphalt .select2-results .select2-highlighted {
  background: #52bdb4; }
.theme-asphalt .select2-primary .select2-container-multi .select2-choices .select2-search-choice {
  background: #52bdb4; }
.theme-asphalt .select2-primary .select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #3d9f97; }
.theme-asphalt .select2-primary .select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  background: #52bdb4 !important; }
.theme-asphalt .switcher-primary .switcher-state-on {
  background: #52bdb4 !important; }
.theme-asphalt .pixel-file-input:hover {
  border-color: #52bdb4; }
.theme-asphalt .datepicker table tr td.selected, .theme-asphalt .datepicker table tr td.selected:hover, .theme-asphalt .datepicker table tr td.selected.disabled, .theme-asphalt .datepicker table tr td.selected.disabled:hover {
  background: #52bdb4; }
.theme-asphalt .datepicker table tr td.active, .theme-asphalt .datepicker table tr td.active:hover, .theme-asphalt .datepicker table tr td.active.disabled, .theme-asphalt .datepicker table tr td.active.disabled:hover {
  background: #52bdb4; }
.theme-asphalt .datepicker table tr td span.active, .theme-asphalt .datepicker table tr td span.active:hover, .theme-asphalt .datepicker table tr td span.active.disabled, .theme-asphalt .datepicker table tr td span.active.disabled:hover {
  background: #52bdb4; }
.theme-asphalt .datepicker thead tr {
  background: #5c9994; }
  .theme-asphalt .datepicker thead tr:first-child {
    background: #52bdb4 !important;
    border-bottom-color: #597876 !important; }
.theme-asphalt .datepicker thead th.prev,
.theme-asphalt .datepicker thead th.next {
  background: #52bdb4; }
.theme-asphalt .datepicker thead th.prev {
  border-color: #597876; }
.theme-asphalt .datepicker thead th.next {
  border-color: #597876; }
.theme-asphalt .datepicker > .datepicker-days thead tr:first-child th.cw + .prev {
  border-color: #597876; }
.theme-asphalt .datepicker > .datepicker-days thead tr:last-child th.cw + th {
  border-color: #597876; }
.theme-asphalt .bootstrap-timepicker-widget table td a:hover {
  background: #52bdb4;
  border-color: #52bdb4; }
.theme-asphalt .pagination a.dp-today,
.theme-asphalt .pagination a.dp-today:hover {
  background: #77cbc4;
  border-color: #64c4bc; }
.theme-asphalt .pagination a.dp-today:hover {
  border-color: #52bdb4 !important; }
.theme-asphalt .pagination a.dp-selected {
  background: #52bdb4 !important;
  border-color: #52bdb4 !important; }
.theme-asphalt .tt-suggestion.tt-is-under-cursor {
  background: #52bdb4; }
.theme-asphalt .table-primary .dataTables_wrapper .DT-per-page {
  border-color: #4a8883 !important; }
.theme-asphalt .dropzone-box.dz-drag-hover {
  border-color: #52bdb4; }
.theme-asphalt .dropzone-box:hover .fa.fa-cloud-upload {
  background: #52bdb4; }
.theme-asphalt .md-editor.active {
  border-color: #52bdb4; }
.theme-asphalt .widget-rating .active a {
  color: #52bdb4; }

.theme-purple-hills {
  background: #f6f6f6; }
.theme-purple-hills .page-header {
  background: #fff;
  border-color: #e9e9e9; }
.theme-purple-hills .table,
.theme-purple-hills .panel,
.theme-purple-hills hr {
  border-color: #e2e2e2; }
.theme-purple-hills .bordered {
  border-color: #e2e2e2; }
.theme-purple-hills .bordered[class*='bg-'],
.theme-purple-hills [class*='bg-'] .border-t {
  border-color: #fff;
  border-color: rgba(0, 0, 0, 0.2); }
.theme-purple-hills .panel.panel-transparent > .panel-heading {
  border-bottom-color: #e2e2e2 !important; }
.theme-purple-hills .breadcrumb-page {
  background: #fff;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 6px;
  padding-top: 6px; }
.theme-purple-hills #main-navbar .navbar-header {
  background: #2e313e; }
  .theme-purple-hills #main-navbar .navbar-header a {
    color: #fff; }
.theme-purple-hills #main-navbar .navbar-brand {
  color: #fff; }
.theme-purple-hills #main-navbar .navbar-toggle,
.theme-purple-hills #main-navbar #main-menu-toggle {
  color: #fff; }
.theme-purple-hills #main-navbar #main-menu-toggle {
  background: #a48ad4; }
  .theme-purple-hills #main-navbar #main-menu-toggle .fa:before {
    color: #fff; }
.theme-purple-hills #main-navbar .navbar-collapse {
  border-top: 1px solid #3b3f50;
  background: #2e313e;
  -webkit-box-shadow: none;
  box-shadow: none; }
.theme-purple-hills #main-navbar .dropdown > a,
.theme-purple-hills #main-navbar .dropdown-menu > li > a,
.theme-purple-hills #main-navbar .nav > li > a {
  color: #abafc0; }
.theme-purple-hills #main-navbar li + li,
.theme-purple-hills #main-navbar .nav + .nav,
.theme-purple-hills #main-navbar .nav + .right {
  border-top: 1px solid #3b3f50; }
.theme-purple-hills #main-navbar .dropdown.open > a {
  background: #3b3f50;
  border-bottom: 1px solid #44485b;
  border-top: 1px solid #4f546a;
  margin-top: -1px;
  color: #fff; }
.theme-purple-hills #main-navbar form.navbar-form {
  -webkit-box-shadow: none;
  box-shadow: none; }
  .theme-purple-hills #main-navbar form.navbar-form .form-control {
    background: #fff;
    background: rgba(255, 255, 255, 0.05);
    border: none;
    color: #999; }
    .theme-purple-hills #main-navbar form.navbar-form .form-control:focus {
      background: #fff;
      color: #555;
      -webkit-box-shadow: none;
      box-shadow: none; }
@media (min-width: 768px) {
  .theme-purple-hills #main-navbar #main-menu-toggle {
    background: #9577cd; }
  .theme-purple-hills #main-navbar .navbar-header {
    background: #a48ad4; }
  .theme-purple-hills #main-navbar .navbar-collapse {
    background: #2e313e;
    border: none; }
    .theme-purple-hills #main-navbar .navbar-collapse > div > .navbar-nav {
      border-left: 1px solid #252832;
      -webkit-box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03) inset;
      box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03) inset; }
  .theme-purple-hills #main-navbar .right > .navbar-nav > li {
    border-left: 1px solid #252832;
    border-right: none;
    -webkit-box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03) inset;
    box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03) inset; }
  .theme-purple-hills #main-navbar .navbar-nav > li {
    border-right: 1px solid #252832;
    -webkit-box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03);
    box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03); }
    .theme-purple-hills #main-navbar .navbar-nav > li > a {
      color: #abafc0; }
    .theme-purple-hills #main-navbar .navbar-nav > li > a:hover, .theme-purple-hills #main-navbar .navbar-nav > li.active > a, .theme-purple-hills #main-navbar .navbar-nav > li.dropdown.open > a {
      border-bottom: 3px solid #a48ad4;
      color: #fff; }
  .theme-purple-hills #main-navbar li + li,
  .theme-purple-hills #main-navbar .nav + .nav,
  .theme-purple-hills #main-navbar .nav + .right {
    border-top: none; }
  .theme-purple-hills #main-navbar .dropdown.open > a {
    margin: 0;
    border-top: none; }
  .theme-purple-hills #main-navbar .dropdown-menu {
    background: #2e313e;
    border: none;
    border-top: 1px solid #a48ad4;
    padding: 5px 0; }
    .theme-purple-hills #main-navbar .dropdown-menu .divider {
      background-color: #252832; }
    .theme-purple-hills #main-navbar .dropdown-menu > li > a {
      color: #fff; }
      .theme-purple-hills #main-navbar .dropdown-menu > li > a:hover {
        background: #373a4a; }
  .theme-purple-hills #main-navbar .widget-notifications a:hover {
    color: #fff !important; }
  .theme-purple-hills #main-navbar .widget-notifications .notification,
  .theme-purple-hills #main-navbar .widget-notifications .notifications-link {
    border-color: #252832;
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }
  .theme-purple-hills #main-navbar .widget-notifications .notification-description {
    color: #888; }
  .theme-purple-hills #main-navbar .widget-notifications .notification-ago {
    color: #666; }
  .theme-purple-hills #main-navbar .widget-notifications .notifications-link:hover {
    background: #3b3f50; }
  .theme-purple-hills #main-navbar .widget-messages-alt a:hover {
    color: #fff !important; }
  .theme-purple-hills #main-navbar .widget-messages-alt .message,
  .theme-purple-hills #main-navbar .widget-messages-alt .messages-link {
    border-color: #252832;
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }
  .theme-purple-hills #main-navbar .widget-messages-alt .message-subject {
    color: #bbb; }
  .theme-purple-hills #main-navbar .widget-messages-alt .message-description {
    color: #666; }
    .theme-purple-hills #main-navbar .widget-messages-alt .message-description a {
      color: #888; }
  .theme-purple-hills #main-navbar .widget-messages-alt .messages-link:hover {
    background: #3b3f50; }
  .theme-purple-hills.main-menu-right #main-navbar .navbar-header, .theme-purple-hills.mmc #main-navbar .navbar-header, .theme-purple-hills.no-main-menu #main-navbar .navbar-header {
    background: #2e313e; }
  .theme-purple-hills.main-menu-right #main-navbar .navbar-header {
    border-right: 1px solid #252832; } }
@media (min-width: 768px) {
  .theme-purple-hills.right-to-left #main-navbar .right > .navbar-nav > li {
    border-right: 1px solid #252832;
    border-left: none;
    -webkit-box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03);
    box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03); }
  .theme-purple-hills.right-to-left.mmc #main-navbar .navbar-header {
    -webkit-box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03) inset;
    box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03) inset; } }
.theme-purple-hills #main-menu-bg {
  background-color: #3c3e49; }
.theme-purple-hills #main-menu .navigation a,
.theme-purple-hills #main-menu .mmc-dropdown-open-ul a {
  color: #a0a3b1;
  position: relative;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s; }
  .theme-purple-hills #main-menu .navigation a:after,
  .theme-purple-hills #main-menu .mmc-dropdown-open-ul a:after {
    content: "";
    top: 0;
    bottom: 0;
    width: 4px;
    position: absolute;
    right: auto;
    background: #9577cd;
    display: none;
    left: 0; }
  .theme-purple-hills #main-menu .navigation a:hover,
  .theme-purple-hills #main-menu .mmc-dropdown-open-ul a:hover {
    color: #fff; }
    .theme-purple-hills #main-menu .navigation a:hover:after,
    .theme-purple-hills #main-menu .mmc-dropdown-open-ul a:hover:after {
      display: block !important; }
.theme-purple-hills #main-menu li.active > a {
  background: #30323b;
  color: #fff; }
  .theme-purple-hills #main-menu li.active > a .menu-icon {
    color: #a48ad4; }
  .theme-purple-hills #main-menu li.active > a:after {
    display: block; }
.theme-purple-hills #main-menu .mm-dropdown.active > a {
  background: none; }
.theme-purple-hills #main-menu .mm-dropdown.active > a:after {
  display: none; }
.theme-purple-hills #main-menu .mm-dropdown > ul,
.theme-purple-hills #main-menu .mmc-dropdown-open-ul {
  background: #434551; }
.theme-purple-hills #main-menu .mmc-dropdown-open-ul .mmc-title {
  background: #9577cd;
  color: #fff; }
.theme-purple-hills #main-menu .mmc-dropdown-open > a {
  background: #9577cd !important; }
  .theme-purple-hills #main-menu .mmc-dropdown-open > a,
  .theme-purple-hills #main-menu .mmc-dropdown-open > a .menu-icon {
    color: #fff !important; }
  .theme-purple-hills #main-menu .mmc-dropdown-open > a:hover:after {
    display: none !important; }
.theme-purple-hills #main-menu .menu-content {
  border-color: #484a57; }
  .theme-purple-hills #main-menu .menu-content .btn-outline {
    border-color: #484a57;
    color: #a0a3b1; }
    .theme-purple-hills #main-menu .menu-content .btn-outline:hover {
      color: #fff; }
.theme-purple-hills .mmc-dropdown-open .mm-dropdown.open > a, .theme-purple-hills.mme #main-menu .mm-dropdown.open > a {
  color: #fff; }
@media (min-width: 768px) {
  .theme-purple-hills #main-menu .mm-dropdown.open > a {
    color: #fff; }
  .theme-purple-hills.mmc #main-menu .navigation > .mm-dropdown.open > a {
    color: #a0a3b1; } }
.theme-purple-hills.right-to-left #main-menu .navigation a:after {
  left: auto;
  right: 0; }
.theme-purple-hills #main-navbar .navbar-brand div,
.theme-purple-hills .demo-logo {
  background: #a48ad4; }
.theme-purple-hills.page-signin .signin-info, .theme-purple-hills.page-signup .signup-header {
  background: #857198;
  background: rgba(133, 113, 152, 0.8); }
.theme-purple-hills .timeline:before,
.theme-purple-hills .timeline:after,
.theme-purple-hills .tl-header,
.theme-purple-hills .tl-icon {
  background: #dddddd; }
.theme-purple-hills .tl-header,
.theme-purple-hills .tl-icon {
  box-shadow: 0 0 0 4px #f6f6f6; }
.theme-purple-hills .tl-entry:before {
  background: #857198;
  box-shadow: 0 0 0 3px #f6f6f6; }
.theme-purple-hills .tl-body:before {
  border-color: transparent #e2e2e2 transparent transparent; }
@media (min-width: 768px) {
  .theme-purple-hills .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent transparent transparent #e2e2e2; }
  .theme-purple-hills.right-to-left .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent #e2e2e2 transparent transparent; } }
.theme-purple-hills.right-to-left .tl-body:before {
  border-color: transparent transparent transparent #e2e2e2; }
.theme-purple-hills.page-mail .mail-nav,
.theme-purple-hills.page-mail .mail-nav:before {
  background: #f6f6f6; }
.theme-purple-hills.page-mail .mail-nav .sections li.active .label,
.theme-purple-hills.page-mail .mail-nav .sections li.active .badge {
  background: #857198; }
.theme-purple-hills.page-mail .mail-nav,
.theme-purple-hills.page-mail .mail-nav .compose-btn,
.theme-purple-hills.page-mail .mail-nav .sections li.active,
.theme-purple-hills.page-mail .mail-nav:before {
  border-color: #e2e2e2; }
.theme-purple-hills.page-mail .mail-nav .navigation.open .sections li.active {
  background: #ececec; }
.theme-purple-hills.page-mail .mail-nav li.divider {
  background: #e2e2e2; }
.theme-purple-hills.page-mail .mail-nav .sections li.active a {
  color: #444444; }
.theme-purple-hills.page-mail .mail-nav .sections li.active:after {
  color: #aaaaaa; }
.theme-purple-hills.page-mail .mail-nav .sections a {
  color: #6a6a6a; }
.theme-purple-hills.page-mail .mail-nav-header {
  color: #aaaaaa; }
.theme-purple-hills.page-mail .mail-nav .add-more a {
  color: #aaaaaa; }
@media (min-width: 992px) {
  .theme-purple-hills.page-mail .mail-nav .navigation .sections li.active {
    background: #ececec; }
  .theme-purple-hills.page-mail .mail-nav .sections a:hover {
    color: #444444; } }
.theme-purple-hills .nav-icon-btn.nav-icon-btn-primary .label {
  background: #857198; }
.theme-purple-hills .btn-primary {
  color: #fff; }
  .theme-purple-hills .btn-primary, .theme-purple-hills .btn-primary:focus {
    border-color: #78658b;
    border-bottom-color: #695879;
    background: #857198;
    background-image: -webkit-linear-gradient(top, #88749a 0%, #78658b 100%);
    background-image: linear-gradient(to bottom, #88749a 0%, #78658b 100%);
    background-repeat: repeat-x; }
  .theme-purple-hills .btn-primary:hover {
    color: #fff;
    border-color: #715e82;
    border-bottom-color: #574964;
    background: #78658b !important;
    background-image: -webkit-linear-gradient(top, #806b94 0%, #715e82 100%) !important;
    background-image: linear-gradient(to bottom, #806b94 0%, #715e82 100%) !important;
    background-repeat: repeat-x; }
  .theme-purple-hills .btn-primary:active, .theme-purple-hills .btn-primary.active, .open .theme-purple-hills .btn-primary.dropdown-toggle {
    background: none;
    background: #7b678e !important;
    border-color: #615170;
    border-bottom-color: #615170 !important;
    color: #fff;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset; }
  .theme-purple-hills .btn-primary.btn-flat {
    background: #7d6991; }
    .theme-purple-hills .btn-primary.btn-flat:hover, .theme-purple-hills .btn-primary.btn-flat:active, .theme-purple-hills .btn-primary.btn-flat.active, .open .theme-purple-hills .btn-primary.btn-flat.dropdown-toggle {
      background: #7d6991 !important;
      color: #fff; }
  .theme-purple-hills .btn-primary.btn-outline:hover, .theme-purple-hills .btn-primary.btn-outline.btn-flat:hover {
    color: #fff;
    border-color: #715e82;
    border-bottom-color: #574964; }
  .theme-purple-hills .btn-primary.btn-outline:active, .theme-purple-hills .btn-primary.btn-outline.btn-flat:active {
    border-color: #615170; }
  .btn-group .theme-purple-hills .btn-primary, .btn-group .theme-purple-hills .btn-primary:focus, .input-group-btn .theme-purple-hills .btn-primary, .input-group-btn .theme-purple-hills .btn-primary:focus {
    border-left-color: #715e82;
    border-right-color: #715e82; }
  .btn-group .theme-purple-hills .btn-primary:hover, .btn-group.open .theme-purple-hills .btn-primary.dropdown-toggle, .input-group-btn .theme-purple-hills .btn-primary:hover, .input-group-btn.open .theme-purple-hills .btn-primary.dropdown-toggle {
    border-color: #715e82;
    border-bottom-color: #574964; }
  .btn-group .theme-purple-hills .btn-primary:active, .btn-group .theme-purple-hills .btn-primary.active, .input-group-btn .theme-purple-hills .btn-primary:active, .input-group-btn .theme-purple-hills .btn-primary.active {
    border-left-color: #615170;
    border-right-color: #615170; }
.theme-purple-hills .open .btn.btn-primary.dropdown-toggle {
  color: #fff;
  border-bottom-color: #615170 !important;
  background: none;
  background: #7b678e !important; }
.theme-purple-hills .progress-bar {
  background: #857198;
  border-color: #766388; }
.theme-purple-hills .progress-striped .progress-bar {
  background-color: #857198;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px; }
.theme-purple-hills .label.label-primary {
  background: #857198; }
  .theme-purple-hills .label.label-primary[href]:hover, .theme-purple-hills .label.label-primary[href]:focus {
    background-color: #6b5a7c; }
  .theme-purple-hills .label.label-primary.label-tag {
    border: 1px solid #857198; }
    .theme-purple-hills .label.label-primary.label-tag:before {
      border-color: transparent #857198 transparent transparent; }
    .theme-purple-hills .label.label-primary.label-tag[href]:hover, .theme-purple-hills .label.label-primary.label-tag[href]:focus {
      border-color: #6b5a7c; }
    .theme-purple-hills .label.label-primary.label-tag[href]:hover:before, .theme-purple-hills .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent #6b5a7c transparent transparent; }
    .right-to-left .theme-purple-hills .label.label-primary.label-tag:before {
      border-color: transparent transparent transparent #857198; }
    .right-to-left .theme-purple-hills .label.label-primary.label-tag[href]:hover:before, .right-to-left .theme-purple-hills .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent transparent transparent #6b5a7c; }
    .ie8 .right-to-left .theme-purple-hills .label.label-primary.label-tag:before, .ie9 .right-to-left .theme-purple-hills .label.label-primary.label-tag:before {
      border-color: transparent #857198 transparent transparent; }
    .ie8 .right-to-left .theme-purple-hills .label.label-primary.label-tag[href]:hover:before, .ie8 .right-to-left .theme-purple-hills .label.label-primary.label-tag[href]:focus:before, .ie9 .right-to-left .theme-purple-hills .label.label-primary.label-tag[href]:hover:before, .ie9 .right-to-left .theme-purple-hills .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent #6b5a7c transparent transparent; }
.theme-purple-hills.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent transparent transparent #857198; }
.theme-purple-hills.right-to-left .label.label-primary.label-tag:hover:before, .theme-purple-hills.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent transparent transparent #6b5a7c; }
.ie8 .theme-purple-hills.right-to-left .label.label-primary.label-tag:before, .ie9 .theme-purple-hills.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent #857198 transparent transparent; }
.ie8 .theme-purple-hills.right-to-left .label.label-primary.label-tag:hover:before, .ie8 .theme-purple-hills.right-to-left .label.label-primary.label-tag:focus:before, .ie9 .theme-purple-hills.right-to-left .label.label-primary.label-tag:hover:before, .ie9 .theme-purple-hills.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent #6b5a7c transparent transparent; }
.theme-purple-hills .badge.badge-primary {
  background: #857198;
  border: 1px solid #857198; }
  .theme-purple-hills .badge.badge-primary[href]:hover, .theme-purple-hills .badge.badge-primary[href]:focus {
    background-color: #6b5a7c;
    border-color: #6b5a7c; }
.theme-purple-hills .table-primary table {
  border-top-color: #666567 !important; }
.theme-purple-hills .table-primary thead,
.theme-purple-hills .table-primary thead tr,
.theme-purple-hills .table-primary thead th,
.theme-purple-hills .table-primary .table-header {
  border-color: #666567 !important;
  color: #fff; }
.theme-purple-hills .table-primary thead tr,
.theme-purple-hills .table-primary thead th {
  background: #756c7e; }
.theme-purple-hills .table-primary .table-header {
  background: #857198; }
.theme-purple-hills .nav-tabs > li.active > a, .theme-purple-hills .nav-tabs > li.active > a:hover, .theme-purple-hills .nav-tabs > li.active > a:focus {
  background: #857198;
  border-bottom: 2px solid #78658b; }
.theme-purple-hills .nav-tabs .nav-tabs.nav-justified > .active > a {
  border-bottom-color: #78658b; }
.theme-purple-hills .nav-pills > li.active > a, .theme-purple-hills .nav-pills > li.active > a:hover, .theme-purple-hills .nav-pills > li.active > a:focus {
  background: #857198; }
.theme-purple-hills .dropdown-menu > li > a:hover,
.theme-purple-hills .dropdown-menu > li.active > a {
  background: #857198; }
.theme-purple-hills .dropdown-menu > li > a:hover {
  color: #fff; }
.theme-purple-hills .btn-group .btn-primary,
.theme-purple-hills .btn-group .btn-primary:focus,
.theme-purple-hills .input-group-btn .btn-primary,
.theme-purple-hills .input-group-btn .btn-primary:focus {
  border-left-color: #715e82;
  border-right-color: #715e82; }
.theme-purple-hills .btn-group .btn-primary:hover,
.theme-purple-hills .btn-group.open .btn-primary.dropdown-toggle,
.theme-purple-hills .input-group-btn .btn-primary:hover,
.theme-purple-hills .input-group-btn.open .btn-primary.dropdown-toggle {
  border-color: #615170; }
.theme-purple-hills .btn-group .btn-primary:active,
.theme-purple-hills .btn-group .btn-primary.active,
.theme-purple-hills .input-group-btn .btn-primary:active,
.theme-purple-hills .input-group-btn .btn-primary.active {
  border-left-color: #615170;
  border-right-color: #615170; }
.theme-purple-hills .pagination > li > a:hover {
  border-color: #857198; }
.theme-purple-hills .pagination > li.active > a,
.theme-purple-hills .pagination > li.active > a:hover,
.theme-purple-hills .pagination > li.active > a:focus,
.theme-purple-hills .pagination > li.active > span {
  background: #857198;
  border-color: #857198; }
.theme-purple-hills .pager li > a:hover {
  border-color: #857198; }
.theme-purple-hills a.list-group-item.active {
  background: #857198;
  border-color: #857198; }
  .theme-purple-hills a.list-group-item.active .list-group-item-text {
    color: #ebe7ee; }
.theme-purple-hills .form-control:focus,
.theme-purple-hills .form-control.focus {
  border-color: #857198;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }
.gt-ie8 .theme-purple-hills input.px:focus + .lbl:before, .gt-ie8 .theme-purple-hills input.px + .lbl:hover:before {
  border-color: #857198; }
.gt-ie8 .theme-purple-hills input[type="checkbox"].px + .lbl:after {
  color: #857198; }
.gt-ie8 .theme-purple-hills input[type="radio"].px + .lbl:after {
  background: #857198; }
.theme-purple-hills .text-primary, .theme-purple-hills .text-primary:hover, .theme-purple-hills .text-primary:active, .theme-purple-hills .text-primary:focus,
.theme-purple-hills .text-primary a,
.theme-purple-hills .text-primary a:focus {
  color: #857198; }
.theme-purple-hills .text-primary a:hover,
.theme-purple-hills .text-primary a:active {
  color: #5f4f6d; }
.theme-purple-hills .bg-primary {
  background: #857198 !important; }
  .theme-purple-hills .bg-primary[href]:hover {
    background: #7d7089 !important; }
  .theme-purple-hills .bg-primary.darken {
    background: #7d7089 !important; }
    .theme-purple-hills .bg-primary.darken[href]:hover {
      background: #756a81 !important; }
  .theme-purple-hills .bg-primary.darker {
    background: #756a81 !important; }
    .theme-purple-hills .bg-primary.darker[href]:hover {
      background: #6e6378 !important; }
  .theme-purple-hills .bg-primary, .theme-purple-hills .bg-primary:hover, .theme-purple-hills .bg-primary:active, .theme-purple-hills .bg-primary:focus,
  .theme-purple-hills .bg-primary a,
  .theme-purple-hills .bg-primary a:focus {
    color: #fff; }
  .theme-purple-hills .bg-primary a:hover,
  .theme-purple-hills .bg-primary a:active {
    color: #d9d9d9; }
  .theme-purple-hills .bg-primary[href]:hover,
  .theme-purple-hills .bg-primary [href]:hover {
    color: #fff; }
  .theme-purple-hills .bg-primary * {
    border-color: #9786a7; }
.theme-purple-hills .ui-slider-handle.ui-state-active:before {
  background: #857198; }
.theme-purple-hills .ui-slider-range {
  background: #857198; }
.theme-purple-hills .ui-autocomplete > li > a:hover,
.theme-purple-hills .ui-autocompletev > li.active > a {
  background: #857198; }
.theme-purple-hills .ui-autocomplete > li > a:hover {
  color: #fff; }
.theme-purple-hills .ui-autocomplete .ui-state-focus {
  background: #857198;
  color: #fff; }
.theme-purple-hills .ui-menu .ui-menu-item a:hover,
.theme-purple-hills .ui-menu .ui-menu-item a.ui-state-focus,
.theme-purple-hills .ui-menu .ui-menu-item a.ui-state-active {
  background: #857198; }
.theme-purple-hills .ui-progressbar-value {
  border-color: #766388;
  background-color: #857198;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px; }
.theme-purple-hills .ui-tabs-nav > .ui-tabs-active > a, .theme-purple-hills .ui-tabs-nav > .ui-tabs-active > a:hover, .theme-purple-hills .ui-tabs-nav > .ui-tabs-active > a:focus {
  color: #fff;
  background: #857198;
  border-bottom: 2px solid #78658b; }
.theme-purple-hills .ui-datepicker .ui-state-active {
  background: #857198 !important; }
.theme-purple-hills .ui-datepicker-title {
  background: #857198; }
.theme-purple-hills .ui-datepicker th {
  background: #787878; }
.theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary {
  color: #fff; }
  .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary, .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary:focus {
    border-color: #78658b;
    border-bottom-color: #695879;
    background: #857198;
    background-image: -webkit-linear-gradient(top, #88749a 0%, #78658b 100%);
    background-image: linear-gradient(to bottom, #88749a 0%, #78658b 100%);
    background-repeat: repeat-x; }
  .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary:hover {
    color: #fff;
    border-color: #715e82;
    border-bottom-color: #574964;
    background: #78658b !important;
    background-image: -webkit-linear-gradient(top, #806b94 0%, #715e82 100%) !important;
    background-image: linear-gradient(to bottom, #806b94 0%, #715e82 100%) !important;
    background-repeat: repeat-x; }
  .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary:active, .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary.active, .open .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
    background: none;
    background: #7b678e !important;
    border-color: #615170;
    border-bottom-color: #615170 !important;
    color: #fff;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset; }
  .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat {
    background: #7d6991; }
    .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:hover, .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:active, .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.active, .open .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.dropdown-toggle {
      background: #7d6991 !important;
      color: #fff; }
  .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:hover, .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:hover {
    color: #fff;
    border-color: #715e82;
    border-bottom-color: #574964; }
  .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:active, .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:active {
    border-color: #615170; }
  .btn-group .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary, .btn-group .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary:focus, .input-group-btn .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary, .input-group-btn .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary:focus {
    border-left-color: #715e82;
    border-right-color: #715e82; }
  .btn-group .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary:hover, .btn-group.open .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle, .input-group-btn .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary:hover, .input-group-btn.open .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
    border-color: #715e82;
    border-bottom-color: #574964; }
  .btn-group .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary:active, .btn-group .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary.active, .input-group-btn .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary:active, .input-group-btn .theme-purple-hills .ui-datepicker-buttonpane button.ui-priority-primary.active {
    border-left-color: #615170;
    border-right-color: #615170; }
.theme-purple-hills .select2-drop-active,
.theme-purple-hills .select2-container-multi.select2-container-active .select2-choices,
.theme-purple-hills .select2-container-multi.select2-dropdown-open .select2-choices {
  border: 1px solid #857198; }
.theme-purple-hills .select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid #857198; }
.theme-purple-hills .select2-container-active .select2-choice,
.theme-purple-hills .select2-container-active .select2-choices {
  border: 1px solid #857198; }
.theme-purple-hills .select2-dropdown-open .select2-drop-above .select2-choice,
.theme-purple-hills .select2-dropdown-open .select2-drop-above .select2-choices {
  border: 1px solid #857198;
  border-top-color: #aaa; }
.theme-purple-hills .select2-results .select2-highlighted {
  background: #857198; }
.theme-purple-hills .select2-primary .select2-container-multi .select2-choices .select2-search-choice {
  background: #857198; }
.theme-purple-hills .select2-primary .select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #6b5a7c; }
.theme-purple-hills .select2-primary .select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  background: #857198 !important; }
.theme-purple-hills .switcher-primary .switcher-state-on {
  background: #857198 !important; }
.theme-purple-hills .pixel-file-input:hover {
  border-color: #857198; }
.theme-purple-hills .datepicker table tr td.selected, .theme-purple-hills .datepicker table tr td.selected:hover, .theme-purple-hills .datepicker table tr td.selected.disabled, .theme-purple-hills .datepicker table tr td.selected.disabled:hover {
  background: #857198; }
.theme-purple-hills .datepicker table tr td.active, .theme-purple-hills .datepicker table tr td.active:hover, .theme-purple-hills .datepicker table tr td.active.disabled, .theme-purple-hills .datepicker table tr td.active.disabled:hover {
  background: #857198; }
.theme-purple-hills .datepicker table tr td span.active, .theme-purple-hills .datepicker table tr td span.active:hover, .theme-purple-hills .datepicker table tr td span.active.disabled, .theme-purple-hills .datepicker table tr td span.active.disabled:hover {
  background: #857198; }
.theme-purple-hills .datepicker thead tr {
  background: #787878; }
  .theme-purple-hills .datepicker thead tr:first-child {
    background: #857198 !important;
    border-bottom-color: #666666 !important; }
.theme-purple-hills .datepicker thead th.prev,
.theme-purple-hills .datepicker thead th.next {
  background: #857198; }
.theme-purple-hills .datepicker thead th.prev {
  border-color: #666666; }
.theme-purple-hills .datepicker thead th.next {
  border-color: #666666; }
.theme-purple-hills .datepicker > .datepicker-days thead tr:first-child th.cw + .prev {
  border-color: #666666; }
.theme-purple-hills .datepicker > .datepicker-days thead tr:last-child th.cw + th {
  border-color: #666666; }
.theme-purple-hills .bootstrap-timepicker-widget table td a:hover {
  background: #857198;
  border-color: #857198; }
.theme-purple-hills .pagination a.dp-today,
.theme-purple-hills .pagination a.dp-today:hover {
  background: #9e8fad;
  border-color: #9280a3; }
.theme-purple-hills .pagination a.dp-today:hover {
  border-color: #857198 !important; }
.theme-purple-hills .pagination a.dp-selected {
  background: #857198 !important;
  border-color: #857198 !important; }
.theme-purple-hills .tt-suggestion.tt-is-under-cursor {
  background: #857198; }
.theme-purple-hills .table-primary .dataTables_wrapper .DT-per-page {
  border-color: #666567 !important; }
.theme-purple-hills .dropzone-box.dz-drag-hover {
  border-color: #857198; }
.theme-purple-hills .dropzone-box:hover .fa.fa-cloud-upload {
  background: #857198; }
.theme-purple-hills .md-editor.active {
  border-color: #857198; }
.theme-purple-hills .widget-rating .active a {
  color: #857198; }

.theme-adminflare {
  background: #f4f4f4 url("../images/themes/adminflare/body-bg.png"); }
.theme-adminflare .page-header {
  background: #ececec;
  background: rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5); }
.theme-adminflare .table,
.theme-adminflare .panel,
.theme-adminflare .page-header,
.theme-adminflare hr {
  border-color: #d6d6d6; }
.theme-adminflare .bordered {
  border-color: #d6d6d6; }
.theme-adminflare .bordered[class*='bg-'],
.theme-adminflare [class*='bg-'] .border-t {
  border-color: #fff;
  border-color: rgba(0, 0, 0, 0.2); }
.theme-adminflare .panel.panel-transparent > .panel-heading {
  border-bottom-color: #d6d6d6 !important; }
.theme-adminflare .breadcrumb-page {
  background: #ececec;
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 6px;
  padding-top: 6px;
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5); }
.gt-ie9 .theme-adminflare #main-navbar, .gt-ie9 .theme-adminflare #main-navbar .navbar-header {
  background-image: -webkit-linear-gradient(top, #52aded 0%, #418ce4 100%);
  background-image: -o-linear-gradient(top, #52aded 0%, #418ce4 100%);
  background-image: linear-gradient(to bottom, #52aded 0%, #418ce4 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF52ADED', endColorstr='#FF418CE4', GradientType=0); }
.ie8 .theme-adminflare #main-navbar, .ie9 .theme-adminflare #main-navbar, .ie8 .theme-adminflare #main-navbar .navbar-header, .ie9 .theme-adminflare #main-navbar .navbar-header {
  background-image: url("../images/themes/adminflare/ie-navbar-bg.png"); }
.theme-adminflare #main-navbar {
  border-bottom: 1px solid #185aa7; }
  .theme-adminflare #main-navbar,
  .theme-adminflare #main-navbar .navbar-header {
    background: #418ce4;
    -webkit-box-shadow: rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
    box-shadow: rgba(255, 255, 255, 0.25) 0px 1px 0px inset; }
  .theme-adminflare #main-navbar .navbar-brand {
    color: #fff; }
  .theme-adminflare #main-navbar .dropdown > a,
  .theme-adminflare #main-navbar .dropdown-menu > li > a,
  .theme-adminflare #main-navbar .nav > li > a {
    text-shadow: rgba(0, 0, 0, 0.2) 0px -1px 0;
    color: #fff; }
  .theme-adminflare #main-navbar .navbar-toggle,
  .theme-adminflare #main-navbar #main-menu-toggle {
    color: #fff; }
  .theme-adminflare #main-navbar #main-menu-toggle {
    border: 0px solid;
    border-color: #3470b6;
    border-color: rgba(0, 0, 0, 0.2); }
    .theme-adminflare #main-navbar #main-menu-toggle .fa:before {
      color: #fff; }
  .theme-adminflare #main-navbar .main-navbar-collapse {
    border-top: 1px solid #185aa7;
    background: #418ce4;
    -webkit-box-shadow: rgba(255, 255, 255, 0.18) 0px 1px 0px inset;
    box-shadow: rgba(255, 255, 255, 0.18) 0px 1px 0px inset; }
  .theme-adminflare #main-navbar li {
    border-bottom: 1px solid #5697e4; }
  .theme-adminflare #main-navbar .dropdown.open > a {
    background: #377bca;
    border-bottom: 1px solid #2e6db7;
    border-top: 1px solid #306cb3;
    margin-top: -1px; }
  .theme-adminflare #main-navbar form.navbar-form {
    -webkit-box-shadow: none;
    box-shadow: none; }
    .theme-adminflare #main-navbar form.navbar-form .form-control {
      background: #fff;
      background: rgba(255, 255, 255, 0.2);
      border: none;
      color: #fff;
      border-radius: 999999px;
      -webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2); }
      .theme-adminflare #main-navbar form.navbar-form .form-control:focus {
        background: #fff;
        color: #555; }
      .theme-adminflare #main-navbar form.navbar-form .form-control::-moz-placeholder {
        color: #2565af;
        opacity: 1; }
      .theme-adminflare #main-navbar form.navbar-form .form-control:-ms-input-placeholder {
        color: #2565af; }
      .theme-adminflare #main-navbar form.navbar-form .form-control::-webkit-input-placeholder {
        color: #2565af; }
@media (min-width: 768px) {
  .theme-adminflare #main-navbar .main-navbar-collapse {
    background: none;
    border-top: none;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .theme-adminflare #main-navbar li {
    border-bottom: none; }
  .theme-adminflare #main-navbar .dropdown > a,
  .theme-adminflare #main-navbar .nav > li > a:hover {
    color: rgba(255, 255, 255, 0.7); }
  .theme-adminflare #main-navbar .dropdown.open > a,
  .theme-adminflare #main-navbar .dropdown.open > a:hover {
    background: rgba(0, 0, 0, 0.15);
    border: none;
    margin: 0;
    color: #fff; }
  .theme-adminflare #main-navbar .dropdown-menu {
    border-radius: 2px;
    margin-top: 3px;
    border-width: 2px;
    padding: 5px 0; }
    .theme-adminflare #main-navbar .dropdown-menu li > a {
      color: #555;
      text-shadow: none;
      margin: 0 -2px; }
      .theme-adminflare #main-navbar .dropdown-menu li > a:hover {
        background: #3690e6;
        color: #fff; }
  .theme-adminflare #main-navbar .nav-icon-btn > a > .label,
  .theme-adminflare #main-navbar .nav-icon-btn > a > .badge {
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); } }
.theme-adminflare #main-navbar #main-menu-toggle, .theme-adminflare.right-to-left.main-menu-right #main-navbar #main-menu-toggle {
  border-right-width: 1px;
  -webkit-box-shadow: 1px 0 0 rgba(255, 255, 255, 0.2);
  box-shadow: 1px 0 0 rgba(255, 255, 255, 0.2); }
.theme-adminflare.right-to-left #main-navbar #main-menu-toggle, .theme-adminflare.main-menu-right #main-navbar #main-menu-toggle {
  border-left-width: 1px;
  border-right-width: 0;
  -webkit-box-shadow: 1px 0 0 rgba(255, 255, 255, 0.2) inset;
  box-shadow: 1px 0 0 rgba(255, 255, 255, 0.2) inset; }
.theme-adminflare #main-menu-bg,
.theme-adminflare .mmc-dropdown-open-ul {
  background: #2b2b2b url("../images/themes/adminflare/menu-bg.png"); }
.theme-adminflare #main-menu-bg, .theme-adminflare.right-to-left.main-menu-right #main-menu-bg {
  border-left: none;
  border-right: 1px solid #1d1d1d; }
.theme-adminflare.main-menu-right #main-menu-bg, .theme-adminflare.right-to-left #main-menu-bg {
  border-left: 1px solid #1d1d1d;
  border-right: none; }
.theme-adminflare #main-menu .navigation {
  -webkit-box-shadow: 0 1px 0 #313131;
  box-shadow: 0 1px 0 #313131; }
.theme-adminflare #main-menu .navigation a,
.theme-adminflare #main-menu .mmc-dropdown-open-ul a {
  color: #aaaaaa;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.6);
  -webkit-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s; }
  .theme-adminflare #main-menu .navigation a:hover,
  .theme-adminflare #main-menu .mmc-dropdown-open-ul a:hover {
    color: #fff !important; }
.theme-adminflare #main-menu li {
  border-top: 1px solid #313131;
  border-bottom: 1px solid #1f1f1f; }
  .theme-adminflare #main-menu li.active {
    border-top: none;
    background: #242424;
    background: rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 1px 6px inset rgba(0, 0, 0, 0.25) !important;
    box-shadow: 0px 1px 6px inset rgba(0, 0, 0, 0.25) !important; }
    .theme-adminflare #main-menu li.active > a, .theme-adminflare #main-menu li.active > a:hover {
      color: #75c6f3 !important; }
.theme-adminflare #main-menu .mm-dropdown.open {
  border-bottom: none; }
  .theme-adminflare #main-menu .mm-dropdown.open > a {
    border-bottom: 1px solid #1f1f1f; }
.theme-adminflare #main-menu .navigation .mm-dropdown.active,
.theme-adminflare #main-menu .mmc-dropdown-open {
  border-top: 1px solid #313131;
  background: none; }
  .theme-adminflare #main-menu .navigation .mm-dropdown.active > a,
  .theme-adminflare #main-menu .navigation .mm-dropdown.active > a:hover,
  .theme-adminflare #main-menu .mmc-dropdown-open > a,
  .theme-adminflare #main-menu .mmc-dropdown-open > a:hover {
    color: #fff !important; }
.theme-adminflare #main-menu .mmc-dropdown-open-ul {
  margin-top: -1px !important; }
  .theme-adminflare #main-menu .mmc-dropdown-open-ul > a {
    color: #fff !important; }
.theme-adminflare #main-menu .mmc-title {
  color: #fff;
  border-bottom: 1px solid #1f1f1f;
  padding-top: 1px;
  padding-bottom: 1px; }
.theme-adminflare #main-menu .mmc-dropdown-open-ul.top {
  margin-top: 0 !important;
  margin-bottom: 1px !important; }
  .theme-adminflare #main-menu .mmc-dropdown-open-ul.top .mmc-title {
    padding-top: 0; }
.theme-adminflare #main-menu .menu-content {
  border: none;
  margin-top: -8px; }
  .theme-adminflare #main-menu .menu-content .btn-outline {
    border-color: #383838;
    color: #aaa; }
    .theme-adminflare #main-menu .menu-content .btn-outline:hover {
      color: #fff; }
  .theme-adminflare #main-menu .menu-content.top {
    border-bottom: 1px solid #1f1f1f;
    margin-top: 0; }
.theme-adminflare.right-to-left #main-menu-bg {
  border-right: none;
  border-left: 1px solid #1d1d1d; }
.theme-adminflare .mmc-dropdown-open .mm-dropdown.open > a, .theme-adminflare.mme #main-menu .mm-dropdown.open > a {
  color: #fff; }
@media (min-width: 768px) {
  .theme-adminflare #main-menu .mm-dropdown.open > a {
    color: #fff; }
  .theme-adminflare.mmc #main-menu .navigation > .mm-dropdown.open > a {
    color: #aaaaaa; } }
.theme-adminflare .demo-logo {
  background: #3690e6; }
.theme-adminflare.page-signin .signin-info, .theme-adminflare.page-signup .signup-header {
  background: #3690e6;
  background: rgba(54, 144, 230, 0.8); }
.theme-adminflare .timeline:before,
.theme-adminflare .timeline:after,
.theme-adminflare .tl-header,
.theme-adminflare .tl-icon {
  background: #dbdbdb; }
.theme-adminflare .tl-header,
.theme-adminflare .tl-icon {
  box-shadow: 0 0 0 4px #f4f4f4; }
.theme-adminflare .tl-entry:before {
  background: #3690e6;
  box-shadow: 0 0 0 3px #f4f4f4; }
.theme-adminflare .tl-body:before {
  border-color: transparent #d6d6d6 transparent transparent; }
@media (min-width: 768px) {
  .theme-adminflare .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent transparent transparent #d6d6d6; }
  .theme-adminflare.right-to-left .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent #d6d6d6 transparent transparent; } }
.theme-adminflare.right-to-left .tl-body:before {
  border-color: transparent transparent transparent #d6d6d6; }
.theme-adminflare.page-mail .mail-nav,
.theme-adminflare.page-mail .mail-nav:before {
  background: #f4f4f4 url("../images/themes/adminflare/body-bg.png"); }
.theme-adminflare.page-mail .mail-nav .sections li.active .label,
.theme-adminflare.page-mail .mail-nav .sections li.active .badge {
  background: #3690e6; }
.theme-adminflare.page-mail .mail-nav,
.theme-adminflare.page-mail .mail-nav .compose-btn,
.theme-adminflare.page-mail .mail-nav .sections li.active,
.theme-adminflare.page-mail .mail-nav:before {
  border-color: #d6d6d6; }
.theme-adminflare.page-mail .mail-nav .navigation.open .sections li.active {
  background: #eaeaea;
  background: rgba(0, 0, 0, 0.04); }
.theme-adminflare.page-mail .mail-nav li.divider {
  background: #d6d6d6; }
.theme-adminflare.page-mail .mail-nav .sections li.active a {
  color: #424242; }
.theme-adminflare.page-mail .mail-nav .sections li.active:after {
  color: #a8a8a8; }
.theme-adminflare.page-mail .mail-nav .sections a {
  color: #686868; }
.theme-adminflare.page-mail .mail-nav-header {
  color: #a8a8a8; }
.theme-adminflare.page-mail .mail-nav .add-more a {
  color: #a8a8a8; }
@media (min-width: 992px) {
  .theme-adminflare.page-mail .mail-nav .navigation .sections li.active {
    background: #eaeaea;
    background: rgba(0, 0, 0, 0.04); }
  .theme-adminflare.page-mail .mail-nav .sections a:hover {
    color: #424242; } }
.theme-adminflare .nav-icon-btn.nav-icon-btn-primary .label {
  background: #3690e6; }
.theme-adminflare .btn-primary {
  color: #fff; }
  .theme-adminflare .btn-primary, .theme-adminflare .btn-primary:focus {
    border-color: #1f83e3;
    border-bottom-color: #1974cb;
    background: #3690e6;
    background-image: -webkit-linear-gradient(top, #3b93e7 0%, #1f83e3 100%);
    background-image: linear-gradient(to bottom, #3b93e7 0%, #1f83e3 100%);
    background-repeat: repeat-x; }
  .theme-adminflare .btn-primary:hover {
    color: #fff;
    border-color: #1b7cd8;
    border-bottom-color: #1562ab;
    background: #1f83e3 !important;
    background-image: -webkit-linear-gradient(top, #2d8be5 0%, #1b7cd8 100%) !important;
    background-image: linear-gradient(to bottom, #2d8be5 0%, #1b7cd8 100%) !important;
    background-repeat: repeat-x; }
  .theme-adminflare .btn-primary:active, .theme-adminflare .btn-primary.active, .open .theme-adminflare .btn-primary.dropdown-toggle {
    background: none;
    background: #2486e4 !important;
    border-color: #186cbd;
    border-bottom-color: #186cbd !important;
    color: #fff;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset; }
  .theme-adminflare .btn-primary.btn-flat {
    background: #2888e4; }
    .theme-adminflare .btn-primary.btn-flat:hover, .theme-adminflare .btn-primary.btn-flat:active, .theme-adminflare .btn-primary.btn-flat.active, .open .theme-adminflare .btn-primary.btn-flat.dropdown-toggle {
      background: #2888e4 !important;
      color: #fff; }
  .theme-adminflare .btn-primary.btn-outline:hover, .theme-adminflare .btn-primary.btn-outline.btn-flat:hover {
    color: #fff;
    border-color: #1b7cd8;
    border-bottom-color: #1562ab; }
  .theme-adminflare .btn-primary.btn-outline:active, .theme-adminflare .btn-primary.btn-outline.btn-flat:active {
    border-color: #186cbd; }
  .btn-group .theme-adminflare .btn-primary, .btn-group .theme-adminflare .btn-primary:focus, .input-group-btn .theme-adminflare .btn-primary, .input-group-btn .theme-adminflare .btn-primary:focus {
    border-left-color: #1b7cd8;
    border-right-color: #1b7cd8; }
  .btn-group .theme-adminflare .btn-primary:hover, .btn-group.open .theme-adminflare .btn-primary.dropdown-toggle, .input-group-btn .theme-adminflare .btn-primary:hover, .input-group-btn.open .theme-adminflare .btn-primary.dropdown-toggle {
    border-color: #1b7cd8;
    border-bottom-color: #1562ab; }
  .btn-group .theme-adminflare .btn-primary:active, .btn-group .theme-adminflare .btn-primary.active, .input-group-btn .theme-adminflare .btn-primary:active, .input-group-btn .theme-adminflare .btn-primary.active {
    border-left-color: #186cbd;
    border-right-color: #186cbd; }
.theme-adminflare .open .btn.btn-primary.dropdown-toggle {
  color: #fff;
  border-bottom-color: #186cbd !important;
  background: none;
  background: #2486e4 !important; }
.theme-adminflare .progress-bar {
  background: #3690e6;
  border-color: #1c81e1; }
.theme-adminflare .progress-striped .progress-bar {
  background-color: #3690e6;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px; }
.theme-adminflare .label.label-primary {
  background: #3690e6; }
  .theme-adminflare .label.label-primary[href]:hover, .theme-adminflare .label.label-primary[href]:focus {
    background-color: #1a77cf; }
  .theme-adminflare .label.label-primary.label-tag {
    border: 1px solid #3690e6; }
    .theme-adminflare .label.label-primary.label-tag:before {
      border-color: transparent #3690e6 transparent transparent; }
    .theme-adminflare .label.label-primary.label-tag[href]:hover, .theme-adminflare .label.label-primary.label-tag[href]:focus {
      border-color: #1a77cf; }
    .theme-adminflare .label.label-primary.label-tag[href]:hover:before, .theme-adminflare .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent #1a77cf transparent transparent; }
    .right-to-left .theme-adminflare .label.label-primary.label-tag:before {
      border-color: transparent transparent transparent #3690e6; }
    .right-to-left .theme-adminflare .label.label-primary.label-tag[href]:hover:before, .right-to-left .theme-adminflare .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent transparent transparent #1a77cf; }
    .ie8 .right-to-left .theme-adminflare .label.label-primary.label-tag:before, .ie9 .right-to-left .theme-adminflare .label.label-primary.label-tag:before {
      border-color: transparent #3690e6 transparent transparent; }
    .ie8 .right-to-left .theme-adminflare .label.label-primary.label-tag[href]:hover:before, .ie8 .right-to-left .theme-adminflare .label.label-primary.label-tag[href]:focus:before, .ie9 .right-to-left .theme-adminflare .label.label-primary.label-tag[href]:hover:before, .ie9 .right-to-left .theme-adminflare .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent #1a77cf transparent transparent; }
.theme-adminflare.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent transparent transparent #3690e6; }
.theme-adminflare.right-to-left .label.label-primary.label-tag:hover:before, .theme-adminflare.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent transparent transparent #1a77cf; }
.ie8 .theme-adminflare.right-to-left .label.label-primary.label-tag:before, .ie9 .theme-adminflare.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent #3690e6 transparent transparent; }
.ie8 .theme-adminflare.right-to-left .label.label-primary.label-tag:hover:before, .ie8 .theme-adminflare.right-to-left .label.label-primary.label-tag:focus:before, .ie9 .theme-adminflare.right-to-left .label.label-primary.label-tag:hover:before, .ie9 .theme-adminflare.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent #1a77cf transparent transparent; }
.theme-adminflare .badge.badge-primary {
  background: #3690e6;
  border: 1px solid #3690e6; }
  .theme-adminflare .badge.badge-primary[href]:hover, .theme-adminflare .badge.badge-primary[href]:focus {
    background-color: #1a77cf;
    border-color: #1a77cf; }
.theme-adminflare .table-primary table {
  border-top-color: #2971b5 !important; }
.theme-adminflare .table-primary thead,
.theme-adminflare .table-primary thead tr,
.theme-adminflare .table-primary thead th,
.theme-adminflare .table-primary .table-header {
  border-color: #2971b5 !important;
  color: #fff; }
.theme-adminflare .table-primary thead tr,
.theme-adminflare .table-primary thead th {
  background: #2681d7; }
.theme-adminflare .table-primary .table-header {
  background: #3690e6; }
.theme-adminflare .nav-tabs > li.active > a, .theme-adminflare .nav-tabs > li.active > a:hover, .theme-adminflare .nav-tabs > li.active > a:focus {
  background: #3690e6;
  border-bottom: 2px solid #1f83e3; }
.theme-adminflare .nav-tabs .nav-tabs.nav-justified > .active > a {
  border-bottom-color: #1f83e3; }
.theme-adminflare .nav-pills > li.active > a, .theme-adminflare .nav-pills > li.active > a:hover, .theme-adminflare .nav-pills > li.active > a:focus {
  background: #3690e6; }
.theme-adminflare .dropdown-menu > li > a:hover,
.theme-adminflare .dropdown-menu > li.active > a {
  background: #3690e6; }
.theme-adminflare .dropdown-menu > li > a:hover {
  color: #fff; }
.theme-adminflare .btn-group .btn-primary,
.theme-adminflare .btn-group .btn-primary:focus,
.theme-adminflare .input-group-btn .btn-primary,
.theme-adminflare .input-group-btn .btn-primary:focus {
  border-left-color: #1b7cd8;
  border-right-color: #1b7cd8; }
.theme-adminflare .btn-group .btn-primary:hover,
.theme-adminflare .btn-group.open .btn-primary.dropdown-toggle,
.theme-adminflare .input-group-btn .btn-primary:hover,
.theme-adminflare .input-group-btn.open .btn-primary.dropdown-toggle {
  border-color: #186cbd; }
.theme-adminflare .btn-group .btn-primary:active,
.theme-adminflare .btn-group .btn-primary.active,
.theme-adminflare .input-group-btn .btn-primary:active,
.theme-adminflare .input-group-btn .btn-primary.active {
  border-left-color: #186cbd;
  border-right-color: #186cbd; }
.theme-adminflare .pagination > li > a:hover {
  border-color: #3690e6; }
.theme-adminflare .pagination > li.active > a,
.theme-adminflare .pagination > li.active > a:hover,
.theme-adminflare .pagination > li.active > a:focus,
.theme-adminflare .pagination > li.active > span {
  background: #3690e6;
  border-color: #3690e6; }
.theme-adminflare .pager li > a:hover {
  border-color: #3690e6; }
.theme-adminflare a.list-group-item.active {
  background: #3690e6;
  border-color: #3690e6; }
  .theme-adminflare a.list-group-item.active .list-group-item-text {
    color: #ebf4fd; }
.theme-adminflare .form-control:focus,
.theme-adminflare .form-control.focus {
  border-color: #3690e6;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }
.gt-ie8 .theme-adminflare input.px:focus + .lbl:before, .gt-ie8 .theme-adminflare input.px + .lbl:hover:before {
  border-color: #3690e6; }
.gt-ie8 .theme-adminflare input[type="checkbox"].px + .lbl:after {
  color: #3690e6; }
.gt-ie8 .theme-adminflare input[type="radio"].px + .lbl:after {
  background: #3690e6; }
.theme-adminflare .text-primary, .theme-adminflare .text-primary:hover, .theme-adminflare .text-primary:active, .theme-adminflare .text-primary:focus,
.theme-adminflare .text-primary a,
.theme-adminflare .text-primary a:focus {
  color: #3690e6; }
.theme-adminflare .text-primary a:hover,
.theme-adminflare .text-primary a:active {
  color: #176ab9; }
.theme-adminflare .bg-primary {
  background: #3690e6 !important; }
  .theme-adminflare .bg-primary[href]:hover {
    background: #3088dd !important; }
  .theme-adminflare .bg-primary.darken {
    background: #3088dd !important; }
    .theme-adminflare .bg-primary.darken[href]:hover {
      background: #2481da !important; }
  .theme-adminflare .bg-primary.darker {
    background: #2481da !important; }
    .theme-adminflare .bg-primary.darker[href]:hover {
      background: #2179cd !important; }
  .theme-adminflare .bg-primary, .theme-adminflare .bg-primary:hover, .theme-adminflare .bg-primary:active, .theme-adminflare .bg-primary:focus,
  .theme-adminflare .bg-primary a,
  .theme-adminflare .bg-primary a:focus {
    color: #fff; }
  .theme-adminflare .bg-primary a:hover,
  .theme-adminflare .bg-primary a:active {
    color: #d9d9d9; }
  .theme-adminflare .bg-primary[href]:hover,
  .theme-adminflare .bg-primary [href]:hover {
    color: #fff; }
  .theme-adminflare .bg-primary * {
    border-color: #56a2ea; }
.theme-adminflare .ui-slider-handle.ui-state-active:before {
  background: #3690e6; }
.theme-adminflare .ui-slider-range {
  background: #3690e6; }
.theme-adminflare .ui-autocomplete > li > a:hover,
.theme-adminflare .ui-autocompletev > li.active > a {
  background: #3690e6; }
.theme-adminflare .ui-autocomplete > li > a:hover {
  color: #fff; }
.theme-adminflare .ui-autocomplete .ui-state-focus {
  background: #3690e6;
  color: #fff; }
.theme-adminflare .ui-menu .ui-menu-item a:hover,
.theme-adminflare .ui-menu .ui-menu-item a.ui-state-focus,
.theme-adminflare .ui-menu .ui-menu-item a.ui-state-active {
  background: #3690e6; }
.theme-adminflare .ui-progressbar-value {
  border-color: #1c81e1;
  background-color: #3690e6;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px; }
.theme-adminflare .ui-tabs-nav > .ui-tabs-active > a, .theme-adminflare .ui-tabs-nav > .ui-tabs-active > a:hover, .theme-adminflare .ui-tabs-nav > .ui-tabs-active > a:focus {
  color: #fff;
  background: #3690e6;
  border-bottom: 2px solid #1f83e3; }
.theme-adminflare .ui-datepicker .ui-state-active {
  background: #3690e6 !important; }
.theme-adminflare .ui-datepicker-title {
  background: #3690e6; }
.theme-adminflare .ui-datepicker th {
  background: #3883ca; }
.theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary {
  color: #fff; }
  .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary, .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary:focus {
    border-color: #1f83e3;
    border-bottom-color: #1974cb;
    background: #3690e6;
    background-image: -webkit-linear-gradient(top, #3b93e7 0%, #1f83e3 100%);
    background-image: linear-gradient(to bottom, #3b93e7 0%, #1f83e3 100%);
    background-repeat: repeat-x; }
  .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary:hover {
    color: #fff;
    border-color: #1b7cd8;
    border-bottom-color: #1562ab;
    background: #1f83e3 !important;
    background-image: -webkit-linear-gradient(top, #2d8be5 0%, #1b7cd8 100%) !important;
    background-image: linear-gradient(to bottom, #2d8be5 0%, #1b7cd8 100%) !important;
    background-repeat: repeat-x; }
  .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary:active, .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary.active, .open .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
    background: none;
    background: #2486e4 !important;
    border-color: #186cbd;
    border-bottom-color: #186cbd !important;
    color: #fff;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset; }
  .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat {
    background: #2888e4; }
    .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:hover, .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:active, .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.active, .open .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.dropdown-toggle {
      background: #2888e4 !important;
      color: #fff; }
  .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:hover, .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:hover {
    color: #fff;
    border-color: #1b7cd8;
    border-bottom-color: #1562ab; }
  .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:active, .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:active {
    border-color: #186cbd; }
  .btn-group .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary, .btn-group .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary:focus, .input-group-btn .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary, .input-group-btn .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary:focus {
    border-left-color: #1b7cd8;
    border-right-color: #1b7cd8; }
  .btn-group .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary:hover, .btn-group.open .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle, .input-group-btn .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary:hover, .input-group-btn.open .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
    border-color: #1b7cd8;
    border-bottom-color: #1562ab; }
  .btn-group .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary:active, .btn-group .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary.active, .input-group-btn .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary:active, .input-group-btn .theme-adminflare .ui-datepicker-buttonpane button.ui-priority-primary.active {
    border-left-color: #186cbd;
    border-right-color: #186cbd; }
.theme-adminflare .select2-drop-active,
.theme-adminflare .select2-container-multi.select2-container-active .select2-choices,
.theme-adminflare .select2-container-multi.select2-dropdown-open .select2-choices {
  border: 1px solid #3690e6; }
.theme-adminflare .select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid #3690e6; }
.theme-adminflare .select2-container-active .select2-choice,
.theme-adminflare .select2-container-active .select2-choices {
  border: 1px solid #3690e6; }
.theme-adminflare .select2-dropdown-open .select2-drop-above .select2-choice,
.theme-adminflare .select2-dropdown-open .select2-drop-above .select2-choices {
  border: 1px solid #3690e6;
  border-top-color: #aaa; }
.theme-adminflare .select2-results .select2-highlighted {
  background: #3690e6; }
.theme-adminflare .select2-primary .select2-container-multi .select2-choices .select2-search-choice {
  background: #3690e6; }
.theme-adminflare .select2-primary .select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #1a77cf; }
.theme-adminflare .select2-primary .select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  background: #3690e6 !important; }
.theme-adminflare .switcher-primary .switcher-state-on {
  background: #3690e6 !important; }
.theme-adminflare .pixel-file-input:hover {
  border-color: #3690e6; }
.theme-adminflare .datepicker table tr td.selected, .theme-adminflare .datepicker table tr td.selected:hover, .theme-adminflare .datepicker table tr td.selected.disabled, .theme-adminflare .datepicker table tr td.selected.disabled:hover {
  background: #3690e6; }
.theme-adminflare .datepicker table tr td.active, .theme-adminflare .datepicker table tr td.active:hover, .theme-adminflare .datepicker table tr td.active.disabled, .theme-adminflare .datepicker table tr td.active.disabled:hover {
  background: #3690e6; }
.theme-adminflare .datepicker table tr td span.active, .theme-adminflare .datepicker table tr td span.active:hover, .theme-adminflare .datepicker table tr td span.active.disabled, .theme-adminflare .datepicker table tr td span.active.disabled:hover {
  background: #3690e6; }
.theme-adminflare .datepicker thead tr {
  background: #3883ca; }
  .theme-adminflare .datepicker thead tr:first-child {
    background: #3690e6 !important;
    border-bottom-color: #3a71a5 !important; }
.theme-adminflare .datepicker thead th.prev,
.theme-adminflare .datepicker thead th.next {
  background: #3690e6; }
.theme-adminflare .datepicker thead th.prev {
  border-color: #3a71a5; }
.theme-adminflare .datepicker thead th.next {
  border-color: #3a71a5; }
.theme-adminflare .datepicker > .datepicker-days thead tr:first-child th.cw + .prev {
  border-color: #3a71a5; }
.theme-adminflare .datepicker > .datepicker-days thead tr:last-child th.cw + th {
  border-color: #3a71a5; }
.theme-adminflare .bootstrap-timepicker-widget table td a:hover {
  background: #3690e6;
  border-color: #3690e6; }
.theme-adminflare .pagination a.dp-today,
.theme-adminflare .pagination a.dp-today:hover {
  background: #63a9ec;
  border-color: #4d9de9; }
.theme-adminflare .pagination a.dp-today:hover {
  border-color: #3690e6 !important; }
.theme-adminflare .pagination a.dp-selected {
  background: #3690e6 !important;
  border-color: #3690e6 !important; }
.theme-adminflare .tt-suggestion.tt-is-under-cursor {
  background: #3690e6; }
.theme-adminflare .table-primary .dataTables_wrapper .DT-per-page {
  border-color: #2971b5 !important; }
.theme-adminflare .dropzone-box.dz-drag-hover {
  border-color: #3690e6; }
.theme-adminflare .dropzone-box:hover .fa.fa-cloud-upload {
  background: #3690e6; }
.theme-adminflare .md-editor.active {
  border-color: #3690e6; }
.theme-adminflare .widget-rating .active a {
  color: #3690e6; }

.theme-dust {
  background: #edece7; }
.theme-dust .page-header {
  background: #f6f5f3;
  border-color: #d6d6d6; }
.theme-dust .table,
.theme-dust .panel,
.theme-dust hr {
  border-color: #d6d6d6; }
.theme-dust .bordered {
  border-color: #d6d6d6; }
.theme-dust .bordered[class*='bg-'],
.theme-dust [class*='bg-'] .border-t {
  border-color: #fff;
  border-color: rgba(0, 0, 0, 0.2); }
.theme-dust .panel.panel-transparent > .panel-heading {
  border-bottom-color: #d6d6d6 !important; }
.theme-dust .breadcrumb-page {
  background: #f6f5f3;
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 6px;
  padding-top: 6px; }
.theme-dust #main-navbar {
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04), 0 1px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04), 0 1px 0 rgba(0, 0, 0, 0.04); }
  .theme-dust #main-navbar .navbar-header {
    background: #fff; }
    .theme-dust #main-navbar .navbar-header a {
      color: #555; }
  .theme-dust #main-navbar .navbar-collapse {
    border-top: 1px solid #f1f1f1;
    background: #fff;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .theme-dust #main-navbar .dropdown > a,
  .theme-dust #main-navbar .dropdown-menu > li > a,
  .theme-dust #main-navbar .nav > li > a {
    color: #555; }
  .theme-dust #main-navbar .navbar-toggle {
    color: #555; }
  .theme-dust #main-navbar #main-menu-toggle {
    background: #f65d35;
    color: #fff;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box; }
    .theme-dust #main-navbar #main-menu-toggle .fa:before {
      color: #fff; }
  .theme-dust #main-navbar li + li,
  .theme-dust #main-navbar .nav + .nav,
  .theme-dust #main-navbar .nav + .right {
    border-top: 1px solid #f1f1f1; }
  .theme-dust #main-navbar .dropdown.open > a {
    border-bottom: 1px solid #e4e4e4;
    border-top: 1px solid #dfdfdf;
    margin-top: -1px;
    background: #f6f6f6; }
  .theme-dust #main-navbar form.navbar-form {
    -webkit-box-shadow: none;
    box-shadow: none; }
    .theme-dust #main-navbar form.navbar-form .form-control {
      background: #fff;
      border-color: #ddd;
      border-radius: 999999px;
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s; }
      .theme-dust #main-navbar form.navbar-form .form-control:focus {
        background: #fff;
        border-color: #f65d35; }
@media (min-width: 768px) {
  .theme-dust #main-navbar .navbar-header {
    background: #f65d35; }
    .theme-dust #main-navbar .navbar-header a {
      color: #fff; }
  .theme-dust #main-navbar .navbar-collapse {
    background: #fff;
    border: none; }
  .theme-dust #main-navbar #main-menu-toggle {
    background: #f7714d; }
  .theme-dust #main-navbar li + li,
  .theme-dust #main-navbar .nav + .nav,
  .theme-dust #main-navbar .nav + .right {
    border-top: none; }
  .theme-dust #main-navbar .nav > li > a:hover {
    color: #555;
    background: #f8f8f8; }
  .theme-dust #main-navbar .dropdown.open > a {
    border-top: none;
    border-bottom: none;
    margin: 0;
    background: #f8f8f8;
    color: #555; }
  .theme-dust #main-navbar .dropdown-menu {
    border-top: none;
    margin-top: 1px;
    border-width: 2px;
    padding: 5px 0; }
    .theme-dust #main-navbar .dropdown-menu li > a {
      color: #555;
      margin: 0 -2px; }
      .theme-dust #main-navbar .dropdown-menu li > a:hover {
        background: #f65d35;
        color: #fff; }
  .theme-dust #main-navbar .widget-notifications a {
    color: !important; }
  .theme-dust #main-navbar .widget-notifications .notification,
  .theme-dust #main-navbar .widget-notifications .notifications-link {
    border-color: #f1f1f1;
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }
  .theme-dust #main-navbar .widget-notifications .notification-description {
    color: #999; }
  .theme-dust #main-navbar .widget-notifications .notification-ago {
    color: #bbb; }
  .theme-dust #main-navbar .widget-notifications .notifications-link:hover {
    background: #f1f1f1; }
  .theme-dust #main-navbar .widget-messages-alt a {
    color: !important; }
  .theme-dust #main-navbar .widget-messages-alt .message,
  .theme-dust #main-navbar .widget-messages-alt .messages-link {
    border-color: #f1f1f1;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }
  .theme-dust #main-navbar .widget-messages-alt .message-subject {
    color: #555 !important; }
  .theme-dust #main-navbar .widget-messages-alt .message-description {
    color: #aaa; }
    .theme-dust #main-navbar .widget-messages-alt .message-description a {
      color: #888; }
  .theme-dust #main-navbar .widget-messages-alt .messages-link:hover {
    background: #f1f1f1; }
  .theme-dust.mmc #main-navbar #main-menu-toggle {
    background: #f65d35; }
  .theme-dust.mmc #main-navbar .navbar-header {
    background: #fff; }
    .theme-dust.mmc #main-navbar .navbar-header a {
      color: #555; } }
.theme-dust.main-menu-right #main-navbar .navbar-header, .theme-dust.no-main-menu #main-navbar .navbar-header, .theme-dust.no-main-menu #main-navbar .navbar-header {
  background: #fff; }
  .theme-dust.main-menu-right #main-navbar .navbar-header a, .theme-dust.no-main-menu #main-navbar .navbar-header a, .theme-dust.no-main-menu #main-navbar .navbar-header a {
    color: #555; }
.theme-dust #main-menu-bg,
.theme-dust .mmc-dropdown-open-ul {
  background: #433d3c; }
.theme-dust #main-menu .navigation li a,
.theme-dust #main-menu .mmc-dropdown-open-ul li a {
  color: #a19796;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s; }
  .theme-dust #main-menu .navigation li a:hover,
  .theme-dust #main-menu .mmc-dropdown-open-ul li a:hover {
    color: #fff; }
.theme-dust #main-menu .navigation li.active > a,
.theme-dust #main-menu .mmc-dropdown-open-ul li.active > a {
  color: #fff;
  background: #363130; }
.theme-dust #main-menu .navigation > .mm-dropdown.open,
.theme-dust #main-menu .mmc-dropdown-open-ul > .mm-dropdown.open {
  border-bottom: 1px solid #433d3c; }
.theme-dust #main-menu .mm-dropdown > ul,
.theme-dust #main-menu .mmc-dropdown-open-ul {
  background: #363130; }
  .theme-dust #main-menu .mm-dropdown > ul li.active > a,
  .theme-dust #main-menu .mmc-dropdown-open-ul li.active > a {
    background: #2d2929; }
.theme-dust #main-menu .mm-dropdown.active > a {
  background: none !important;
  color: #fff !important; }
.theme-dust #main-menu .mmc-dropdown-open > a {
  color: #fff !important; }
.theme-dust #main-menu .mmc-title {
  color: #fff;
  background: #433d3c; }
.theme-dust #main-menu .menu-content {
  border-color: #504948; }
  .theme-dust #main-menu .menu-content .btn-outline {
    border-color: #504948;
    color: #a19796; }
    .theme-dust #main-menu .menu-content .btn-outline:hover {
      color: #fff; }
.theme-dust .mmc-dropdown-open .mm-dropdown.open > a, .theme-dust.mme #main-menu .mm-dropdown.open > a {
  color: #fff; }
@media (min-width: 768px) {
  .theme-dust #main-menu .mm-dropdown.open > a {
    color: #fff; }
  .theme-dust.mmc #main-menu .navigation > .mm-dropdown.open > a {
    color: #9e9593; } }
.theme-dust .demo-logo {
  background: #7a6f6f; }
.theme-dust #main-navbar .navbar-brand div {
  background: #f6653f; }
.theme-dust #main-navbar .navbar-header a {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600; }
.theme-dust.page-signin .signin-info, .theme-dust.page-signup .signup-header {
  background: #433d3c;
  background: rgba(67, 61, 60, 0.8); }
.theme-dust .timeline:before,
.theme-dust .timeline:after,
.theme-dust .tl-header,
.theme-dust .tl-icon {
  background: #d7d5ca; }
.theme-dust .tl-header,
.theme-dust .tl-icon {
  box-shadow: 0 0 0 4px #edece7; }
.theme-dust .tl-entry:before {
  background: #7a6f6f;
  box-shadow: 0 0 0 3px #edece7; }
.theme-dust .tl-body:before {
  border-color: transparent #d6d6d6 transparent transparent; }
@media (min-width: 768px) {
  .theme-dust .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent transparent transparent #d6d6d6; }
  .theme-dust.right-to-left .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent #d6d6d6 transparent transparent; } }
.theme-dust.right-to-left .tl-body:before {
  border-color: transparent transparent transparent #d6d6d6; }
.theme-dust.page-mail .mail-nav,
.theme-dust.page-mail .mail-nav:before {
  background: #edece7; }
.theme-dust.page-mail .mail-nav .sections li.active .label,
.theme-dust.page-mail .mail-nav .sections li.active .badge {
  background: #7a6f6f; }
.theme-dust.page-mail .mail-nav,
.theme-dust.page-mail .mail-nav .compose-btn,
.theme-dust.page-mail .mail-nav .sections li.active,
.theme-dust.page-mail .mail-nav:before {
  border-color: #d6d6d6; }
.theme-dust.page-mail .mail-nav .navigation.open .sections li.active {
  background: #e1e1de; }
.theme-dust.page-mail .mail-nav li.divider {
  background: #d6d6d6; }
.theme-dust.page-mail .mail-nav .sections li.active a {
  color: #383838; }
.theme-dust.page-mail .mail-nav .sections li.active:after {
  color: #9e9e9e; }
.theme-dust.page-mail .mail-nav .sections a {
  color: #5e5e5e; }
.theme-dust.page-mail .mail-nav-header {
  color: #9e9e9e; }
.theme-dust.page-mail .mail-nav .add-more a {
  color: #9e9e9e; }
@media (min-width: 992px) {
  .theme-dust.page-mail .mail-nav .navigation .sections li.active {
    background: #e1e1de; }
  .theme-dust.page-mail .mail-nav .sections a:hover {
    color: #383838; } }
.theme-dust .nav-icon-btn.nav-icon-btn-primary .label {
  background: #7a6f6f; }
.theme-dust .btn-primary {
  color: #fff; }
  .theme-dust .btn-primary, .theme-dust .btn-primary:focus {
    border-color: #6d6363;
    border-bottom-color: #5d5454;
    background: #7a6f6f;
    background-image: -webkit-linear-gradient(top, #7d7171 0%, #6d6363 100%);
    background-image: linear-gradient(to bottom, #7d7171 0%, #6d6363 100%);
    background-repeat: repeat-x; }
  .theme-dust .btn-primary:hover {
    color: #fff;
    border-color: #655c5c;
    border-bottom-color: #4a4343;
    background: #6d6363 !important;
    background-image: -webkit-linear-gradient(top, #756a6a 0%, #655c5c 100%) !important;
    background-image: linear-gradient(to bottom, #756a6a 0%, #655c5c 100%) !important;
    background-repeat: repeat-x; }
  .theme-dust .btn-primary:active, .theme-dust .btn-primary.active, .open .theme-dust .btn-primary.dropdown-toggle {
    background: none;
    background: #6f6565 !important;
    border-color: #554d4d;
    border-bottom-color: #554d4d !important;
    color: #fff;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset; }
  .theme-dust .btn-primary.btn-flat {
    background: #726868; }
    .theme-dust .btn-primary.btn-flat:hover, .theme-dust .btn-primary.btn-flat:active, .theme-dust .btn-primary.btn-flat.active, .open .theme-dust .btn-primary.btn-flat.dropdown-toggle {
      background: #726868 !important;
      color: #fff; }
  .theme-dust .btn-primary.btn-outline:hover, .theme-dust .btn-primary.btn-outline.btn-flat:hover {
    color: #fff;
    border-color: #655c5c;
    border-bottom-color: #4a4343; }
  .theme-dust .btn-primary.btn-outline:active, .theme-dust .btn-primary.btn-outline.btn-flat:active {
    border-color: #554d4d; }
  .btn-group .theme-dust .btn-primary, .btn-group .theme-dust .btn-primary:focus, .input-group-btn .theme-dust .btn-primary, .input-group-btn .theme-dust .btn-primary:focus {
    border-left-color: #655c5c;
    border-right-color: #655c5c; }
  .btn-group .theme-dust .btn-primary:hover, .btn-group.open .theme-dust .btn-primary.dropdown-toggle, .input-group-btn .theme-dust .btn-primary:hover, .input-group-btn.open .theme-dust .btn-primary.dropdown-toggle {
    border-color: #655c5c;
    border-bottom-color: #4a4343; }
  .btn-group .theme-dust .btn-primary:active, .btn-group .theme-dust .btn-primary.active, .input-group-btn .theme-dust .btn-primary:active, .input-group-btn .theme-dust .btn-primary.active {
    border-left-color: #554d4d;
    border-right-color: #554d4d; }
.theme-dust .open .btn.btn-primary.dropdown-toggle {
  color: #fff;
  border-bottom-color: #554d4d !important;
  background: none;
  background: #6f6565 !important; }
.theme-dust .progress-bar {
  background: #7a6f6f;
  border-color: #6a6060; }
.theme-dust .progress-striped .progress-bar {
  background-color: #7a6f6f;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px; }
.theme-dust .label.label-primary {
  background: #7a6f6f; }
  .theme-dust .label.label-primary[href]:hover, .theme-dust .label.label-primary[href]:focus {
    background-color: #5f5757; }
  .theme-dust .label.label-primary.label-tag {
    border: 1px solid #7a6f6f; }
    .theme-dust .label.label-primary.label-tag:before {
      border-color: transparent #7a6f6f transparent transparent; }
    .theme-dust .label.label-primary.label-tag[href]:hover, .theme-dust .label.label-primary.label-tag[href]:focus {
      border-color: #5f5757; }
    .theme-dust .label.label-primary.label-tag[href]:hover:before, .theme-dust .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent #5f5757 transparent transparent; }
    .right-to-left .theme-dust .label.label-primary.label-tag:before {
      border-color: transparent transparent transparent #7a6f6f; }
    .right-to-left .theme-dust .label.label-primary.label-tag[href]:hover:before, .right-to-left .theme-dust .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent transparent transparent #5f5757; }
    .ie8 .right-to-left .theme-dust .label.label-primary.label-tag:before, .ie9 .right-to-left .theme-dust .label.label-primary.label-tag:before {
      border-color: transparent #7a6f6f transparent transparent; }
    .ie8 .right-to-left .theme-dust .label.label-primary.label-tag[href]:hover:before, .ie8 .right-to-left .theme-dust .label.label-primary.label-tag[href]:focus:before, .ie9 .right-to-left .theme-dust .label.label-primary.label-tag[href]:hover:before, .ie9 .right-to-left .theme-dust .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent #5f5757 transparent transparent; }
.theme-dust.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent transparent transparent #7a6f6f; }
.theme-dust.right-to-left .label.label-primary.label-tag:hover:before, .theme-dust.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent transparent transparent #5f5757; }
.ie8 .theme-dust.right-to-left .label.label-primary.label-tag:before, .ie9 .theme-dust.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent #7a6f6f transparent transparent; }
.ie8 .theme-dust.right-to-left .label.label-primary.label-tag:hover:before, .ie8 .theme-dust.right-to-left .label.label-primary.label-tag:focus:before, .ie9 .theme-dust.right-to-left .label.label-primary.label-tag:hover:before, .ie9 .theme-dust.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent #5f5757 transparent transparent; }
.theme-dust .badge.badge-primary {
  background: #7a6f6f;
  border: 1px solid #7a6f6f; }
  .theme-dust .badge.badge-primary[href]:hover, .theme-dust .badge.badge-primary[href]:focus {
    background-color: #5f5757;
    border-color: #5f5757; }
.theme-dust .table-primary table {
  border-top-color: #565656 !important; }
.theme-dust .table-primary thead,
.theme-dust .table-primary thead tr,
.theme-dust .table-primary thead th,
.theme-dust .table-primary .table-header {
  border-color: #565656 !important;
  color: #fff; }
.theme-dust .table-primary thead tr,
.theme-dust .table-primary thead th {
  background: #656565; }
.theme-dust .table-primary .table-header {
  background: #7a6f6f; }
.theme-dust .nav-tabs > li.active > a, .theme-dust .nav-tabs > li.active > a:hover, .theme-dust .nav-tabs > li.active > a:focus {
  background: #7a6f6f;
  border-bottom: 2px solid #6d6363; }
.theme-dust .nav-tabs .nav-tabs.nav-justified > .active > a {
  border-bottom-color: #6d6363; }
.theme-dust .nav-pills > li.active > a, .theme-dust .nav-pills > li.active > a:hover, .theme-dust .nav-pills > li.active > a:focus {
  background: #7a6f6f; }
.theme-dust .dropdown-menu > li > a:hover,
.theme-dust .dropdown-menu > li.active > a {
  background: #7a6f6f; }
.theme-dust .dropdown-menu > li > a:hover {
  color: #fff; }
.theme-dust .btn-group .btn-primary,
.theme-dust .btn-group .btn-primary:focus,
.theme-dust .input-group-btn .btn-primary,
.theme-dust .input-group-btn .btn-primary:focus {
  border-left-color: #655c5c;
  border-right-color: #655c5c; }
.theme-dust .btn-group .btn-primary:hover,
.theme-dust .btn-group.open .btn-primary.dropdown-toggle,
.theme-dust .input-group-btn .btn-primary:hover,
.theme-dust .input-group-btn.open .btn-primary.dropdown-toggle {
  border-color: #554d4d; }
.theme-dust .btn-group .btn-primary:active,
.theme-dust .btn-group .btn-primary.active,
.theme-dust .input-group-btn .btn-primary:active,
.theme-dust .input-group-btn .btn-primary.active {
  border-left-color: #554d4d;
  border-right-color: #554d4d; }
.theme-dust .pagination > li > a:hover {
  border-color: #7a6f6f; }
.theme-dust .pagination > li.active > a,
.theme-dust .pagination > li.active > a:hover,
.theme-dust .pagination > li.active > a:focus,
.theme-dust .pagination > li.active > span {
  background: #7a6f6f;
  border-color: #7a6f6f; }
.theme-dust .pager li > a:hover {
  border-color: #7a6f6f; }
.theme-dust a.list-group-item.active {
  background: #7a6f6f;
  border-color: #7a6f6f; }
  .theme-dust a.list-group-item.active .list-group-item-text {
    color: #dcd9d9; }
.theme-dust .form-control:focus,
.theme-dust .form-control.focus {
  border-color: #7a6f6f;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }
.gt-ie8 .theme-dust input.px:focus + .lbl:before, .gt-ie8 .theme-dust input.px + .lbl:hover:before {
  border-color: #7a6f6f; }
.gt-ie8 .theme-dust input[type="checkbox"].px + .lbl:after {
  color: #7a6f6f; }
.gt-ie8 .theme-dust input[type="radio"].px + .lbl:after {
  background: #7a6f6f; }
.theme-dust .text-primary, .theme-dust .text-primary:hover, .theme-dust .text-primary:active, .theme-dust .text-primary:focus,
.theme-dust .text-primary a,
.theme-dust .text-primary a:focus {
  color: #7a6f6f; }
.theme-dust .text-primary a:hover,
.theme-dust .text-primary a:active {
  color: #524b4b; }
.theme-dust .bg-primary {
  background: #7a6f6f !important; }
  .theme-dust .bg-primary[href]:hover {
    background: #6d6d6d !important; }
  .theme-dust .bg-primary.darken {
    background: #6d6d6d !important; }
    .theme-dust .bg-primary.darken[href]:hover {
      background: #656565 !important; }
  .theme-dust .bg-primary.darker {
    background: #656565 !important; }
    .theme-dust .bg-primary.darker[href]:hover {
      background: #5e5e5e !important; }
  .theme-dust .bg-primary, .theme-dust .bg-primary:hover, .theme-dust .bg-primary:active, .theme-dust .bg-primary:focus,
  .theme-dust .bg-primary a,
  .theme-dust .bg-primary a:focus {
    color: #fff; }
  .theme-dust .bg-primary a:hover,
  .theme-dust .bg-primary a:active {
    color: #d9d9d9; }
  .theme-dust .bg-primary[href]:hover,
  .theme-dust .bg-primary [href]:hover {
    color: #fff; }
  .theme-dust .bg-primary * {
    border-color: #8c8181; }
.theme-dust .ui-slider-handle.ui-state-active:before {
  background: #7a6f6f; }
.theme-dust .ui-slider-range {
  background: #7a6f6f; }
.theme-dust .ui-autocomplete > li > a:hover,
.theme-dust .ui-autocompletev > li.active > a {
  background: #7a6f6f; }
.theme-dust .ui-autocomplete > li > a:hover {
  color: #fff; }
.theme-dust .ui-autocomplete .ui-state-focus {
  background: #7a6f6f;
  color: #fff; }
.theme-dust .ui-menu .ui-menu-item a:hover,
.theme-dust .ui-menu .ui-menu-item a.ui-state-focus,
.theme-dust .ui-menu .ui-menu-item a.ui-state-active {
  background: #7a6f6f; }
.theme-dust .ui-progressbar-value {
  border-color: #6a6060;
  background-color: #7a6f6f;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px; }
.theme-dust .ui-tabs-nav > .ui-tabs-active > a, .theme-dust .ui-tabs-nav > .ui-tabs-active > a:hover, .theme-dust .ui-tabs-nav > .ui-tabs-active > a:focus {
  color: #fff;
  background: #7a6f6f;
  border-bottom: 2px solid #6d6363; }
.theme-dust .ui-datepicker .ui-state-active {
  background: #7a6f6f !important; }
.theme-dust .ui-datepicker-title {
  background: #7a6f6f; }
.theme-dust .ui-datepicker th {
  background: #686868; }
.theme-dust .ui-datepicker-buttonpane button.ui-priority-primary {
  color: #fff; }
  .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary, .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary:focus {
    border-color: #6d6363;
    border-bottom-color: #5d5454;
    background: #7a6f6f;
    background-image: -webkit-linear-gradient(top, #7d7171 0%, #6d6363 100%);
    background-image: linear-gradient(to bottom, #7d7171 0%, #6d6363 100%);
    background-repeat: repeat-x; }
  .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary:hover {
    color: #fff;
    border-color: #655c5c;
    border-bottom-color: #4a4343;
    background: #6d6363 !important;
    background-image: -webkit-linear-gradient(top, #756a6a 0%, #655c5c 100%) !important;
    background-image: linear-gradient(to bottom, #756a6a 0%, #655c5c 100%) !important;
    background-repeat: repeat-x; }
  .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary:active, .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary.active, .open .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
    background: none;
    background: #6f6565 !important;
    border-color: #554d4d;
    border-bottom-color: #554d4d !important;
    color: #fff;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset; }
  .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat {
    background: #726868; }
    .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:hover, .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:active, .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.active, .open .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.dropdown-toggle {
      background: #726868 !important;
      color: #fff; }
  .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:hover, .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:hover {
    color: #fff;
    border-color: #655c5c;
    border-bottom-color: #4a4343; }
  .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:active, .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:active {
    border-color: #554d4d; }
  .btn-group .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary, .btn-group .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary:focus, .input-group-btn .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary, .input-group-btn .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary:focus {
    border-left-color: #655c5c;
    border-right-color: #655c5c; }
  .btn-group .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary:hover, .btn-group.open .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle, .input-group-btn .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary:hover, .input-group-btn.open .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
    border-color: #655c5c;
    border-bottom-color: #4a4343; }
  .btn-group .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary:active, .btn-group .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary.active, .input-group-btn .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary:active, .input-group-btn .theme-dust .ui-datepicker-buttonpane button.ui-priority-primary.active {
    border-left-color: #554d4d;
    border-right-color: #554d4d; }
.theme-dust .select2-drop-active,
.theme-dust .select2-container-multi.select2-container-active .select2-choices,
.theme-dust .select2-container-multi.select2-dropdown-open .select2-choices {
  border: 1px solid #7a6f6f; }
.theme-dust .select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid #7a6f6f; }
.theme-dust .select2-container-active .select2-choice,
.theme-dust .select2-container-active .select2-choices {
  border: 1px solid #7a6f6f; }
.theme-dust .select2-dropdown-open .select2-drop-above .select2-choice,
.theme-dust .select2-dropdown-open .select2-drop-above .select2-choices {
  border: 1px solid #7a6f6f;
  border-top-color: #aaa; }
.theme-dust .select2-results .select2-highlighted {
  background: #7a6f6f; }
.theme-dust .select2-primary .select2-container-multi .select2-choices .select2-search-choice {
  background: #7a6f6f; }
.theme-dust .select2-primary .select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #5f5757; }
.theme-dust .select2-primary .select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  background: #7a6f6f !important; }
.theme-dust .switcher-primary .switcher-state-on {
  background: #7a6f6f !important; }
.theme-dust .pixel-file-input:hover {
  border-color: #7a6f6f; }
.theme-dust .datepicker table tr td.selected, .theme-dust .datepicker table tr td.selected:hover, .theme-dust .datepicker table tr td.selected.disabled, .theme-dust .datepicker table tr td.selected.disabled:hover {
  background: #7a6f6f; }
.theme-dust .datepicker table tr td.active, .theme-dust .datepicker table tr td.active:hover, .theme-dust .datepicker table tr td.active.disabled, .theme-dust .datepicker table tr td.active.disabled:hover {
  background: #7a6f6f; }
.theme-dust .datepicker table tr td span.active, .theme-dust .datepicker table tr td span.active:hover, .theme-dust .datepicker table tr td span.active.disabled, .theme-dust .datepicker table tr td span.active.disabled:hover {
  background: #7a6f6f; }
.theme-dust .datepicker thead tr {
  background: #686868; }
  .theme-dust .datepicker thead tr:first-child {
    background: #7a6f6f !important;
    border-bottom-color: #565656 !important; }
.theme-dust .datepicker thead th.prev,
.theme-dust .datepicker thead th.next {
  background: #7a6f6f; }
.theme-dust .datepicker thead th.prev {
  border-color: #565656; }
.theme-dust .datepicker thead th.next {
  border-color: #565656; }
.theme-dust .datepicker > .datepicker-days thead tr:first-child th.cw + .prev {
  border-color: #565656; }
.theme-dust .datepicker > .datepicker-days thead tr:last-child th.cw + th {
  border-color: #565656; }
.theme-dust .bootstrap-timepicker-widget table td a:hover {
  background: #7a6f6f;
  border-color: #7a6f6f; }
.theme-dust .pagination a.dp-today,
.theme-dust .pagination a.dp-today:hover {
  background: #938989;
  border-color: #877b7b; }
.theme-dust .pagination a.dp-today:hover {
  border-color: #7a6f6f !important; }
.theme-dust .pagination a.dp-selected {
  background: #7a6f6f !important;
  border-color: #7a6f6f !important; }
.theme-dust .tt-suggestion.tt-is-under-cursor {
  background: #7a6f6f; }
.theme-dust .table-primary .dataTables_wrapper .DT-per-page {
  border-color: #565656 !important; }
.theme-dust .dropzone-box.dz-drag-hover {
  border-color: #7a6f6f; }
.theme-dust .dropzone-box:hover .fa.fa-cloud-upload {
  background: #7a6f6f; }
.theme-dust .md-editor.active {
  border-color: #7a6f6f; }
.theme-dust .widget-rating .active a {
  color: #7a6f6f; }

.theme-frost {
  background: #e8edfd; }
.theme-frost .page-header {
  background: #f3f5f9;
  border-color: #dcdfe0; }
.theme-frost .table,
.theme-frost .panel,
.theme-frost hr {
  border-color: #cfd5d7; }
.theme-frost .bordered {
  border-color: #cfd5d7; }
.theme-frost .bordered[class*='bg-'],
.theme-frost [class*='bg-'] .border-t {
  border-color: #fff;
  border-color: rgba(0, 0, 0, 0.2); }
.theme-frost .panel.panel-transparent > .panel-heading {
  border-bottom-color: #cfd5d7 !important; }
.theme-frost .breadcrumb-page {
  background: #f3f5f9;
  border-bottom: 1px solid #dcdfe0;
  padding-bottom: 6px;
  padding-top: 6px; }
.theme-frost #main-navbar {
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04), 0 1px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04), 0 1px 0 rgba(0, 0, 0, 0.04); }
  .theme-frost #main-navbar .navbar-header {
    background: #fff; }
    .theme-frost #main-navbar .navbar-header a {
      color: #555; }
  .theme-frost #main-navbar .navbar-collapse {
    border-top: 1px solid #f1f1f1;
    background: #fff;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .theme-frost #main-navbar .dropdown > a,
  .theme-frost #main-navbar .dropdown-menu > li > a,
  .theme-frost #main-navbar .nav > li > a {
    color: #555; }
  .theme-frost #main-navbar .navbar-toggle {
    color: #555; }
  .theme-frost #main-navbar #main-menu-toggle {
    background: #1aaada;
    color: #fff;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box; }
    .theme-frost #main-navbar #main-menu-toggle .fa:before {
      color: #fff; }
  .theme-frost #main-navbar li + li,
  .theme-frost #main-navbar .nav + .nav,
  .theme-frost #main-navbar .nav + .right {
    border-top: 1px solid #f1f1f1; }
  .theme-frost #main-navbar .dropdown.open > a {
    border-bottom: 1px solid #e4e4e4;
    border-top: 1px solid #dfdfdf;
    margin-top: -1px;
    background: #f6f6f6; }
  .theme-frost #main-navbar form.navbar-form {
    -webkit-box-shadow: none;
    box-shadow: none; }
    .theme-frost #main-navbar form.navbar-form .form-control {
      background: #fff;
      border-color: #ddd;
      border-radius: 999999px;
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s; }
      .theme-frost #main-navbar form.navbar-form .form-control:focus {
        background: #fff;
        border-color: #1aaada; }
@media (min-width: 768px) {
  .theme-frost #main-navbar .navbar-header {
    background: #1aaada; }
    .theme-frost #main-navbar .navbar-header a {
      color: #fff; }
  .theme-frost #main-navbar .navbar-collapse {
    background: #fff;
    border: none; }
  .theme-frost #main-navbar #main-menu-toggle {
    background: #28b6e5; }
  .theme-frost #main-navbar li + li,
  .theme-frost #main-navbar .nav + .nav,
  .theme-frost #main-navbar .nav + .right {
    border-top: none; }
  .theme-frost #main-navbar .nav > li > a:hover {
    color: #555;
    background: #f8f8f8; }
  .theme-frost #main-navbar .dropdown.open > a {
    border-top: none;
    border-bottom: none;
    margin: 0;
    background: #f8f8f8;
    color: #555; }
  .theme-frost #main-navbar .dropdown-menu {
    border-top: none;
    margin-top: 1px;
    border-width: 2px;
    padding: 5px 0; }
    .theme-frost #main-navbar .dropdown-menu li > a {
      color: #555;
      margin: 0 -2px; }
      .theme-frost #main-navbar .dropdown-menu li > a:hover {
        background: #1aaada;
        color: #fff; }
  .theme-frost #main-navbar .widget-notifications a {
    color: !important; }
  .theme-frost #main-navbar .widget-notifications .notification,
  .theme-frost #main-navbar .widget-notifications .notifications-link {
    border-color: #f1f1f1;
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }
  .theme-frost #main-navbar .widget-notifications .notification-description {
    color: #999; }
  .theme-frost #main-navbar .widget-notifications .notification-ago {
    color: #bbb; }
  .theme-frost #main-navbar .widget-notifications .notifications-link:hover {
    background: #f1f1f1; }
  .theme-frost #main-navbar .widget-messages-alt a {
    color: !important; }
  .theme-frost #main-navbar .widget-messages-alt .message,
  .theme-frost #main-navbar .widget-messages-alt .messages-link {
    border-color: #f1f1f1;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }
  .theme-frost #main-navbar .widget-messages-alt .message-subject {
    color: #555 !important; }
  .theme-frost #main-navbar .widget-messages-alt .message-description {
    color: #aaa; }
    .theme-frost #main-navbar .widget-messages-alt .message-description a {
      color: #888; }
  .theme-frost #main-navbar .widget-messages-alt .messages-link:hover {
    background: #f1f1f1; }
  .theme-frost.mmc #main-navbar #main-menu-toggle {
    background: #1aaada; }
  .theme-frost.mmc #main-navbar .navbar-header {
    background: #fff; }
    .theme-frost.mmc #main-navbar .navbar-header a {
      color: #555; } }
.theme-frost.main-menu-right #main-navbar .navbar-header, .theme-frost.no-main-menu #main-navbar .navbar-header, .theme-frost.no-main-menu #main-navbar .navbar-header {
  background: #fff; }
  .theme-frost.main-menu-right #main-navbar .navbar-header a, .theme-frost.no-main-menu #main-navbar .navbar-header a, .theme-frost.no-main-menu #main-navbar .navbar-header a {
    color: #555; }
.theme-frost #main-menu-bg,
.theme-frost .mmc-dropdown-open-ul {
  background: #2a2d33; }
.theme-frost #main-menu .navigation li a,
.theme-frost #main-menu .mmc-dropdown-open-ul li a {
  color: #7f8796;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s; }
  .theme-frost #main-menu .navigation li a:hover,
  .theme-frost #main-menu .mmc-dropdown-open-ul li a:hover {
    color: #fff; }
.theme-frost #main-menu .navigation li.active > a,
.theme-frost #main-menu .mmc-dropdown-open-ul li.active > a {
  color: #fff;
  background: #1e2125; }
.theme-frost #main-menu .navigation > .mm-dropdown.open,
.theme-frost #main-menu .mmc-dropdown-open-ul > .mm-dropdown.open {
  border-bottom: 1px solid #2a2d33; }
.theme-frost #main-menu .mm-dropdown.active > a {
  background: none !important;
  color: #fff !important; }
  .theme-frost #main-menu .mm-dropdown.active > a:after {
    display: none !important; }
.theme-frost #main-menu .mm-dropdown > ul,
.theme-frost #main-menu .mmc-dropdown-open-ul {
  background: #1e2125; }
  .theme-frost #main-menu .mm-dropdown > ul li.active > a,
  .theme-frost #main-menu .mmc-dropdown-open-ul li.active > a {
    background: #18191d; }
.theme-frost #main-menu .mmc-dropdown-open > a {
  color: #fff !important; }
.theme-frost #main-menu .mmc-title {
  color: #fff;
  background: #2a2d33; }
.theme-frost #main-menu .menu-content {
  border-color: #363941; }
  .theme-frost #main-menu .menu-content .btn-outline {
    border-color: #363941;
    color: #7f8796; }
    .theme-frost #main-menu .menu-content .btn-outline:hover {
      color: #fff; }
.theme-frost .mmc-dropdown-open .mm-dropdown.open > a, .theme-frost.mme #main-menu .mm-dropdown.open > a {
  color: #fff !important; }
@media (min-width: 768px) {
  .theme-frost #main-menu .mm-dropdown.open > a {
    color: #fff; }
  .theme-frost.mmc #main-menu .navigation > .mm-dropdown.open > a {
    color: #7c8493; } }
.theme-frost #main-menu .navigation li.active > a, .theme-frost.right-to-left.main-menu-right #main-menu .navigation li.active > a {
  position: relative; }
  .theme-frost #main-menu .navigation li.active > a:after, .theme-frost.right-to-left.main-menu-right #main-menu .navigation li.active > a:after {
    content: "";
    top: 0;
    bottom: 0;
    width: 4px;
    position: absolute;
    right: auto;
    left: 0;
    background: #1aaada; }
.theme-frost.main-menu-right #main-menu .navigation li.active > a:after, .theme-frost.right-to-left #main-menu .navigation li.active > a:after {
  left: auto;
  right: 0; }
.theme-frost .demo-logo {
  background: #3daacf; }
.theme-frost #main-navbar .navbar-brand div {
  background: #1bb1e3; }
.theme-frost #main-navbar .navbar-header a {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600; }
.theme-frost.page-signin .signin-info, .theme-frost.page-signup .signup-header {
  background: #1aaada;
  background: rgba(26, 170, 218, 0.8); }
.theme-frost .timeline:before,
.theme-frost .timeline:after,
.theme-frost .tl-header,
.theme-frost .tl-icon {
  background: #d1dbfb; }
.theme-frost .tl-header,
.theme-frost .tl-icon {
  box-shadow: 0 0 0 4px #e8edfd; }
.theme-frost .tl-entry:before {
  background: #3daacf;
  box-shadow: 0 0 0 3px #e8edfd; }
.theme-frost .tl-body:before {
  border-color: transparent #cfd5d7 transparent transparent; }
@media (min-width: 768px) {
  .theme-frost .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent transparent transparent #cfd5d7; }
  .theme-frost.right-to-left .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent #cfd5d7 transparent transparent; } }
.theme-frost.right-to-left .tl-body:before {
  border-color: transparent transparent transparent #cfd5d7; }
.theme-frost.page-mail .mail-nav,
.theme-frost.page-mail .mail-nav:before {
  background: #e8edfd; }
.theme-frost.page-mail .mail-nav .sections li.active .label,
.theme-frost.page-mail .mail-nav .sections li.active .badge {
  background: #3daacf; }
.theme-frost.page-mail .mail-nav,
.theme-frost.page-mail .mail-nav .compose-btn,
.theme-frost.page-mail .mail-nav .sections li.active,
.theme-frost.page-mail .mail-nav:before {
  border-color: #cfd5d7; }
.theme-frost.page-mail .mail-nav .navigation.open .sections li.active {
  background: #dce3fa; }
.theme-frost.page-mail .mail-nav li.divider {
  background: #cfd5d7; }
.theme-frost.page-mail .mail-nav .sections li.active a {
  color: #373b49; }
.theme-frost.page-mail .mail-nav .sections li.active:after {
  color: #9a9fb2; }
.theme-frost.page-mail .mail-nav .sections a {
  color: #585f75; }
.theme-frost.page-mail .mail-nav-header {
  color: #9a9fb2; }
.theme-frost.page-mail .mail-nav .add-more a {
  color: #9a9fb2; }
@media (min-width: 992px) {
  .theme-frost.page-mail .mail-nav .navigation .sections li.active {
    background: #dce3fa; }
  .theme-frost.page-mail .mail-nav .sections a:hover {
    color: #373b49; } }
.theme-frost .nav-icon-btn.nav-icon-btn-primary .label {
  background: #3daacf; }
.theme-frost .btn-primary {
  color: #fff; }
  .theme-frost .btn-primary, .theme-frost .btn-primary:focus {
    border-color: #309dc2;
    border-bottom-color: #2a89aa;
    background: #3daacf;
    background-image: -webkit-linear-gradient(top, #41acd0 0%, #309dc2 100%);
    background-image: linear-gradient(to bottom, #41acd0 0%, #309dc2 100%);
    background-repeat: repeat-x; }
  .theme-frost .btn-primary:hover {
    color: #fff;
    border-color: #2d93b6;
    border-bottom-color: #23728d;
    background: #309dc2 !important;
    background-image: -webkit-linear-gradient(top, #35a6cd 0%, #2d93b6 100%) !important;
    background-image: linear-gradient(to bottom, #35a6cd 0%, #2d93b6 100%) !important;
    background-repeat: repeat-x; }
  .theme-frost .btn-primary:active, .theme-frost .btn-primary.active, .open .theme-frost .btn-primary.dropdown-toggle {
    background: none;
    background: #31a1c6 !important;
    border-color: #27809e;
    border-bottom-color: #27809e !important;
    color: #fff;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset; }
  .theme-frost .btn-primary.btn-flat {
    background: #32a4cb; }
    .theme-frost .btn-primary.btn-flat:hover, .theme-frost .btn-primary.btn-flat:active, .theme-frost .btn-primary.btn-flat.active, .open .theme-frost .btn-primary.btn-flat.dropdown-toggle {
      background: #32a4cb !important;
      color: #fff; }
  .theme-frost .btn-primary.btn-outline:hover, .theme-frost .btn-primary.btn-outline.btn-flat:hover {
    color: #fff;
    border-color: #2d93b6;
    border-bottom-color: #23728d; }
  .theme-frost .btn-primary.btn-outline:active, .theme-frost .btn-primary.btn-outline.btn-flat:active {
    border-color: #27809e; }
  .btn-group .theme-frost .btn-primary, .btn-group .theme-frost .btn-primary:focus, .input-group-btn .theme-frost .btn-primary, .input-group-btn .theme-frost .btn-primary:focus {
    border-left-color: #2d93b6;
    border-right-color: #2d93b6; }
  .btn-group .theme-frost .btn-primary:hover, .btn-group.open .theme-frost .btn-primary.dropdown-toggle, .input-group-btn .theme-frost .btn-primary:hover, .input-group-btn.open .theme-frost .btn-primary.dropdown-toggle {
    border-color: #2d93b6;
    border-bottom-color: #23728d; }
  .btn-group .theme-frost .btn-primary:active, .btn-group .theme-frost .btn-primary.active, .input-group-btn .theme-frost .btn-primary:active, .input-group-btn .theme-frost .btn-primary.active {
    border-left-color: #27809e;
    border-right-color: #27809e; }
.theme-frost .open .btn.btn-primary.dropdown-toggle {
  color: #fff;
  border-bottom-color: #27809e !important;
  background: none;
  background: #31a1c6 !important; }
.theme-frost .progress-bar {
  background: #3daacf;
  border-color: #2f9abe; }
.theme-frost .progress-striped .progress-bar {
  background-color: #3daacf;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px; }
.theme-frost .label.label-primary {
  background: #3daacf; }
  .theme-frost .label.label-primary[href]:hover, .theme-frost .label.label-primary[href]:focus {
    background-color: #2b8dae; }
  .theme-frost .label.label-primary.label-tag {
    border: 1px solid #3daacf; }
    .theme-frost .label.label-primary.label-tag:before {
      border-color: transparent #3daacf transparent transparent; }
    .theme-frost .label.label-primary.label-tag[href]:hover, .theme-frost .label.label-primary.label-tag[href]:focus {
      border-color: #2b8dae; }
    .theme-frost .label.label-primary.label-tag[href]:hover:before, .theme-frost .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent #2b8dae transparent transparent; }
    .right-to-left .theme-frost .label.label-primary.label-tag:before {
      border-color: transparent transparent transparent #3daacf; }
    .right-to-left .theme-frost .label.label-primary.label-tag[href]:hover:before, .right-to-left .theme-frost .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent transparent transparent #2b8dae; }
    .ie8 .right-to-left .theme-frost .label.label-primary.label-tag:before, .ie9 .right-to-left .theme-frost .label.label-primary.label-tag:before {
      border-color: transparent #3daacf transparent transparent; }
    .ie8 .right-to-left .theme-frost .label.label-primary.label-tag[href]:hover:before, .ie8 .right-to-left .theme-frost .label.label-primary.label-tag[href]:focus:before, .ie9 .right-to-left .theme-frost .label.label-primary.label-tag[href]:hover:before, .ie9 .right-to-left .theme-frost .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent #2b8dae transparent transparent; }
.theme-frost.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent transparent transparent #3daacf; }
.theme-frost.right-to-left .label.label-primary.label-tag:hover:before, .theme-frost.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent transparent transparent #2b8dae; }
.ie8 .theme-frost.right-to-left .label.label-primary.label-tag:before, .ie9 .theme-frost.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent #3daacf transparent transparent; }
.ie8 .theme-frost.right-to-left .label.label-primary.label-tag:hover:before, .ie8 .theme-frost.right-to-left .label.label-primary.label-tag:focus:before, .ie9 .theme-frost.right-to-left .label.label-primary.label-tag:hover:before, .ie9 .theme-frost.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent #2b8dae transparent transparent; }
.theme-frost .badge.badge-primary {
  background: #3daacf;
  border: 1px solid #3daacf; }
  .theme-frost .badge.badge-primary[href]:hover, .theme-frost .badge.badge-primary[href]:focus {
    background-color: #2b8dae;
    border-color: #2b8dae; }
.theme-frost .table-primary table {
  border-top-color: #397f96 !important; }
.theme-frost .table-primary thead,
.theme-frost .table-primary thead tr,
.theme-frost .table-primary thead th,
.theme-frost .table-primary .table-header {
  border-color: #397f96 !important;
  color: #fff; }
.theme-frost .table-primary thead tr,
.theme-frost .table-primary thead th {
  background: #3995b5; }
.theme-frost .table-primary .table-header {
  background: #3daacf; }
.theme-frost .nav-tabs > li.active > a, .theme-frost .nav-tabs > li.active > a:hover, .theme-frost .nav-tabs > li.active > a:focus {
  background: #3daacf;
  border-bottom: 2px solid #309dc2; }
.theme-frost .nav-tabs .nav-tabs.nav-justified > .active > a {
  border-bottom-color: #309dc2; }
.theme-frost .nav-pills > li.active > a, .theme-frost .nav-pills > li.active > a:hover, .theme-frost .nav-pills > li.active > a:focus {
  background: #3daacf; }
.theme-frost .dropdown-menu > li > a:hover,
.theme-frost .dropdown-menu > li.active > a {
  background: #3daacf; }
.theme-frost .dropdown-menu > li > a:hover {
  color: #fff; }
.theme-frost .btn-group .btn-primary,
.theme-frost .btn-group .btn-primary:focus,
.theme-frost .input-group-btn .btn-primary,
.theme-frost .input-group-btn .btn-primary:focus {
  border-left-color: #2d93b6;
  border-right-color: #2d93b6; }
.theme-frost .btn-group .btn-primary:hover,
.theme-frost .btn-group.open .btn-primary.dropdown-toggle,
.theme-frost .input-group-btn .btn-primary:hover,
.theme-frost .input-group-btn.open .btn-primary.dropdown-toggle {
  border-color: #27809e; }
.theme-frost .btn-group .btn-primary:active,
.theme-frost .btn-group .btn-primary.active,
.theme-frost .input-group-btn .btn-primary:active,
.theme-frost .input-group-btn .btn-primary.active {
  border-left-color: #27809e;
  border-right-color: #27809e; }
.theme-frost .pagination > li > a:hover {
  border-color: #3daacf; }
.theme-frost .pagination > li.active > a,
.theme-frost .pagination > li.active > a:hover,
.theme-frost .pagination > li.active > a:focus,
.theme-frost .pagination > li.active > span {
  background: #3daacf;
  border-color: #3daacf; }
.theme-frost .pager li > a:hover {
  border-color: #3daacf; }
.theme-frost a.list-group-item.active {
  background: #3daacf;
  border-color: #3daacf; }
  .theme-frost a.list-group-item.active .list-group-item-text {
    color: #e1f2f7; }
.theme-frost .form-control:focus,
.theme-frost .form-control.focus {
  border-color: #3daacf;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }
.gt-ie8 .theme-frost input.px:focus + .lbl:before, .gt-ie8 .theme-frost input.px + .lbl:hover:before {
  border-color: #3daacf; }
.gt-ie8 .theme-frost input[type="checkbox"].px + .lbl:after {
  color: #3daacf; }
.gt-ie8 .theme-frost input[type="radio"].px + .lbl:after {
  background: #3daacf; }
.theme-frost .text-primary, .theme-frost .text-primary:hover, .theme-frost .text-primary:active, .theme-frost .text-primary:focus,
.theme-frost .text-primary a,
.theme-frost .text-primary a:focus {
  color: #3daacf; }
.theme-frost .text-primary a:hover,
.theme-frost .text-primary a:active {
  color: #267c9a; }
.theme-frost .bg-primary {
  background: #3daacf !important; }
  .theme-frost .bg-primary[href]:hover {
    background: #3aa0c3 !important; }
  .theme-frost .bg-primary.darken {
    background: #3aa0c3 !important; }
    .theme-frost .bg-primary.darken[href]:hover {
      background: #3697b7 !important; }
  .theme-frost .bg-primary.darker {
    background: #3697b7 !important; }
    .theme-frost .bg-primary.darker[href]:hover {
      background: #338dab !important; }
  .theme-frost .bg-primary, .theme-frost .bg-primary:hover, .theme-frost .bg-primary:active, .theme-frost .bg-primary:focus,
  .theme-frost .bg-primary a,
  .theme-frost .bg-primary a:focus {
    color: #fff; }
  .theme-frost .bg-primary a:hover,
  .theme-frost .bg-primary a:active {
    color: #d9d9d9; }
  .theme-frost .bg-primary[href]:hover,
  .theme-frost .bg-primary [href]:hover {
    color: #fff; }
  .theme-frost .bg-primary * {
    border-color: #5ab7d6; }
.theme-frost .ui-slider-handle.ui-state-active:before {
  background: #3daacf; }
.theme-frost .ui-slider-range {
  background: #3daacf; }
.theme-frost .ui-autocomplete > li > a:hover,
.theme-frost .ui-autocompletev > li.active > a {
  background: #3daacf; }
.theme-frost .ui-autocomplete > li > a:hover {
  color: #fff; }
.theme-frost .ui-autocomplete .ui-state-focus {
  background: #3daacf;
  color: #fff; }
.theme-frost .ui-menu .ui-menu-item a:hover,
.theme-frost .ui-menu .ui-menu-item a.ui-state-focus,
.theme-frost .ui-menu .ui-menu-item a.ui-state-active {
  background: #3daacf; }
.theme-frost .ui-progressbar-value {
  border-color: #2f9abe;
  background-color: #3daacf;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px; }
.theme-frost .ui-tabs-nav > .ui-tabs-active > a, .theme-frost .ui-tabs-nav > .ui-tabs-active > a:hover, .theme-frost .ui-tabs-nav > .ui-tabs-active > a:focus {
  color: #fff;
  background: #3daacf;
  border-bottom: 2px solid #309dc2; }
.theme-frost .ui-datepicker .ui-state-active {
  background: #3daacf !important; }
.theme-frost .ui-datepicker-title {
  background: #3daacf; }
.theme-frost .ui-datepicker th {
  background: #4891aa; }
.theme-frost .ui-datepicker-buttonpane button.ui-priority-primary {
  color: #fff; }
  .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary, .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary:focus {
    border-color: #309dc2;
    border-bottom-color: #2a89aa;
    background: #3daacf;
    background-image: -webkit-linear-gradient(top, #41acd0 0%, #309dc2 100%);
    background-image: linear-gradient(to bottom, #41acd0 0%, #309dc2 100%);
    background-repeat: repeat-x; }
  .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary:hover {
    color: #fff;
    border-color: #2d93b6;
    border-bottom-color: #23728d;
    background: #309dc2 !important;
    background-image: -webkit-linear-gradient(top, #35a6cd 0%, #2d93b6 100%) !important;
    background-image: linear-gradient(to bottom, #35a6cd 0%, #2d93b6 100%) !important;
    background-repeat: repeat-x; }
  .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary:active, .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary.active, .open .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
    background: none;
    background: #31a1c6 !important;
    border-color: #27809e;
    border-bottom-color: #27809e !important;
    color: #fff;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset; }
  .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat {
    background: #32a4cb; }
    .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:hover, .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:active, .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.active, .open .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.dropdown-toggle {
      background: #32a4cb !important;
      color: #fff; }
  .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:hover, .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:hover {
    color: #fff;
    border-color: #2d93b6;
    border-bottom-color: #23728d; }
  .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:active, .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:active {
    border-color: #27809e; }
  .btn-group .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary, .btn-group .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary:focus, .input-group-btn .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary, .input-group-btn .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary:focus {
    border-left-color: #2d93b6;
    border-right-color: #2d93b6; }
  .btn-group .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary:hover, .btn-group.open .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle, .input-group-btn .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary:hover, .input-group-btn.open .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
    border-color: #2d93b6;
    border-bottom-color: #23728d; }
  .btn-group .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary:active, .btn-group .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary.active, .input-group-btn .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary:active, .input-group-btn .theme-frost .ui-datepicker-buttonpane button.ui-priority-primary.active {
    border-left-color: #27809e;
    border-right-color: #27809e; }
.theme-frost .select2-drop-active,
.theme-frost .select2-container-multi.select2-container-active .select2-choices,
.theme-frost .select2-container-multi.select2-dropdown-open .select2-choices {
  border: 1px solid #3daacf; }
.theme-frost .select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid #3daacf; }
.theme-frost .select2-container-active .select2-choice,
.theme-frost .select2-container-active .select2-choices {
  border: 1px solid #3daacf; }
.theme-frost .select2-dropdown-open .select2-drop-above .select2-choice,
.theme-frost .select2-dropdown-open .select2-drop-above .select2-choices {
  border: 1px solid #3daacf;
  border-top-color: #aaa; }
.theme-frost .select2-results .select2-highlighted {
  background: #3daacf; }
.theme-frost .select2-primary .select2-container-multi .select2-choices .select2-search-choice {
  background: #3daacf; }
.theme-frost .select2-primary .select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #2b8dae; }
.theme-frost .select2-primary .select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  background: #3daacf !important; }
.theme-frost .switcher-primary .switcher-state-on {
  background: #3daacf !important; }
.theme-frost .pixel-file-input:hover {
  border-color: #3daacf; }
.theme-frost .datepicker table tr td.selected, .theme-frost .datepicker table tr td.selected:hover, .theme-frost .datepicker table tr td.selected.disabled, .theme-frost .datepicker table tr td.selected.disabled:hover {
  background: #3daacf; }
.theme-frost .datepicker table tr td.active, .theme-frost .datepicker table tr td.active:hover, .theme-frost .datepicker table tr td.active.disabled, .theme-frost .datepicker table tr td.active.disabled:hover {
  background: #3daacf; }
.theme-frost .datepicker table tr td span.active, .theme-frost .datepicker table tr td span.active:hover, .theme-frost .datepicker table tr td span.active.disabled, .theme-frost .datepicker table tr td span.active.disabled:hover {
  background: #3daacf; }
.theme-frost .datepicker thead tr {
  background: #4891aa; }
  .theme-frost .datepicker thead tr:first-child {
    background: #3daacf !important;
    border-bottom-color: #487787 !important; }
.theme-frost .datepicker thead th.prev,
.theme-frost .datepicker thead th.next {
  background: #3daacf; }
.theme-frost .datepicker thead th.prev {
  border-color: #487787; }
.theme-frost .datepicker thead th.next {
  border-color: #487787; }
.theme-frost .datepicker > .datepicker-days thead tr:first-child th.cw + .prev {
  border-color: #487787; }
.theme-frost .datepicker > .datepicker-days thead tr:last-child th.cw + th {
  border-color: #487787; }
.theme-frost .bootstrap-timepicker-widget table td a:hover {
  background: #3daacf;
  border-color: #3daacf; }
.theme-frost .pagination a.dp-today,
.theme-frost .pagination a.dp-today:hover {
  background: #66bcd9;
  border-color: #51b3d4; }
.theme-frost .pagination a.dp-today:hover {
  border-color: #3daacf !important; }
.theme-frost .pagination a.dp-selected {
  background: #3daacf !important;
  border-color: #3daacf !important; }
.theme-frost .tt-suggestion.tt-is-under-cursor {
  background: #3daacf; }
.theme-frost .table-primary .dataTables_wrapper .DT-per-page {
  border-color: #397f96 !important; }
.theme-frost .dropzone-box.dz-drag-hover {
  border-color: #3daacf; }
.theme-frost .dropzone-box:hover .fa.fa-cloud-upload {
  background: #3daacf; }
.theme-frost .md-editor.active {
  border-color: #3daacf; }
.theme-frost .widget-rating .active a {
  color: #3daacf; }

.theme-fresh {
  background: #f4f6f7; }
.theme-fresh .page-header {
  background: #fff;
  border-color: #ddd; }
.theme-fresh .table,
.theme-fresh .panel,
.theme-fresh hr {
  border-color: #ddd; }
.theme-fresh .bordered {
  border-color: #ddd; }
.theme-fresh .bordered[class*='bg-'],
.theme-fresh [class*='bg-'] .border-t {
  border-color: #fff;
  border-color: rgba(0, 0, 0, 0.2); }
.theme-fresh .panel.panel-transparent > .panel-heading {
  border-bottom-color: #ddd !important; }
.theme-fresh .breadcrumb-page {
  background: #fff;
  border-bottom: 1px solid #ddd;
  padding-bottom: 6px;
  padding-top: 6px; }
.gt-ie9 .theme-fresh #main-navbar, .gt-ie9 .theme-fresh #main-navbar .navbar-header {
  background-image: -webkit-linear-gradient(top, #a1cc3d 0%, #94c53e 100%);
  background-image: -o-linear-gradient(top, #a1cc3d 0%, #94c53e 100%);
  background-image: linear-gradient(to bottom, #a1cc3d 0%, #94c53e 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFA1CC3D', endColorstr='#FF94C53E', GradientType=0); }
.ie8 .theme-fresh #main-navbar, .ie9 .theme-fresh #main-navbar, .ie8 .theme-fresh #main-navbar .navbar-header, .ie9 .theme-fresh #main-navbar .navbar-header {
  background: #a1cc3d; }
.theme-fresh #main-navbar .dropdown > a,
.theme-fresh #main-navbar .dropdown-menu > li > a,
.theme-fresh #main-navbar .nav > li > a,
.theme-fresh #main-navbar .navbar-header a {
  color: #fff; }
.theme-fresh #main-navbar .navbar-toggle,
.theme-fresh #main-navbar #main-menu-toggle,
.theme-fresh #main-navbar #main-menu-toggle .fa:before,
.theme-fresh #main-navbar a {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2); }
.theme-fresh #main-navbar .navbar-toggle,
.theme-fresh #main-navbar #main-menu-toggle {
  color: #fff; }
.theme-fresh #main-navbar #main-menu-toggle .fa:before {
  color: #fff; }
.theme-fresh #main-navbar #main-navbar-collapse {
  border-top-color: #83b035;
  background: #94c53e; }
.theme-fresh #main-navbar li + li,
.theme-fresh #main-navbar .nav + .nav,
.theme-fresh #main-navbar .nav + .right {
  border-top: 1px solid #a5ce5d; }
.theme-fresh #main-navbar .dropdown.open > a {
  border-top: 1px solid #71982e;
  border-bottom: 1px solid #7aa431;
  margin-top: -1px;
  background: #83b035; }
.theme-fresh #main-navbar form.navbar-form {
  -webkit-box-shadow: none;
  box-shadow: none; }
  .theme-fresh #main-navbar form.navbar-form .form-control {
    background: #fff;
    border-color: #fff;
    border-radius: 3px; }
@media (min-width: 768px) {
  .theme-fresh #main-navbar #main-navbar-collapse {
    background: none; }
  .theme-fresh #main-navbar li + li,
  .theme-fresh #main-navbar .nav + .nav,
  .theme-fresh #main-navbar .nav + .right {
    border: none; }
  .theme-fresh #main-navbar .nav > li > a {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent; }
    .theme-fresh #main-navbar .nav > li > a:hover {
      background: rgba(0, 0, 0, 0.08);
      border-color: #77a030; }
  .theme-fresh #main-navbar .dropdown.open > a {
    border-top: none;
    border-bottom: none;
    margin-top: 0;
    background: rgba(0, 0, 0, 0.08);
    border-color: #77a030; }
  .theme-fresh #main-navbar .dropdown-menu {
    background: #43474b;
    border-color: #43474b;
    margin-top: 2px;
    padding: 5px 0;
    border-radius: 2px; }
    .theme-fresh #main-navbar .dropdown-menu a {
      text-shadow: none;
      margin: 0 -2px; }
    .theme-fresh #main-navbar .dropdown-menu a:hover {
      background: #78bdd1; }
    .theme-fresh #main-navbar .dropdown-menu .divider {
      background-color: #4d5156;
      margin-left: -2px;
      margin-right: -2px; }
  .theme-fresh #main-navbar .widget-notifications a:hover {
    color: #fff !important; }
  .theme-fresh #main-navbar .widget-notifications .notification,
  .theme-fresh #main-navbar .widget-notifications .notifications-link {
    border-color: #4d5156;
    margin: 0 -2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px; }
  .theme-fresh #main-navbar .widget-notifications .notification-description {
    color: #9ba0a6; }
  .theme-fresh #main-navbar .widget-notifications .notification-ago {
    color: #80878e; }
  .theme-fresh #main-navbar .widget-messages-alt a:hover {
    color: #fff !important; }
  .theme-fresh #main-navbar .widget-messages-alt .message,
  .theme-fresh #main-navbar .widget-messages-alt .messages-link {
    border-color: #4d5156;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }
    .theme-fresh #main-navbar .widget-messages-alt .message a:hover,
    .theme-fresh #main-navbar .widget-messages-alt .messages-link a:hover {
      background: none; }
  .theme-fresh #main-navbar .widget-messages-alt .message-subject {
    color: #78bdd1; }
  .theme-fresh #main-navbar .widget-messages-alt .message-description {
    color: #80878e; }
    .theme-fresh #main-navbar .widget-messages-alt .message-description a {
      color: #9ba0a6; } }
.theme-fresh #main-navbar #main-menu-toggle, .theme-fresh.right-to-left.main-menu-right #main-navbar #main-menu-toggle {
  border-left: none;
  border-right: 1px solid #7da832; }
.theme-fresh.right-to-left #main-navbar #main-menu-toggle, .theme-fresh.main-menu-right #main-navbar #main-menu-toggle {
  border-left: 1px solid #7da832;
  border-right: none; }
.theme-fresh #main-menu-bg,
.theme-fresh .mmc-dropdown-open-ul {
  background: #43474b; }
.theme-fresh #main-menu #main-menu-inner {
  padding-top: 10px; }
.theme-fresh #main-menu .navigation li,
.theme-fresh #main-menu .mmc-dropdown-open-ul li {
  border-bottom: 1px solid #4d5156; }
.theme-fresh #main-menu .navigation li a,
.theme-fresh #main-menu .mmc-dropdown-open-ul li a {
  color: #a8adb2;
  font-size: 12px;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-property: color,background;
  -moz-transition-property: color,background;
  -ms-transition-property: color,background;
  -o-transition-property: color,background;
  transition-property: color,background; }
  .theme-fresh #main-menu .navigation li a:hover,
  .theme-fresh #main-menu .mmc-dropdown-open-ul li a:hover {
    background: #4d5156;
    color: #fff; }
.theme-fresh #main-menu .navigation li.active,
.theme-fresh #main-menu .mmc-dropdown-open-ul li.active {
  border-color: #78bdd1; }
  .theme-fresh #main-menu .navigation li.active > a,
  .theme-fresh #main-menu .mmc-dropdown-open-ul li.active > a {
    color: #fff;
    background: #78bdd1;
    border-color: #78bdd1; }
.theme-fresh #main-menu .mm-dropdown ul,
.theme-fresh #main-menu .mmc-dropdown-open-ul {
  background: #393d40; }
  .theme-fresh #main-menu .mm-dropdown ul li,
  .theme-fresh #main-menu .mmc-dropdown-open-ul li {
    border: none; }
    .theme-fresh #main-menu .mm-dropdown ul li a:hover,
    .theme-fresh #main-menu .mmc-dropdown-open-ul li a:hover {
      background: #43474b; }
    .theme-fresh #main-menu .mm-dropdown ul li.active,
    .theme-fresh #main-menu .mmc-dropdown-open-ul li.active {
      border-top: none; }
      .theme-fresh #main-menu .mm-dropdown ul li.active > a,
      .theme-fresh #main-menu .mmc-dropdown-open-ul li.active > a {
        background: #78bdd1; }
  .theme-fresh #main-menu .mm-dropdown ul li + li,
  .theme-fresh #main-menu .mmc-dropdown-open-ul li + li {
    border-top: 1px solid #43474b; }
.theme-fresh #main-menu li.mm-dropdown.active {
  border-bottom-color: #4d5156; }
  .theme-fresh #main-menu li.mm-dropdown.active > a {
    color: #fff !important;
    background: none;
    border-bottom-color: #4d5156; }
    .theme-fresh #main-menu li.mm-dropdown.active > a:hover {
      background: #4d5156; }
.theme-fresh #main-menu .mm-dropdown .mm-dropdown.open > a {
  border-bottom-color: #43474b; }
.theme-fresh #main-menu .mm-dropdown li.mm-dropdown.active {
  border-bottom-color: #43474b;
  border-top: 1px solid #43474b; }
  .theme-fresh #main-menu .mm-dropdown li.mm-dropdown.active > a {
    background: none;
    border-bottom-color: #43474b; }
    .theme-fresh #main-menu .mm-dropdown li.mm-dropdown.active > a:hover {
      background: #43474b; }
.theme-fresh #main-menu .mmc-dropdown-open > a {
  color: #fff !important; }
.theme-fresh #main-menu .mmc-title {
  color: #fff;
  background: #43474b;
  border-bottom: 1px solid #4d5156;
  padding-top: 1px; }
.theme-fresh #main-menu .mmc-dropdown-open-ul.top .mmc-title {
  padding-top: 0;
  padding-bottom: 1px;
  border-bottom: 0;
  border-top: 1px solid #4d5156;
  margin-top: -1px; }
.theme-fresh #main-menu .mmc-dropdown-open-ul.top {
  margin-bottom: 1px !important; }
.theme-fresh #main-menu .menu-content {
  border: none;
  margin-top: -10px; }
  .theme-fresh #main-menu .menu-content .btn-outline {
    border-color: #4d5156;
    color: #a8adb2; }
    .theme-fresh #main-menu .menu-content .btn-outline:hover {
      color: #fff; }
  .theme-fresh #main-menu .menu-content.top {
    border-bottom: 1px solid #4d5156; }
.theme-fresh .mmc-dropdown-open .mm-dropdown.open > a, .theme-fresh.mme #main-menu .mm-dropdown.open > a {
  color: #fff !important; }
.theme-fresh.mme #main-menu .mm-dropdown.open > a {
  border-bottom: 1px solid #4d5156; }
@media (min-width: 768px) {
  .theme-fresh #main-menu .mm-dropdown.open > a {
    color: #fff;
    border-bottom: 1px solid #4d5156; }
  .theme-fresh.mmc #main-menu .navigation > .mm-dropdown.open > a {
    color: #a8adb2;
    border-bottom: none; } }
.theme-fresh .demo-logo {
  background: #64bed8; }
.theme-fresh.page-signin .signin-info, .theme-fresh.page-signup .signup-header {
  background: #94c53e;
  background: rgba(148, 197, 62, 0.8); }
.theme-fresh .timeline:before,
.theme-fresh .timeline:after,
.theme-fresh .tl-header,
.theme-fresh .tl-icon {
  background: #dce3e6; }
.theme-fresh .tl-header,
.theme-fresh .tl-icon {
  box-shadow: 0 0 0 4px #f4f6f7; }
.theme-fresh .tl-entry:before {
  background: #64bed8;
  box-shadow: 0 0 0 3px #f4f6f7; }
.theme-fresh .tl-body:before {
  border-color: transparent #ddd transparent transparent; }
@media (min-width: 768px) {
  .theme-fresh .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent transparent transparent #ddd; }
  .theme-fresh.right-to-left .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent #ddd transparent transparent; } }
.theme-fresh.right-to-left .tl-body:before {
  border-color: transparent transparent transparent #ddd; }
.theme-fresh.page-mail .mail-nav,
.theme-fresh.page-mail .mail-nav:before {
  background: #f4f6f7; }
.theme-fresh.page-mail .mail-nav .sections li.active .label,
.theme-fresh.page-mail .mail-nav .sections li.active .badge {
  background: #64bed8; }
.theme-fresh.page-mail .mail-nav,
.theme-fresh.page-mail .mail-nav .compose-btn,
.theme-fresh.page-mail .mail-nav .sections li.active,
.theme-fresh.page-mail .mail-nav:before {
  border-color: #ddd; }
.theme-fresh.page-mail .mail-nav .navigation.open .sections li.active {
  background: #e8ecee; }
.theme-fresh.page-mail .mail-nav li.divider {
  background: #ddd; }
.theme-fresh.page-mail .mail-nav .sections li.active a {
  color: #38474e; }
.theme-fresh.page-mail .mail-nav .sections li.active:after {
  color: #9baeb7; }
.theme-fresh.page-mail .mail-nav .sections a {
  color: #596f7a; }
.theme-fresh.page-mail .mail-nav-header {
  color: #9baeb7; }
.theme-fresh.page-mail .mail-nav .add-more a {
  color: #9baeb7; }
@media (min-width: 992px) {
  .theme-fresh.page-mail .mail-nav .navigation .sections li.active {
    background: #e8ecee; }
  .theme-fresh.page-mail .mail-nav .sections a:hover {
    color: #38474e; } }
.theme-fresh .nav-icon-btn.nav-icon-btn-primary .label {
  background: #64bed8; }
.theme-fresh .btn-primary {
  color: #fff; }
  .theme-fresh .btn-primary, .theme-fresh .btn-primary:focus {
    border-color: #50b5d3;
    border-bottom-color: #37abcd;
    background: #64bed8;
    background-image: -webkit-linear-gradient(top, #68c0d9 0%, #50b5d3 100%);
    background-image: linear-gradient(to bottom, #68c0d9 0%, #50b5d3 100%);
    background-repeat: repeat-x; }
  .theme-fresh .btn-primary:hover {
    color: #fff;
    border-color: #43b0d0;
    border-bottom-color: #2d95b3;
    background: #50b5d3 !important;
    background-image: -webkit-linear-gradient(top, #5cbbd6 0%, #43b0d0 100%) !important;
    background-image: linear-gradient(to bottom, #5cbbd6 0%, #43b0d0 100%) !important;
    background-repeat: repeat-x; }
  .theme-fresh .btn-primary:active, .theme-fresh .btn-primary.active, .open .theme-fresh .btn-primary.dropdown-toggle {
    background: none;
    background: #54b7d4 !important;
    border-color: #31a3c3;
    border-bottom-color: #31a3c3 !important;
    color: #fff;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset; }
  .theme-fresh .btn-primary.btn-flat {
    background: #58b9d5; }
    .theme-fresh .btn-primary.btn-flat:hover, .theme-fresh .btn-primary.btn-flat:active, .theme-fresh .btn-primary.btn-flat.active, .open .theme-fresh .btn-primary.btn-flat.dropdown-toggle {
      background: #58b9d5 !important;
      color: #fff; }
  .theme-fresh .btn-primary.btn-outline:hover, .theme-fresh .btn-primary.btn-outline.btn-flat:hover {
    color: #fff;
    border-color: #43b0d0;
    border-bottom-color: #2d95b3; }
  .theme-fresh .btn-primary.btn-outline:active, .theme-fresh .btn-primary.btn-outline.btn-flat:active {
    border-color: #31a3c3; }
  .btn-group .theme-fresh .btn-primary, .btn-group .theme-fresh .btn-primary:focus, .input-group-btn .theme-fresh .btn-primary, .input-group-btn .theme-fresh .btn-primary:focus {
    border-left-color: #43b0d0;
    border-right-color: #43b0d0; }
  .btn-group .theme-fresh .btn-primary:hover, .btn-group.open .theme-fresh .btn-primary.dropdown-toggle, .input-group-btn .theme-fresh .btn-primary:hover, .input-group-btn.open .theme-fresh .btn-primary.dropdown-toggle {
    border-color: #43b0d0;
    border-bottom-color: #2d95b3; }
  .btn-group .theme-fresh .btn-primary:active, .btn-group .theme-fresh .btn-primary.active, .input-group-btn .theme-fresh .btn-primary:active, .input-group-btn .theme-fresh .btn-primary.active {
    border-left-color: #31a3c3;
    border-right-color: #31a3c3; }
.theme-fresh .open .btn.btn-primary.dropdown-toggle {
  color: #fff;
  border-bottom-color: #31a3c3 !important;
  background: none;
  background: #54b7d4 !important; }
.theme-fresh .progress-bar {
  background: #64bed8;
  border-color: #4cb4d2; }
.theme-fresh .progress-striped .progress-bar {
  background-color: #64bed8;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px; }
.theme-fresh .label.label-primary {
  background: #64bed8; }
  .theme-fresh .label.label-primary[href]:hover, .theme-fresh .label.label-primary[href]:focus {
    background-color: #3badce; }
  .theme-fresh .label.label-primary.label-tag {
    border: 1px solid #64bed8; }
    .theme-fresh .label.label-primary.label-tag:before {
      border-color: transparent #64bed8 transparent transparent; }
    .theme-fresh .label.label-primary.label-tag[href]:hover, .theme-fresh .label.label-primary.label-tag[href]:focus {
      border-color: #3badce; }
    .theme-fresh .label.label-primary.label-tag[href]:hover:before, .theme-fresh .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent #3badce transparent transparent; }
    .right-to-left .theme-fresh .label.label-primary.label-tag:before {
      border-color: transparent transparent transparent #64bed8; }
    .right-to-left .theme-fresh .label.label-primary.label-tag[href]:hover:before, .right-to-left .theme-fresh .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent transparent transparent #3badce; }
    .ie8 .right-to-left .theme-fresh .label.label-primary.label-tag:before, .ie9 .right-to-left .theme-fresh .label.label-primary.label-tag:before {
      border-color: transparent #64bed8 transparent transparent; }
    .ie8 .right-to-left .theme-fresh .label.label-primary.label-tag[href]:hover:before, .ie8 .right-to-left .theme-fresh .label.label-primary.label-tag[href]:focus:before, .ie9 .right-to-left .theme-fresh .label.label-primary.label-tag[href]:hover:before, .ie9 .right-to-left .theme-fresh .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent #3badce transparent transparent; }
.theme-fresh.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent transparent transparent #64bed8; }
.theme-fresh.right-to-left .label.label-primary.label-tag:hover:before, .theme-fresh.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent transparent transparent #3badce; }
.ie8 .theme-fresh.right-to-left .label.label-primary.label-tag:before, .ie9 .theme-fresh.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent #64bed8 transparent transparent; }
.ie8 .theme-fresh.right-to-left .label.label-primary.label-tag:hover:before, .ie8 .theme-fresh.right-to-left .label.label-primary.label-tag:focus:before, .ie9 .theme-fresh.right-to-left .label.label-primary.label-tag:hover:before, .ie9 .theme-fresh.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent #3badce transparent transparent; }
.theme-fresh .badge.badge-primary {
  background: #64bed8;
  border: 1px solid #64bed8; }
  .theme-fresh .badge.badge-primary[href]:hover, .theme-fresh .badge.badge-primary[href]:focus {
    background-color: #3badce;
    border-color: #3badce; }
.theme-fresh .table-primary table {
  border-top-color: #469fb8 !important; }
.theme-fresh .table-primary thead,
.theme-fresh .table-primary thead tr,
.theme-fresh .table-primary thead th,
.theme-fresh .table-primary .table-header {
  border-color: #469fb8 !important;
  color: #fff; }
.theme-fresh .table-primary thead tr,
.theme-fresh .table-primary thead th {
  background: #55afc9; }
.theme-fresh .table-primary .table-header {
  background: #64bed8; }
.theme-fresh .nav-tabs > li.active > a, .theme-fresh .nav-tabs > li.active > a:hover, .theme-fresh .nav-tabs > li.active > a:focus {
  background: #64bed8;
  border-bottom: 2px solid #50b5d3; }
.theme-fresh .nav-tabs .nav-tabs.nav-justified > .active > a {
  border-bottom-color: #50b5d3; }
.theme-fresh .nav-pills > li.active > a, .theme-fresh .nav-pills > li.active > a:hover, .theme-fresh .nav-pills > li.active > a:focus {
  background: #64bed8; }
.theme-fresh .dropdown-menu > li > a:hover,
.theme-fresh .dropdown-menu > li.active > a {
  background: #64bed8; }
.theme-fresh .dropdown-menu > li > a:hover {
  color: #fff; }
.theme-fresh .btn-group .btn-primary,
.theme-fresh .btn-group .btn-primary:focus,
.theme-fresh .input-group-btn .btn-primary,
.theme-fresh .input-group-btn .btn-primary:focus {
  border-left-color: #43b0d0;
  border-right-color: #43b0d0; }
.theme-fresh .btn-group .btn-primary:hover,
.theme-fresh .btn-group.open .btn-primary.dropdown-toggle,
.theme-fresh .input-group-btn .btn-primary:hover,
.theme-fresh .input-group-btn.open .btn-primary.dropdown-toggle {
  border-color: #31a3c3; }
.theme-fresh .btn-group .btn-primary:active,
.theme-fresh .btn-group .btn-primary.active,
.theme-fresh .input-group-btn .btn-primary:active,
.theme-fresh .input-group-btn .btn-primary.active {
  border-left-color: #31a3c3;
  border-right-color: #31a3c3; }
.theme-fresh .pagination > li > a:hover {
  border-color: #64bed8; }
.theme-fresh .pagination > li.active > a,
.theme-fresh .pagination > li.active > a:hover,
.theme-fresh .pagination > li.active > a:focus,
.theme-fresh .pagination > li.active > span {
  background: #64bed8;
  border-color: #64bed8; }
.theme-fresh .pager li > a:hover {
  border-color: #64bed8; }
.theme-fresh a.list-group-item.active {
  background: #64bed8;
  border-color: #64bed8; }
  .theme-fresh a.list-group-item.active .list-group-item-text {
    color: white; }
.theme-fresh .form-control:focus,
.theme-fresh .form-control.focus {
  border-color: #64bed8;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }
.gt-ie8 .theme-fresh input.px:focus + .lbl:before, .gt-ie8 .theme-fresh input.px + .lbl:hover:before {
  border-color: #64bed8; }
.gt-ie8 .theme-fresh input[type="checkbox"].px + .lbl:after {
  color: #64bed8; }
.gt-ie8 .theme-fresh input[type="radio"].px + .lbl:after {
  background: #64bed8; }
.theme-fresh .text-primary, .theme-fresh .text-primary:hover, .theme-fresh .text-primary:active, .theme-fresh .text-primary:focus,
.theme-fresh .text-primary a,
.theme-fresh .text-primary a:focus {
  color: #64bed8; }
.theme-fresh .text-primary a:hover,
.theme-fresh .text-primary a:active {
  color: #309fbf; }
.theme-fresh .bg-primary {
  background: #64bed8 !important; }
  .theme-fresh .bg-primary[href]:hover {
    background: #5eb5cf !important; }
  .theme-fresh .bg-primary.darken {
    background: #5eb5cf !important; }
    .theme-fresh .bg-primary.darken[href]:hover {
      background: #52b0cb !important; }
  .theme-fresh .bg-primary.darker {
    background: #52b0cb !important; }
    .theme-fresh .bg-primary.darker[href]:hover {
      background: #47abc8 !important; }
  .theme-fresh .bg-primary, .theme-fresh .bg-primary:hover, .theme-fresh .bg-primary:active, .theme-fresh .bg-primary:focus,
  .theme-fresh .bg-primary a,
  .theme-fresh .bg-primary a:focus {
    color: #fff; }
  .theme-fresh .bg-primary a:hover,
  .theme-fresh .bg-primary a:active {
    color: #d9d9d9; }
  .theme-fresh .bg-primary[href]:hover,
  .theme-fresh .bg-primary [href]:hover {
    color: #fff; }
  .theme-fresh .bg-primary * {
    border-color: #81cadf; }
.theme-fresh .ui-slider-handle.ui-state-active:before {
  background: #64bed8; }
.theme-fresh .ui-slider-range {
  background: #64bed8; }
.theme-fresh .ui-autocomplete > li > a:hover,
.theme-fresh .ui-autocompletev > li.active > a {
  background: #64bed8; }
.theme-fresh .ui-autocomplete > li > a:hover {
  color: #fff; }
.theme-fresh .ui-autocomplete .ui-state-focus {
  background: #64bed8;
  color: #fff; }
.theme-fresh .ui-menu .ui-menu-item a:hover,
.theme-fresh .ui-menu .ui-menu-item a.ui-state-focus,
.theme-fresh .ui-menu .ui-menu-item a.ui-state-active {
  background: #64bed8; }
.theme-fresh .ui-progressbar-value {
  border-color: #4cb4d2;
  background-color: #64bed8;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px; }
.theme-fresh .ui-tabs-nav > .ui-tabs-active > a, .theme-fresh .ui-tabs-nav > .ui-tabs-active > a:hover, .theme-fresh .ui-tabs-nav > .ui-tabs-active > a:focus {
  color: #fff;
  background: #64bed8;
  border-bottom: 2px solid #50b5d3; }
.theme-fresh .ui-datepicker .ui-state-active {
  background: #64bed8 !important; }
.theme-fresh .ui-datepicker-title {
  background: #64bed8; }
.theme-fresh .ui-datepicker th {
  background: #66a9bd; }
.theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary {
  color: #fff; }
  .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary, .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary:focus {
    border-color: #50b5d3;
    border-bottom-color: #37abcd;
    background: #64bed8;
    background-image: -webkit-linear-gradient(top, #68c0d9 0%, #50b5d3 100%);
    background-image: linear-gradient(to bottom, #68c0d9 0%, #50b5d3 100%);
    background-repeat: repeat-x; }
  .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary:hover {
    color: #fff;
    border-color: #43b0d0;
    border-bottom-color: #2d95b3;
    background: #50b5d3 !important;
    background-image: -webkit-linear-gradient(top, #5cbbd6 0%, #43b0d0 100%) !important;
    background-image: linear-gradient(to bottom, #5cbbd6 0%, #43b0d0 100%) !important;
    background-repeat: repeat-x; }
  .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary:active, .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary.active, .open .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
    background: none;
    background: #54b7d4 !important;
    border-color: #31a3c3;
    border-bottom-color: #31a3c3 !important;
    color: #fff;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset; }
  .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat {
    background: #58b9d5; }
    .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:hover, .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:active, .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.active, .open .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.dropdown-toggle {
      background: #58b9d5 !important;
      color: #fff; }
  .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:hover, .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:hover {
    color: #fff;
    border-color: #43b0d0;
    border-bottom-color: #2d95b3; }
  .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:active, .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:active {
    border-color: #31a3c3; }
  .btn-group .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary, .btn-group .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary:focus, .input-group-btn .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary, .input-group-btn .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary:focus {
    border-left-color: #43b0d0;
    border-right-color: #43b0d0; }
  .btn-group .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary:hover, .btn-group.open .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle, .input-group-btn .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary:hover, .input-group-btn.open .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
    border-color: #43b0d0;
    border-bottom-color: #2d95b3; }
  .btn-group .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary:active, .btn-group .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary.active, .input-group-btn .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary:active, .input-group-btn .theme-fresh .ui-datepicker-buttonpane button.ui-priority-primary.active {
    border-left-color: #31a3c3;
    border-right-color: #31a3c3; }
.theme-fresh .select2-drop-active,
.theme-fresh .select2-container-multi.select2-container-active .select2-choices,
.theme-fresh .select2-container-multi.select2-dropdown-open .select2-choices {
  border: 1px solid #64bed8; }
.theme-fresh .select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid #64bed8; }
.theme-fresh .select2-container-active .select2-choice,
.theme-fresh .select2-container-active .select2-choices {
  border: 1px solid #64bed8; }
.theme-fresh .select2-dropdown-open .select2-drop-above .select2-choice,
.theme-fresh .select2-dropdown-open .select2-drop-above .select2-choices {
  border: 1px solid #64bed8;
  border-top-color: #aaa; }
.theme-fresh .select2-results .select2-highlighted {
  background: #64bed8; }
.theme-fresh .select2-primary .select2-container-multi .select2-choices .select2-search-choice {
  background: #64bed8; }
.theme-fresh .select2-primary .select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #3badce; }
.theme-fresh .select2-primary .select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  background: #64bed8 !important; }
.theme-fresh .switcher-primary .switcher-state-on {
  background: #64bed8 !important; }
.theme-fresh .pixel-file-input:hover {
  border-color: #64bed8; }
.theme-fresh .datepicker table tr td.selected, .theme-fresh .datepicker table tr td.selected:hover, .theme-fresh .datepicker table tr td.selected.disabled, .theme-fresh .datepicker table tr td.selected.disabled:hover {
  background: #64bed8; }
.theme-fresh .datepicker table tr td.active, .theme-fresh .datepicker table tr td.active:hover, .theme-fresh .datepicker table tr td.active.disabled, .theme-fresh .datepicker table tr td.active.disabled:hover {
  background: #64bed8; }
.theme-fresh .datepicker table tr td span.active, .theme-fresh .datepicker table tr td span.active:hover, .theme-fresh .datepicker table tr td span.active.disabled, .theme-fresh .datepicker table tr td span.active.disabled:hover {
  background: #64bed8; }
.theme-fresh .datepicker thead tr {
  background: #66a9bd; }
  .theme-fresh .datepicker thead tr:first-child {
    background: #64bed8 !important;
    border-bottom-color: #5994a5 !important; }
.theme-fresh .datepicker thead th.prev,
.theme-fresh .datepicker thead th.next {
  background: #64bed8; }
.theme-fresh .datepicker thead th.prev {
  border-color: #5994a5; }
.theme-fresh .datepicker thead th.next {
  border-color: #5994a5; }
.theme-fresh .datepicker > .datepicker-days thead tr:first-child th.cw + .prev {
  border-color: #5994a5; }
.theme-fresh .datepicker > .datepicker-days thead tr:last-child th.cw + th {
  border-color: #5994a5; }
.theme-fresh .bootstrap-timepicker-widget table td a:hover {
  background: #64bed8;
  border-color: #64bed8; }
.theme-fresh .pagination a.dp-today,
.theme-fresh .pagination a.dp-today:hover {
  background: #8dcfe2;
  border-color: #78c7dd; }
.theme-fresh .pagination a.dp-today:hover {
  border-color: #64bed8 !important; }
.theme-fresh .pagination a.dp-selected {
  background: #64bed8 !important;
  border-color: #64bed8 !important; }
.theme-fresh .tt-suggestion.tt-is-under-cursor {
  background: #64bed8; }
.theme-fresh .table-primary .dataTables_wrapper .DT-per-page {
  border-color: #469fb8 !important; }
.theme-fresh .dropzone-box.dz-drag-hover {
  border-color: #64bed8; }
.theme-fresh .dropzone-box:hover .fa.fa-cloud-upload {
  background: #64bed8; }
.theme-fresh .md-editor.active {
  border-color: #64bed8; }
.theme-fresh .widget-rating .active a {
  color: #64bed8; }

.theme-silver #main-navbar {
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08), 0 1px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08), 0 1px 0 rgba(0, 0, 0, 0.08); }
.theme-silver {
  background: #fafafa; }
.theme-silver .page-header {
  background: #fafafa;
  border-color: #eee; }
.theme-silver .table,
.theme-silver .panel,
.theme-silver hr {
  border-color: #e4e4e4; }
.theme-silver .bordered {
  border-color: #e4e4e4; }
.theme-silver .bordered[class*='bg-'],
.theme-silver [class*='bg-'] .border-t {
  border-color: #fff;
  border-color: rgba(0, 0, 0, 0.2); }
.theme-silver .panel.panel-transparent > .panel-heading {
  border-bottom-color: #e4e4e4 !important; }
.theme-silver .breadcrumb-page {
  background: #fafafa;
  border-bottom: 1px solid #eee;
  padding-bottom: 6px;
  padding-top: 6px; }
.gt-ie9 .theme-silver #main-navbar, .gt-ie9 .theme-silver #main-navbar .navbar-header {
  background-image: -webkit-linear-gradient(top, #f6f6f6 0%, #ebebeb 100%);
  background-image: -o-linear-gradient(top, #f6f6f6 0%, #ebebeb 100%);
  background-image: linear-gradient(to bottom, #f6f6f6 0%, #ebebeb 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFF6F6F6', endColorstr='#FFEBEBEB', GradientType=0); }
.ie8 .theme-silver #main-navbar, .ie9 .theme-silver #main-navbar, .ie8 .theme-silver #main-navbar .navbar-header, .ie9 .theme-silver #main-navbar .navbar-header {
  background: #f6f6f6; }
.theme-silver #main-navbar .dropdown > a,
.theme-silver #main-navbar .dropdown-menu > li > a,
.theme-silver #main-navbar .nav > li > a,
.theme-silver #main-navbar .navbar-header a {
  color: #666; }
.theme-silver #main-navbar .navbar-toggle,
.theme-silver #main-navbar #main-menu-toggle,
.theme-silver #main-navbar #main-menu-toggle .fa:before,
.theme-silver #main-navbar a {
  text-shadow: none; }
.theme-silver #main-navbar .navbar-toggle,
.theme-silver #main-navbar #main-menu-toggle {
  color: #666; }
.theme-silver #main-navbar #main-menu-toggle .fa:before {
  color: #666; }
.theme-silver #main-navbar #main-navbar-collapse {
  border-top-color: gainsboro;
  background: #ebebeb; }
.theme-silver #main-navbar li + li,
.theme-silver #main-navbar .nav + .nav,
.theme-silver #main-navbar .nav + .right {
  border-top: 1px solid gainsboro; }
.theme-silver #main-navbar .dropdown.open > a {
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #d4d4d4;
  margin-top: -1px;
  background: gainsboro; }
.theme-silver #main-navbar form.navbar-form {
  -webkit-box-shadow: none;
  box-shadow: none; }
  .theme-silver #main-navbar form.navbar-form .form-control {
    background: #fff;
    border-color: #fff;
    border-radius: 3px; }
@media (min-width: 768px) {
  .theme-silver #main-navbar #main-navbar-collapse {
    background: none; }
  .theme-silver #main-navbar li + li,
  .theme-silver #main-navbar .nav + .nav,
  .theme-silver #main-navbar .nav + .right {
    border: none; }
  .theme-silver #main-navbar .nav > li > a {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent; }
    .theme-silver #main-navbar .nav > li > a:hover {
      background: rgba(0, 0, 0, 0.08);
      border-color: #d2d2d2; }
  .theme-silver #main-navbar .dropdown.open > a {
    border-top: none;
    border-bottom: none;
    margin-top: 0;
    background: rgba(0, 0, 0, 0.08);
    border-color: #d2d2d2; }
  .theme-silver #main-navbar .dropdown-menu {
    background: #333;
    border-color: #333;
    margin-top: 2px;
    padding: 5px 0;
    border-radius: 2px; }
    .theme-silver #main-navbar .dropdown-menu a {
      text-shadow: none;
      margin: 0 -2px; }
    .theme-silver #main-navbar .dropdown-menu > li > a {
      color: #fff; }
    .theme-silver #main-navbar .dropdown-menu a:hover {
      background: #64bed8; }
    .theme-silver #main-navbar .dropdown-menu .divider {
      background-color: #3d3d3d;
      margin-left: -2px;
      margin-right: -2px; }
  .theme-silver #main-navbar .widget-notifications a:hover {
    color: #fff !important; }
  .theme-silver #main-navbar .widget-notifications .notification,
  .theme-silver #main-navbar .widget-notifications .notifications-link {
    border-color: #3d3d3d;
    margin: 0 -2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px; }
  .theme-silver #main-navbar .widget-notifications .notification-description {
    color: #8c8c8c; }
  .theme-silver #main-navbar .widget-notifications .notification-ago {
    color: #737373; }
  .theme-silver #main-navbar .widget-messages-alt a:hover {
    color: #fff !important; }
  .theme-silver #main-navbar .widget-messages-alt .message,
  .theme-silver #main-navbar .widget-messages-alt .messages-link {
    border-color: #3d3d3d;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }
    .theme-silver #main-navbar .widget-messages-alt .message a:hover,
    .theme-silver #main-navbar .widget-messages-alt .messages-link a:hover {
      background: none; }
  .theme-silver #main-navbar .widget-messages-alt .message-subject {
    color: #64bed8; }
  .theme-silver #main-navbar .widget-messages-alt .message-description {
    color: #737373; }
    .theme-silver #main-navbar .widget-messages-alt .message-description a {
      color: #8c8c8c; } }
.theme-silver #main-navbar #main-menu-toggle, .theme-silver.right-to-left.main-menu-right #main-navbar #main-menu-toggle {
  border-left: none;
  border-right: 1px solid #d7d7d7; }
.theme-silver.right-to-left #main-navbar #main-menu-toggle, .theme-silver.main-menu-right #main-navbar #main-menu-toggle {
  border-left: 1px solid #d7d7d7;
  border-right: none; }
.theme-silver #main-menu-bg,
.theme-silver .mmc-dropdown-open-ul {
  background: #333; }
.theme-silver #main-menu .navigation li,
.theme-silver #main-menu .mmc-dropdown-open-ul li {
  border-bottom: 1px solid #3d3d3d; }
.theme-silver #main-menu .navigation li a,
.theme-silver #main-menu .mmc-dropdown-open-ul li a {
  color: #999999;
  font-size: 12px;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-property: color,background;
  -moz-transition-property: color,background;
  -ms-transition-property: color,background;
  -o-transition-property: color,background;
  transition-property: color,background; }
  .theme-silver #main-menu .navigation li a:hover,
  .theme-silver #main-menu .mmc-dropdown-open-ul li a:hover {
    background: #3d3d3d;
    color: #fff; }
.theme-silver #main-menu .navigation li.active,
.theme-silver #main-menu .mmc-dropdown-open-ul li.active {
  border-color: #64bed8; }
  .theme-silver #main-menu .navigation li.active > a,
  .theme-silver #main-menu .mmc-dropdown-open-ul li.active > a {
    color: #fff;
    background: #64bed8;
    border-color: #64bed8; }
.theme-silver #main-menu .mm-dropdown ul,
.theme-silver #main-menu .mmc-dropdown-open-ul {
  background: #292929; }
  .theme-silver #main-menu .mm-dropdown ul li,
  .theme-silver #main-menu .mmc-dropdown-open-ul li {
    border: none; }
    .theme-silver #main-menu .mm-dropdown ul li a:hover,
    .theme-silver #main-menu .mmc-dropdown-open-ul li a:hover {
      background: #333; }
    .theme-silver #main-menu .mm-dropdown ul li.active,
    .theme-silver #main-menu .mmc-dropdown-open-ul li.active {
      border-top: none; }
      .theme-silver #main-menu .mm-dropdown ul li.active > a,
      .theme-silver #main-menu .mmc-dropdown-open-ul li.active > a {
        background: #64bed8; }
  .theme-silver #main-menu .mm-dropdown ul li + li,
  .theme-silver #main-menu .mmc-dropdown-open-ul li + li {
    border-top: 1px solid #333; }
.theme-silver #main-menu li.mm-dropdown.active {
  border-bottom-color: #3d3d3d; }
  .theme-silver #main-menu li.mm-dropdown.active > a {
    color: #fff !important;
    background: none;
    border-bottom-color: #3d3d3d; }
    .theme-silver #main-menu li.mm-dropdown.active > a:hover {
      background: #3d3d3d; }
.theme-silver #main-menu .mm-dropdown .mm-dropdown.open > a {
  border-bottom-color: #333; }
.theme-silver #main-menu .mm-dropdown li.mm-dropdown.active {
  border-bottom-color: #333;
  border-top: 1px solid #333; }
  .theme-silver #main-menu .mm-dropdown li.mm-dropdown.active > a {
    background: none;
    border-bottom-color: #333; }
    .theme-silver #main-menu .mm-dropdown li.mm-dropdown.active > a:hover {
      background: #333; }
.theme-silver #main-menu .mmc-dropdown-open > a {
  color: #fff !important; }
.theme-silver #main-menu .mmc-title {
  color: #fff;
  background: #333;
  border-bottom: 1px solid #3d3d3d;
  padding-top: 1px; }
.theme-silver #main-menu .mmc-dropdown-open-ul.top .mmc-title {
  padding-top: 0;
  padding-bottom: 1px;
  border-bottom: 0;
  border-top: 1px solid #3d3d3d;
  margin-top: -1px; }
.theme-silver #main-menu .mmc-dropdown-open-ul.top {
  margin-bottom: 1px !important; }
.theme-silver #main-menu .menu-content {
  border: none;
  margin-top: -10px; }
  .theme-silver #main-menu .menu-content .btn-outline {
    border-color: #3d3d3d;
    color: #999999; }
    .theme-silver #main-menu .menu-content .btn-outline:hover {
      color: #fff; }
  .theme-silver #main-menu .menu-content.top {
    border-bottom: 1px solid #3d3d3d;
    margin-top: 0; }
.theme-silver .mmc-dropdown-open .mm-dropdown.open > a, .theme-silver.mme #main-menu .mm-dropdown.open > a {
  color: #fff !important; }
.theme-silver.mme #main-menu .mm-dropdown.open > a {
  border-bottom: 1px solid #3d3d3d; }
@media (min-width: 768px) {
  .theme-silver #main-menu .mm-dropdown.open > a {
    color: #fff;
    border-bottom: 1px solid #3d3d3d; }
  .theme-silver.mmc #main-menu .navigation > .mm-dropdown.open > a {
    color: #999999;
    border-bottom: none; } }
.theme-silver .demo-logo,
.theme-silver #main-navbar .navbar-brand div {
  background: #64bed8; }
.theme-silver #main-navbar .navbar-header a {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600; }
.theme-silver.page-signin .signin-info, .theme-silver.page-signup .signup-header {
  background: #64bed8;
  background: rgba(100, 190, 216, 0.8); }
.theme-silver .timeline:before,
.theme-silver .timeline:after,
.theme-silver .tl-header,
.theme-silver .tl-icon {
  background: #e6e6e6; }
.theme-silver .tl-header,
.theme-silver .tl-icon {
  box-shadow: 0 0 0 4px #fafafa; }
.theme-silver .tl-entry:before {
  background: #64bed8;
  box-shadow: 0 0 0 3px #fafafa; }
.theme-silver .tl-body:before {
  border-color: transparent #e4e4e4 transparent transparent; }
@media (min-width: 768px) {
  .theme-silver .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent transparent transparent #e4e4e4; }
  .theme-silver.right-to-left .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent #e4e4e4 transparent transparent; } }
.theme-silver.right-to-left .tl-body:before {
  border-color: transparent transparent transparent #e4e4e4; }
.theme-silver.page-mail .mail-nav,
.theme-silver.page-mail .mail-nav:before {
  background: #fafafa; }
.theme-silver.page-mail .mail-nav .sections li.active .label,
.theme-silver.page-mail .mail-nav .sections li.active .badge {
  background: #64bed8; }
.theme-silver.page-mail .mail-nav,
.theme-silver.page-mail .mail-nav .compose-btn,
.theme-silver.page-mail .mail-nav .sections li.active,
.theme-silver.page-mail .mail-nav:before {
  border-color: #e4e4e4; }
.theme-silver.page-mail .mail-nav .navigation.open .sections li.active {
  background: #f0f0f0; }
.theme-silver.page-mail .mail-nav li.divider {
  background: #e4e4e4; }
.theme-silver.page-mail .mail-nav .sections li.active a {
  color: #484848; }
.theme-silver.page-mail .mail-nav .sections li.active:after {
  color: #aeaeae; }
.theme-silver.page-mail .mail-nav .sections a {
  color: #6e6e6e; }
.theme-silver.page-mail .mail-nav-header {
  color: #aeaeae; }
.theme-silver.page-mail .mail-nav .add-more a {
  color: #aeaeae; }
@media (min-width: 992px) {
  .theme-silver.page-mail .mail-nav .navigation .sections li.active {
    background: #f0f0f0; }
  .theme-silver.page-mail .mail-nav .sections a:hover {
    color: #484848; } }
.theme-silver .nav-icon-btn.nav-icon-btn-primary .label {
  background: #64bed8; }
.theme-silver .btn-primary {
  color: #fff; }
  .theme-silver .btn-primary, .theme-silver .btn-primary:focus {
    border-color: #50b5d3;
    border-bottom-color: #37abcd;
    background: #64bed8;
    background-image: -webkit-linear-gradient(top, #68c0d9 0%, #50b5d3 100%);
    background-image: linear-gradient(to bottom, #68c0d9 0%, #50b5d3 100%);
    background-repeat: repeat-x; }
  .theme-silver .btn-primary:hover {
    color: #fff;
    border-color: #43b0d0;
    border-bottom-color: #2d95b3;
    background: #50b5d3 !important;
    background-image: -webkit-linear-gradient(top, #5cbbd6 0%, #43b0d0 100%) !important;
    background-image: linear-gradient(to bottom, #5cbbd6 0%, #43b0d0 100%) !important;
    background-repeat: repeat-x; }
  .theme-silver .btn-primary:active, .theme-silver .btn-primary.active, .open .theme-silver .btn-primary.dropdown-toggle {
    background: none;
    background: #54b7d4 !important;
    border-color: #31a3c3;
    border-bottom-color: #31a3c3 !important;
    color: #fff;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset; }
  .theme-silver .btn-primary.btn-flat {
    background: #58b9d5; }
    .theme-silver .btn-primary.btn-flat:hover, .theme-silver .btn-primary.btn-flat:active, .theme-silver .btn-primary.btn-flat.active, .open .theme-silver .btn-primary.btn-flat.dropdown-toggle {
      background: #58b9d5 !important;
      color: #fff; }
  .theme-silver .btn-primary.btn-outline:hover, .theme-silver .btn-primary.btn-outline.btn-flat:hover {
    color: #fff;
    border-color: #43b0d0;
    border-bottom-color: #2d95b3; }
  .theme-silver .btn-primary.btn-outline:active, .theme-silver .btn-primary.btn-outline.btn-flat:active {
    border-color: #31a3c3; }
  .btn-group .theme-silver .btn-primary, .btn-group .theme-silver .btn-primary:focus, .input-group-btn .theme-silver .btn-primary, .input-group-btn .theme-silver .btn-primary:focus {
    border-left-color: #43b0d0;
    border-right-color: #43b0d0; }
  .btn-group .theme-silver .btn-primary:hover, .btn-group.open .theme-silver .btn-primary.dropdown-toggle, .input-group-btn .theme-silver .btn-primary:hover, .input-group-btn.open .theme-silver .btn-primary.dropdown-toggle {
    border-color: #43b0d0;
    border-bottom-color: #2d95b3; }
  .btn-group .theme-silver .btn-primary:active, .btn-group .theme-silver .btn-primary.active, .input-group-btn .theme-silver .btn-primary:active, .input-group-btn .theme-silver .btn-primary.active {
    border-left-color: #31a3c3;
    border-right-color: #31a3c3; }
.theme-silver .open .btn.btn-primary.dropdown-toggle {
  color: #fff;
  border-bottom-color: #31a3c3 !important;
  background: none;
  background: #54b7d4 !important; }
.theme-silver .progress-bar {
  background: #64bed8;
  border-color: #4cb4d2; }
.theme-silver .progress-striped .progress-bar {
  background-color: #64bed8;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px; }
.theme-silver .label.label-primary {
  background: #64bed8; }
  .theme-silver .label.label-primary[href]:hover, .theme-silver .label.label-primary[href]:focus {
    background-color: #3badce; }
  .theme-silver .label.label-primary.label-tag {
    border: 1px solid #64bed8; }
    .theme-silver .label.label-primary.label-tag:before {
      border-color: transparent #64bed8 transparent transparent; }
    .theme-silver .label.label-primary.label-tag[href]:hover, .theme-silver .label.label-primary.label-tag[href]:focus {
      border-color: #3badce; }
    .theme-silver .label.label-primary.label-tag[href]:hover:before, .theme-silver .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent #3badce transparent transparent; }
    .right-to-left .theme-silver .label.label-primary.label-tag:before {
      border-color: transparent transparent transparent #64bed8; }
    .right-to-left .theme-silver .label.label-primary.label-tag[href]:hover:before, .right-to-left .theme-silver .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent transparent transparent #3badce; }
    .ie8 .right-to-left .theme-silver .label.label-primary.label-tag:before, .ie9 .right-to-left .theme-silver .label.label-primary.label-tag:before {
      border-color: transparent #64bed8 transparent transparent; }
    .ie8 .right-to-left .theme-silver .label.label-primary.label-tag[href]:hover:before, .ie8 .right-to-left .theme-silver .label.label-primary.label-tag[href]:focus:before, .ie9 .right-to-left .theme-silver .label.label-primary.label-tag[href]:hover:before, .ie9 .right-to-left .theme-silver .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent #3badce transparent transparent; }
.theme-silver.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent transparent transparent #64bed8; }
.theme-silver.right-to-left .label.label-primary.label-tag:hover:before, .theme-silver.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent transparent transparent #3badce; }
.ie8 .theme-silver.right-to-left .label.label-primary.label-tag:before, .ie9 .theme-silver.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent #64bed8 transparent transparent; }
.ie8 .theme-silver.right-to-left .label.label-primary.label-tag:hover:before, .ie8 .theme-silver.right-to-left .label.label-primary.label-tag:focus:before, .ie9 .theme-silver.right-to-left .label.label-primary.label-tag:hover:before, .ie9 .theme-silver.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent #3badce transparent transparent; }
.theme-silver .badge.badge-primary {
  background: #64bed8;
  border: 1px solid #64bed8; }
  .theme-silver .badge.badge-primary[href]:hover, .theme-silver .badge.badge-primary[href]:focus {
    background-color: #3badce;
    border-color: #3badce; }
.theme-silver .table-primary table {
  border-top-color: #469fb8 !important; }
.theme-silver .table-primary thead,
.theme-silver .table-primary thead tr,
.theme-silver .table-primary thead th,
.theme-silver .table-primary .table-header {
  border-color: #469fb8 !important;
  color: #fff; }
.theme-silver .table-primary thead tr,
.theme-silver .table-primary thead th {
  background: #55afc9; }
.theme-silver .table-primary .table-header {
  background: #64bed8; }
.theme-silver .nav-tabs > li.active > a, .theme-silver .nav-tabs > li.active > a:hover, .theme-silver .nav-tabs > li.active > a:focus {
  background: #64bed8;
  border-bottom: 2px solid #50b5d3; }
.theme-silver .nav-tabs .nav-tabs.nav-justified > .active > a {
  border-bottom-color: #50b5d3; }
.theme-silver .nav-pills > li.active > a, .theme-silver .nav-pills > li.active > a:hover, .theme-silver .nav-pills > li.active > a:focus {
  background: #64bed8; }
.theme-silver .dropdown-menu > li > a:hover,
.theme-silver .dropdown-menu > li.active > a {
  background: #64bed8; }
.theme-silver .dropdown-menu > li > a:hover {
  color: #fff; }
.theme-silver .btn-group .btn-primary,
.theme-silver .btn-group .btn-primary:focus,
.theme-silver .input-group-btn .btn-primary,
.theme-silver .input-group-btn .btn-primary:focus {
  border-left-color: #43b0d0;
  border-right-color: #43b0d0; }
.theme-silver .btn-group .btn-primary:hover,
.theme-silver .btn-group.open .btn-primary.dropdown-toggle,
.theme-silver .input-group-btn .btn-primary:hover,
.theme-silver .input-group-btn.open .btn-primary.dropdown-toggle {
  border-color: #31a3c3; }
.theme-silver .btn-group .btn-primary:active,
.theme-silver .btn-group .btn-primary.active,
.theme-silver .input-group-btn .btn-primary:active,
.theme-silver .input-group-btn .btn-primary.active {
  border-left-color: #31a3c3;
  border-right-color: #31a3c3; }
.theme-silver .pagination > li > a:hover {
  border-color: #64bed8; }
.theme-silver .pagination > li.active > a,
.theme-silver .pagination > li.active > a:hover,
.theme-silver .pagination > li.active > a:focus,
.theme-silver .pagination > li.active > span {
  background: #64bed8;
  border-color: #64bed8; }
.theme-silver .pager li > a:hover {
  border-color: #64bed8; }
.theme-silver a.list-group-item.active {
  background: #64bed8;
  border-color: #64bed8; }
  .theme-silver a.list-group-item.active .list-group-item-text {
    color: white; }
.theme-silver .form-control:focus,
.theme-silver .form-control.focus {
  border-color: #64bed8;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }
.gt-ie8 .theme-silver input.px:focus + .lbl:before, .gt-ie8 .theme-silver input.px + .lbl:hover:before {
  border-color: #64bed8; }
.gt-ie8 .theme-silver input[type="checkbox"].px + .lbl:after {
  color: #64bed8; }
.gt-ie8 .theme-silver input[type="radio"].px + .lbl:after {
  background: #64bed8; }
.theme-silver .text-primary, .theme-silver .text-primary:hover, .theme-silver .text-primary:active, .theme-silver .text-primary:focus,
.theme-silver .text-primary a,
.theme-silver .text-primary a:focus {
  color: #64bed8; }
.theme-silver .text-primary a:hover,
.theme-silver .text-primary a:active {
  color: #309fbf; }
.theme-silver .bg-primary {
  background: #64bed8 !important; }
  .theme-silver .bg-primary[href]:hover {
    background: #5eb5cf !important; }
  .theme-silver .bg-primary.darken {
    background: #5eb5cf !important; }
    .theme-silver .bg-primary.darken[href]:hover {
      background: #52b0cb !important; }
  .theme-silver .bg-primary.darker {
    background: #52b0cb !important; }
    .theme-silver .bg-primary.darker[href]:hover {
      background: #47abc8 !important; }
  .theme-silver .bg-primary, .theme-silver .bg-primary:hover, .theme-silver .bg-primary:active, .theme-silver .bg-primary:focus,
  .theme-silver .bg-primary a,
  .theme-silver .bg-primary a:focus {
    color: #fff; }
  .theme-silver .bg-primary a:hover,
  .theme-silver .bg-primary a:active {
    color: #d9d9d9; }
  .theme-silver .bg-primary[href]:hover,
  .theme-silver .bg-primary [href]:hover {
    color: #fff; }
  .theme-silver .bg-primary * {
    border-color: #81cadf; }
.theme-silver .ui-slider-handle.ui-state-active:before {
  background: #64bed8; }
.theme-silver .ui-slider-range {
  background: #64bed8; }
.theme-silver .ui-autocomplete > li > a:hover,
.theme-silver .ui-autocompletev > li.active > a {
  background: #64bed8; }
.theme-silver .ui-autocomplete > li > a:hover {
  color: #fff; }
.theme-silver .ui-autocomplete .ui-state-focus {
  background: #64bed8;
  color: #fff; }
.theme-silver .ui-menu .ui-menu-item a:hover,
.theme-silver .ui-menu .ui-menu-item a.ui-state-focus,
.theme-silver .ui-menu .ui-menu-item a.ui-state-active {
  background: #64bed8; }
.theme-silver .ui-progressbar-value {
  border-color: #4cb4d2;
  background-color: #64bed8;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px; }
.theme-silver .ui-tabs-nav > .ui-tabs-active > a, .theme-silver .ui-tabs-nav > .ui-tabs-active > a:hover, .theme-silver .ui-tabs-nav > .ui-tabs-active > a:focus {
  color: #fff;
  background: #64bed8;
  border-bottom: 2px solid #50b5d3; }
.theme-silver .ui-datepicker .ui-state-active {
  background: #64bed8 !important; }
.theme-silver .ui-datepicker-title {
  background: #64bed8; }
.theme-silver .ui-datepicker th {
  background: #66a9bd; }
.theme-silver .ui-datepicker-buttonpane button.ui-priority-primary {
  color: #fff; }
  .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary, .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary:focus {
    border-color: #50b5d3;
    border-bottom-color: #37abcd;
    background: #64bed8;
    background-image: -webkit-linear-gradient(top, #68c0d9 0%, #50b5d3 100%);
    background-image: linear-gradient(to bottom, #68c0d9 0%, #50b5d3 100%);
    background-repeat: repeat-x; }
  .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary:hover {
    color: #fff;
    border-color: #43b0d0;
    border-bottom-color: #2d95b3;
    background: #50b5d3 !important;
    background-image: -webkit-linear-gradient(top, #5cbbd6 0%, #43b0d0 100%) !important;
    background-image: linear-gradient(to bottom, #5cbbd6 0%, #43b0d0 100%) !important;
    background-repeat: repeat-x; }
  .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary:active, .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary.active, .open .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
    background: none;
    background: #54b7d4 !important;
    border-color: #31a3c3;
    border-bottom-color: #31a3c3 !important;
    color: #fff;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset; }
  .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat {
    background: #58b9d5; }
    .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:hover, .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:active, .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.active, .open .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.dropdown-toggle {
      background: #58b9d5 !important;
      color: #fff; }
  .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:hover, .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:hover {
    color: #fff;
    border-color: #43b0d0;
    border-bottom-color: #2d95b3; }
  .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:active, .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:active {
    border-color: #31a3c3; }
  .btn-group .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary, .btn-group .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary:focus, .input-group-btn .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary, .input-group-btn .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary:focus {
    border-left-color: #43b0d0;
    border-right-color: #43b0d0; }
  .btn-group .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary:hover, .btn-group.open .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle, .input-group-btn .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary:hover, .input-group-btn.open .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
    border-color: #43b0d0;
    border-bottom-color: #2d95b3; }
  .btn-group .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary:active, .btn-group .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary.active, .input-group-btn .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary:active, .input-group-btn .theme-silver .ui-datepicker-buttonpane button.ui-priority-primary.active {
    border-left-color: #31a3c3;
    border-right-color: #31a3c3; }
.theme-silver .select2-drop-active,
.theme-silver .select2-container-multi.select2-container-active .select2-choices,
.theme-silver .select2-container-multi.select2-dropdown-open .select2-choices {
  border: 1px solid #64bed8; }
.theme-silver .select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid #64bed8; }
.theme-silver .select2-container-active .select2-choice,
.theme-silver .select2-container-active .select2-choices {
  border: 1px solid #64bed8; }
.theme-silver .select2-dropdown-open .select2-drop-above .select2-choice,
.theme-silver .select2-dropdown-open .select2-drop-above .select2-choices {
  border: 1px solid #64bed8;
  border-top-color: #aaa; }
.theme-silver .select2-results .select2-highlighted {
  background: #64bed8; }
.theme-silver .select2-primary .select2-container-multi .select2-choices .select2-search-choice {
  background: #64bed8; }
.theme-silver .select2-primary .select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #3badce; }
.theme-silver .select2-primary .select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  background: #64bed8 !important; }
.theme-silver .switcher-primary .switcher-state-on {
  background: #64bed8 !important; }
.theme-silver .pixel-file-input:hover {
  border-color: #64bed8; }
.theme-silver .datepicker table tr td.selected, .theme-silver .datepicker table tr td.selected:hover, .theme-silver .datepicker table tr td.selected.disabled, .theme-silver .datepicker table tr td.selected.disabled:hover {
  background: #64bed8; }
.theme-silver .datepicker table tr td.active, .theme-silver .datepicker table tr td.active:hover, .theme-silver .datepicker table tr td.active.disabled, .theme-silver .datepicker table tr td.active.disabled:hover {
  background: #64bed8; }
.theme-silver .datepicker table tr td span.active, .theme-silver .datepicker table tr td span.active:hover, .theme-silver .datepicker table tr td span.active.disabled, .theme-silver .datepicker table tr td span.active.disabled:hover {
  background: #64bed8; }
.theme-silver .datepicker thead tr {
  background: #66a9bd; }
  .theme-silver .datepicker thead tr:first-child {
    background: #64bed8 !important;
    border-bottom-color: #5994a5 !important; }
.theme-silver .datepicker thead th.prev,
.theme-silver .datepicker thead th.next {
  background: #64bed8; }
.theme-silver .datepicker thead th.prev {
  border-color: #5994a5; }
.theme-silver .datepicker thead th.next {
  border-color: #5994a5; }
.theme-silver .datepicker > .datepicker-days thead tr:first-child th.cw + .prev {
  border-color: #5994a5; }
.theme-silver .datepicker > .datepicker-days thead tr:last-child th.cw + th {
  border-color: #5994a5; }
.theme-silver .bootstrap-timepicker-widget table td a:hover {
  background: #64bed8;
  border-color: #64bed8; }
.theme-silver .pagination a.dp-today,
.theme-silver .pagination a.dp-today:hover {
  background: #8dcfe2;
  border-color: #78c7dd; }
.theme-silver .pagination a.dp-today:hover {
  border-color: #64bed8 !important; }
.theme-silver .pagination a.dp-selected {
  background: #64bed8 !important;
  border-color: #64bed8 !important; }
.theme-silver .tt-suggestion.tt-is-under-cursor {
  background: #64bed8; }
.theme-silver .table-primary .dataTables_wrapper .DT-per-page {
  border-color: #469fb8 !important; }
.theme-silver .dropzone-box.dz-drag-hover {
  border-color: #64bed8; }
.theme-silver .dropzone-box:hover .fa.fa-cloud-upload {
  background: #64bed8; }
.theme-silver .md-editor.active {
  border-color: #64bed8; }
.theme-silver .widget-rating .active a {
  color: #64bed8; }

.theme-clean {
  background: #fafafa; }
.theme-clean .page-header {
  background: transparent;
  border-color: #eee; }
.theme-clean .table,
.theme-clean .panel,
.theme-clean hr {
  border-color: #dcdcdc; }
.theme-clean .bordered {
  border-color: #dcdcdc; }
.theme-clean .bordered[class*='bg-'],
.theme-clean [class*='bg-'] .border-t {
  border-color: #fff;
  border-color: rgba(0, 0, 0, 0.2); }
.theme-clean .panel.panel-transparent > .panel-heading {
  border-bottom-color: #dcdcdc !important; }
.theme-clean .breadcrumb-page {
  background: transparent;
  border-bottom: 1px solid #eee;
  padding-bottom: 6px;
  padding-top: 6px; }
.theme-clean #main-navbar .navbar-header a {
  color: #fff; }
.theme-clean #main-navbar .navbar-header,
.theme-clean #main-navbar .navbar-collapse {
  background: #3d4a5d;
  -webkit-box-shadow: none;
  box-shadow: none; }
.theme-clean #main-navbar .navbar-collapse {
  border-top: 1px solid #4d5e76; }
.theme-clean #main-navbar .dropdown > a,
.theme-clean #main-navbar .dropdown-menu > li > a,
.theme-clean #main-navbar .nav > li > a {
  color: #fff; }
.theme-clean #main-navbar .navbar-toggle {
  color: #fff; }
.theme-clean #main-navbar #main-menu-toggle {
  background: #3d4a5d;
  color: #fff; }
  .theme-clean #main-navbar #main-menu-toggle .fa:before {
    color: #fff; }
.theme-clean #main-navbar li + li,
.theme-clean #main-navbar .nav + .nav,
.theme-clean #main-navbar .nav + .right {
  border-top: 1px solid #4d5e76; }
.theme-clean #main-navbar .dropdown.open > a {
  border-top: 1px solid #2d3644;
  border-bottom: 1px solid #333e4e;
  margin-top: -1px;
  background: #374354; }
.theme-clean #main-navbar form.navbar-form {
  -webkit-box-shadow: none;
  box-shadow: none; }
  .theme-clean #main-navbar form.navbar-form .form-control {
    background: #fff;
    border-color: #3d4a5d;
    border-radius: 999999px; }
@media (min-width: 768px) {
  .theme-clean #main-navbar li + li,
  .theme-clean #main-navbar .nav + .nav,
  .theme-clean #main-navbar .nav + .right {
    border-top: none; }
  .theme-clean #main-navbar .navbar-collapse {
    border-top: none; }
  .theme-clean #main-navbar .nav > li > a:hover {
    background: #374354; }
  .theme-clean #main-navbar .dropdown.open > a {
    border-top: none;
    border-bottom: none;
    margin-top: 0;
    background: #374354; }
  .theme-clean #main-navbar .dropdown-menu {
    border-radius: 2px;
    margin-top: 3px;
    padding: 5px 0; }
    .theme-clean #main-navbar .dropdown-menu > li > a {
      color: #555; }
      .theme-clean #main-navbar .dropdown-menu > li > a:hover {
        background: #f6f6f6; } }
.theme-clean #main-menu-bg,
.theme-clean .mmc-dropdown-open-ul {
  background: #edf0f1; }
.theme-clean #main-menu #main-menu-inner {
  padding-top: 10px; }
.theme-clean #main-menu .navigation li a,
.theme-clean #main-menu .mmc-dropdown-open-ul li a {
  color: #626b7a;
  font-size: 12px;
  border-bottom: 1px solid #dfe4e6;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s; }
  .theme-clean #main-menu .navigation li a:hover,
  .theme-clean #main-menu .mmc-dropdown-open-ul li a:hover {
    color: #1e2125; }
.theme-clean #main-menu .navigation li.active > a,
.theme-clean #main-menu .mmc-dropdown-open-ul li.active > a {
  color: #1e2125;
  background: #fafafa; }
.theme-clean #main-menu .navigation > li:first-child,
.theme-clean #main-menu .mmc-dropdown-open-ul > li:first-child {
  border-top: 1px solid #dfe4e6; }
.theme-clean #main-menu .mm-dropdown.active > a {
  background: none !important; }
.theme-clean #main-menu .mmc-dropdown-open > a {
  color: #1e2125 !important; }
.theme-clean #main-menu .mmc-title {
  color: #1e2125;
  background: #edf0f1;
  border-bottom: 1px solid #dfe4e6; }
.theme-clean #main-menu .mmc-dropdown-open-ul {
  border: 1px solid #eee;
  margin-top: -1px; }
.theme-clean #main-menu .mmc-dropdown-open-ul.top .mmc-title {
  border-bottom: none; }
.theme-clean #main-menu .menu-content {
  border: none;
  margin-top: -10px; }
  .theme-clean #main-menu .menu-content .btn-outline {
    border-color: #dfe4e6;
    color: #626b7a; }
    .theme-clean #main-menu .menu-content .btn-outline:hover, .theme-clean #main-menu .menu-content .btn-outline:active {
      border-color: transparent;
      color: #fff; }
.theme-clean .mmc-dropdown-open .mm-dropdown.open > a, .theme-clean.mme #main-menu .mm-dropdown.open > a {
  color: #1e2125 !important; }
@media (min-width: 768px) {
  .theme-clean #main-menu .mm-dropdown.open > a {
    color: #1e2125; }
  .theme-clean.mmc #main-menu .navigation > .mm-dropdown.open > a {
    color: #626b7a; } }
.theme-clean #main-menu-bg, .theme-clean.right-to-left.main-menu-right #main-menu-bg {
  border-left: none;
  border-right: 1px solid #dfe4e6; }
.theme-clean #main-menu .mmc-dropdown-open > ul, .theme-clean.right-to-left.main-menu-right #main-menu .mmc-dropdown-open > ul {
  margin-left: -1px;
  margin-right: 0; }
.theme-clean.right-to-left #main-menu-bg, .theme-clean.main-menu-right #main-menu-bg {
  border-left: 1px solid #dfe4e6;
  border-right: none; }
.theme-clean.right-to-left #main-menu .mmc-dropdown-open > ul, .theme-clean.main-menu-right #main-menu .mmc-dropdown-open > ul {
  margin-left: 0;
  margin-right: -1px; }
.theme-clean .demo-logo {
  background: #67cea6; }
.theme-clean #main-navbar .navbar-brand div {
  background: #414f63; }
.theme-clean #main-navbar .navbar-header a {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600; }
.theme-clean.page-signin .signin-info, .theme-clean.page-signup .signup-header {
  background: #edf0f1;
  background: rgba(61, 74, 93, 0.8); }
.theme-clean .timeline:before,
.theme-clean .timeline:after,
.theme-clean .tl-header,
.theme-clean .tl-icon {
  background: #e1e1e1; }
.theme-clean .tl-header,
.theme-clean .tl-icon {
  box-shadow: 0 0 0 4px #fafafa; }
.theme-clean .tl-entry:before {
  background: #67cea6;
  box-shadow: 0 0 0 3px #fafafa; }
.theme-clean .tl-body:before {
  border-color: transparent #dcdcdc transparent transparent; }
@media (min-width: 768px) {
  .theme-clean .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent transparent transparent #dcdcdc; }
  .theme-clean.right-to-left .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent #dcdcdc transparent transparent; } }
.theme-clean.right-to-left .tl-body:before {
  border-color: transparent transparent transparent #dcdcdc; }
.theme-clean.page-mail .mail-nav,
.theme-clean.page-mail .mail-nav:before {
  background: #fafafa; }
.theme-clean.page-mail .mail-nav .sections li.active .label,
.theme-clean.page-mail .mail-nav .sections li.active .badge {
  background: #67cea6; }
.theme-clean.page-mail .mail-nav,
.theme-clean.page-mail .mail-nav .compose-btn,
.theme-clean.page-mail .mail-nav .sections li.active,
.theme-clean.page-mail .mail-nav:before {
  border-color: #dcdcdc; }
.theme-clean.page-mail .mail-nav .navigation.open .sections li.active {
  background: #f0f0f0; }
.theme-clean.page-mail .mail-nav li.divider {
  background: #dcdcdc; }
.theme-clean.page-mail .mail-nav .sections li.active a {
  color: #484848; }
.theme-clean.page-mail .mail-nav .sections li.active:after {
  color: #aeaeae; }
.theme-clean.page-mail .mail-nav .sections a {
  color: #6e6e6e; }
.theme-clean.page-mail .mail-nav-header {
  color: #aeaeae; }
.theme-clean.page-mail .mail-nav .add-more a {
  color: #aeaeae; }
@media (min-width: 992px) {
  .theme-clean.page-mail .mail-nav .navigation .sections li.active {
    background: #f0f0f0; }
  .theme-clean.page-mail .mail-nav .sections a:hover {
    color: #484848; } }
.theme-clean .nav-icon-btn.nav-icon-btn-primary .label {
  background: #67cea6; }
.theme-clean .btn-primary {
  color: #fff; }
  .theme-clean .btn-primary, .theme-clean .btn-primary:focus {
    border-color: #54c89b;
    border-bottom-color: #3ebf8d;
    background: #67cea6;
    background-image: -webkit-linear-gradient(top, #6bcfa8 0%, #54c89b 100%);
    background-image: linear-gradient(to bottom, #6bcfa8 0%, #54c89b 100%);
    background-repeat: repeat-x; }
  .theme-clean .btn-primary:hover {
    color: #fff;
    border-color: #48c494;
    border-bottom-color: #35a479;
    background: #54c89b !important;
    background-image: -webkit-linear-gradient(top, #5fcca1 0%, #48c494 100%) !important;
    background-image: linear-gradient(to bottom, #5fcca1 0%, #48c494 100%) !important;
    background-repeat: repeat-x; }
  .theme-clean .btn-primary:active, .theme-clean .btn-primary.active, .open .theme-clean .btn-primary.dropdown-toggle {
    background: none;
    background: #58c99d !important;
    border-color: #3ab484;
    border-bottom-color: #3ab484 !important;
    color: #fff;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset; }
  .theme-clean .btn-primary.btn-flat {
    background: #5bca9f; }
    .theme-clean .btn-primary.btn-flat:hover, .theme-clean .btn-primary.btn-flat:active, .theme-clean .btn-primary.btn-flat.active, .open .theme-clean .btn-primary.btn-flat.dropdown-toggle {
      background: #5bca9f !important;
      color: #fff; }
  .theme-clean .btn-primary.btn-outline:hover, .theme-clean .btn-primary.btn-outline.btn-flat:hover {
    color: #fff;
    border-color: #48c494;
    border-bottom-color: #35a479; }
  .theme-clean .btn-primary.btn-outline:active, .theme-clean .btn-primary.btn-outline.btn-flat:active {
    border-color: #3ab484; }
  .btn-group .theme-clean .btn-primary, .btn-group .theme-clean .btn-primary:focus, .input-group-btn .theme-clean .btn-primary, .input-group-btn .theme-clean .btn-primary:focus {
    border-left-color: #48c494;
    border-right-color: #48c494; }
  .btn-group .theme-clean .btn-primary:hover, .btn-group.open .theme-clean .btn-primary.dropdown-toggle, .input-group-btn .theme-clean .btn-primary:hover, .input-group-btn.open .theme-clean .btn-primary.dropdown-toggle {
    border-color: #48c494;
    border-bottom-color: #35a479; }
  .btn-group .theme-clean .btn-primary:active, .btn-group .theme-clean .btn-primary.active, .input-group-btn .theme-clean .btn-primary:active, .input-group-btn .theme-clean .btn-primary.active {
    border-left-color: #3ab484;
    border-right-color: #3ab484; }
.theme-clean .open .btn.btn-primary.dropdown-toggle {
  color: #fff;
  border-bottom-color: #3ab484 !important;
  background: none;
  background: #58c99d !important; }
.theme-clean .progress-bar {
  background: #67cea6;
  border-color: #50c798; }
.theme-clean .progress-striped .progress-bar {
  background-color: #67cea6;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px; }
.theme-clean .label.label-primary {
  background: #67cea6; }
  .theme-clean .label.label-primary[href]:hover, .theme-clean .label.label-primary[href]:focus {
    background-color: #40c28f; }
  .theme-clean .label.label-primary.label-tag {
    border: 1px solid #67cea6; }
    .theme-clean .label.label-primary.label-tag:before {
      border-color: transparent #67cea6 transparent transparent; }
    .theme-clean .label.label-primary.label-tag[href]:hover, .theme-clean .label.label-primary.label-tag[href]:focus {
      border-color: #40c28f; }
    .theme-clean .label.label-primary.label-tag[href]:hover:before, .theme-clean .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent #40c28f transparent transparent; }
    .right-to-left .theme-clean .label.label-primary.label-tag:before {
      border-color: transparent transparent transparent #67cea6; }
    .right-to-left .theme-clean .label.label-primary.label-tag[href]:hover:before, .right-to-left .theme-clean .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent transparent transparent #40c28f; }
    .ie8 .right-to-left .theme-clean .label.label-primary.label-tag:before, .ie9 .right-to-left .theme-clean .label.label-primary.label-tag:before {
      border-color: transparent #67cea6 transparent transparent; }
    .ie8 .right-to-left .theme-clean .label.label-primary.label-tag[href]:hover:before, .ie8 .right-to-left .theme-clean .label.label-primary.label-tag[href]:focus:before, .ie9 .right-to-left .theme-clean .label.label-primary.label-tag[href]:hover:before, .ie9 .right-to-left .theme-clean .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent #40c28f transparent transparent; }
.theme-clean.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent transparent transparent #67cea6; }
.theme-clean.right-to-left .label.label-primary.label-tag:hover:before, .theme-clean.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent transparent transparent #40c28f; }
.ie8 .theme-clean.right-to-left .label.label-primary.label-tag:before, .ie9 .theme-clean.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent #67cea6 transparent transparent; }
.ie8 .theme-clean.right-to-left .label.label-primary.label-tag:hover:before, .ie8 .theme-clean.right-to-left .label.label-primary.label-tag:focus:before, .ie9 .theme-clean.right-to-left .label.label-primary.label-tag:hover:before, .ie9 .theme-clean.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent #40c28f transparent transparent; }
.theme-clean .badge.badge-primary {
  background: #67cea6;
  border: 1px solid #67cea6; }
  .theme-clean .badge.badge-primary[href]:hover, .theme-clean .badge.badge-primary[href]:focus {
    background-color: #40c28f;
    border-color: #40c28f; }
.theme-clean .table-primary table {
  border-top-color: #4fa986 !important; }
.theme-clean .table-primary thead,
.theme-clean .table-primary thead tr,
.theme-clean .table-primary thead th,
.theme-clean .table-primary .table-header {
  border-color: #4fa986 !important;
  color: #fff; }
.theme-clean .table-primary thead tr,
.theme-clean .table-primary thead th {
  background: #59bd96; }
.theme-clean .table-primary .table-header {
  background: #67cea6; }
.theme-clean .nav-tabs > li.active > a, .theme-clean .nav-tabs > li.active > a:hover, .theme-clean .nav-tabs > li.active > a:focus {
  background: #67cea6;
  border-bottom: 2px solid #54c89b; }
.theme-clean .nav-tabs .nav-tabs.nav-justified > .active > a {
  border-bottom-color: #54c89b; }
.theme-clean .nav-pills > li.active > a, .theme-clean .nav-pills > li.active > a:hover, .theme-clean .nav-pills > li.active > a:focus {
  background: #67cea6; }
.theme-clean .dropdown-menu > li > a:hover,
.theme-clean .dropdown-menu > li.active > a {
  background: #67cea6; }
.theme-clean .dropdown-menu > li > a:hover {
  color: #fff; }
.theme-clean .btn-group .btn-primary,
.theme-clean .btn-group .btn-primary:focus,
.theme-clean .input-group-btn .btn-primary,
.theme-clean .input-group-btn .btn-primary:focus {
  border-left-color: #48c494;
  border-right-color: #48c494; }
.theme-clean .btn-group .btn-primary:hover,
.theme-clean .btn-group.open .btn-primary.dropdown-toggle,
.theme-clean .input-group-btn .btn-primary:hover,
.theme-clean .input-group-btn.open .btn-primary.dropdown-toggle {
  border-color: #3ab484; }
.theme-clean .btn-group .btn-primary:active,
.theme-clean .btn-group .btn-primary.active,
.theme-clean .input-group-btn .btn-primary:active,
.theme-clean .input-group-btn .btn-primary.active {
  border-left-color: #3ab484;
  border-right-color: #3ab484; }
.theme-clean .pagination > li > a:hover {
  border-color: #67cea6; }
.theme-clean .pagination > li.active > a,
.theme-clean .pagination > li.active > a:hover,
.theme-clean .pagination > li.active > a:focus,
.theme-clean .pagination > li.active > span {
  background: #67cea6;
  border-color: #67cea6; }
.theme-clean .pager li > a:hover {
  border-color: #67cea6; }
.theme-clean a.list-group-item.active {
  background: #67cea6;
  border-color: #67cea6; }
  .theme-clean a.list-group-item.active .list-group-item-text {
    color: white; }
.theme-clean .form-control:focus,
.theme-clean .form-control.focus {
  border-color: #67cea6;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }
.gt-ie8 .theme-clean input.px:focus + .lbl:before, .gt-ie8 .theme-clean input.px + .lbl:hover:before {
  border-color: #67cea6; }
.gt-ie8 .theme-clean input[type="checkbox"].px + .lbl:after {
  color: #67cea6; }
.gt-ie8 .theme-clean input[type="radio"].px + .lbl:after {
  background: #67cea6; }
.theme-clean .text-primary, .theme-clean .text-primary:hover, .theme-clean .text-primary:active, .theme-clean .text-primary:focus,
.theme-clean .text-primary a,
.theme-clean .text-primary a:focus {
  color: #67cea6; }
.theme-clean .text-primary a:hover,
.theme-clean .text-primary a:active {
  color: #39b082; }
.theme-clean .bg-primary {
  background: #67cea6 !important; }
  .theme-clean .bg-primary[href]:hover {
    background: #62c49e !important; }
  .theme-clean .bg-primary.darken {
    background: #62c49e !important; }
    .theme-clean .bg-primary.darken[href]:hover {
      background: #57c097 !important; }
  .theme-clean .bg-primary.darker {
    background: #57c097 !important; }
    .theme-clean .bg-primary.darker[href]:hover {
      background: #4cbb90 !important; }
  .theme-clean .bg-primary, .theme-clean .bg-primary:hover, .theme-clean .bg-primary:active, .theme-clean .bg-primary:focus,
  .theme-clean .bg-primary a,
  .theme-clean .bg-primary a:focus {
    color: #fff; }
  .theme-clean .bg-primary a:hover,
  .theme-clean .bg-primary a:active {
    color: #d9d9d9; }
  .theme-clean .bg-primary[href]:hover,
  .theme-clean .bg-primary [href]:hover {
    color: #fff; }
  .theme-clean .bg-primary * {
    border-color: #82d7b6; }
.theme-clean .ui-slider-handle.ui-state-active:before {
  background: #67cea6; }
.theme-clean .ui-slider-range {
  background: #67cea6; }
.theme-clean .ui-autocomplete > li > a:hover,
.theme-clean .ui-autocompletev > li.active > a {
  background: #67cea6; }
.theme-clean .ui-autocomplete > li > a:hover {
  color: #fff; }
.theme-clean .ui-autocomplete .ui-state-focus {
  background: #67cea6;
  color: #fff; }
.theme-clean .ui-menu .ui-menu-item a:hover,
.theme-clean .ui-menu .ui-menu-item a.ui-state-focus,
.theme-clean .ui-menu .ui-menu-item a.ui-state-active {
  background: #67cea6; }
.theme-clean .ui-progressbar-value {
  border-color: #50c798;
  background-color: #67cea6;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px; }
.theme-clean .ui-tabs-nav > .ui-tabs-active > a, .theme-clean .ui-tabs-nav > .ui-tabs-active > a:hover, .theme-clean .ui-tabs-nav > .ui-tabs-active > a:focus {
  color: #fff;
  background: #67cea6;
  border-bottom: 2px solid #54c89b; }
.theme-clean .ui-datepicker .ui-state-active {
  background: #67cea6 !important; }
.theme-clean .ui-datepicker-title {
  background: #67cea6; }
.theme-clean .ui-datepicker th {
  background: #6ab196; }
.theme-clean .ui-datepicker-buttonpane button.ui-priority-primary {
  color: #fff; }
  .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary, .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary:focus {
    border-color: #54c89b;
    border-bottom-color: #3ebf8d;
    background: #67cea6;
    background-image: -webkit-linear-gradient(top, #6bcfa8 0%, #54c89b 100%);
    background-image: linear-gradient(to bottom, #6bcfa8 0%, #54c89b 100%);
    background-repeat: repeat-x; }
  .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary:hover {
    color: #fff;
    border-color: #48c494;
    border-bottom-color: #35a479;
    background: #54c89b !important;
    background-image: -webkit-linear-gradient(top, #5fcca1 0%, #48c494 100%) !important;
    background-image: linear-gradient(to bottom, #5fcca1 0%, #48c494 100%) !important;
    background-repeat: repeat-x; }
  .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary:active, .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary.active, .open .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
    background: none;
    background: #58c99d !important;
    border-color: #3ab484;
    border-bottom-color: #3ab484 !important;
    color: #fff;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset; }
  .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat {
    background: #5bca9f; }
    .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:hover, .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:active, .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.active, .open .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.dropdown-toggle {
      background: #5bca9f !important;
      color: #fff; }
  .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:hover, .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:hover {
    color: #fff;
    border-color: #48c494;
    border-bottom-color: #35a479; }
  .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:active, .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:active {
    border-color: #3ab484; }
  .btn-group .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary, .btn-group .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary:focus, .input-group-btn .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary, .input-group-btn .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary:focus {
    border-left-color: #48c494;
    border-right-color: #48c494; }
  .btn-group .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary:hover, .btn-group.open .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle, .input-group-btn .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary:hover, .input-group-btn.open .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
    border-color: #48c494;
    border-bottom-color: #35a479; }
  .btn-group .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary:active, .btn-group .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary.active, .input-group-btn .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary:active, .input-group-btn .theme-clean .ui-datepicker-buttonpane button.ui-priority-primary.active {
    border-left-color: #3ab484;
    border-right-color: #3ab484; }
.theme-clean .select2-drop-active,
.theme-clean .select2-container-multi.select2-container-active .select2-choices,
.theme-clean .select2-container-multi.select2-dropdown-open .select2-choices {
  border: 1px solid #67cea6; }
.theme-clean .select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid #67cea6; }
.theme-clean .select2-container-active .select2-choice,
.theme-clean .select2-container-active .select2-choices {
  border: 1px solid #67cea6; }
.theme-clean .select2-dropdown-open .select2-drop-above .select2-choice,
.theme-clean .select2-dropdown-open .select2-drop-above .select2-choices {
  border: 1px solid #67cea6;
  border-top-color: #aaa; }
.theme-clean .select2-results .select2-highlighted {
  background: #67cea6; }
.theme-clean .select2-primary .select2-container-multi .select2-choices .select2-search-choice {
  background: #67cea6; }
.theme-clean .select2-primary .select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #40c28f; }
.theme-clean .select2-primary .select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  background: #67cea6 !important; }
.theme-clean .switcher-primary .switcher-state-on {
  background: #67cea6 !important; }
.theme-clean .pixel-file-input:hover {
  border-color: #67cea6; }
.theme-clean .datepicker table tr td.selected, .theme-clean .datepicker table tr td.selected:hover, .theme-clean .datepicker table tr td.selected.disabled, .theme-clean .datepicker table tr td.selected.disabled:hover {
  background: #67cea6; }
.theme-clean .datepicker table tr td.active, .theme-clean .datepicker table tr td.active:hover, .theme-clean .datepicker table tr td.active.disabled, .theme-clean .datepicker table tr td.active.disabled:hover {
  background: #67cea6; }
.theme-clean .datepicker table tr td span.active, .theme-clean .datepicker table tr td span.active:hover, .theme-clean .datepicker table tr td span.active.disabled, .theme-clean .datepicker table tr td span.active.disabled:hover {
  background: #67cea6; }
.theme-clean .datepicker thead tr {
  background: #6ab196; }
  .theme-clean .datepicker thead tr:first-child {
    background: #67cea6 !important;
    border-bottom-color: #629682 !important; }
.theme-clean .datepicker thead th.prev,
.theme-clean .datepicker thead th.next {
  background: #67cea6; }
.theme-clean .datepicker thead th.prev {
  border-color: #629682; }
.theme-clean .datepicker thead th.next {
  border-color: #629682; }
.theme-clean .datepicker > .datepicker-days thead tr:first-child th.cw + .prev {
  border-color: #629682; }
.theme-clean .datepicker > .datepicker-days thead tr:last-child th.cw + th {
  border-color: #629682; }
.theme-clean .bootstrap-timepicker-widget table td a:hover {
  background: #67cea6;
  border-color: #67cea6; }
.theme-clean .pagination a.dp-today,
.theme-clean .pagination a.dp-today:hover {
  background: #8edabd;
  border-color: #7ad4b1; }
.theme-clean .pagination a.dp-today:hover {
  border-color: #67cea6 !important; }
.theme-clean .pagination a.dp-selected {
  background: #67cea6 !important;
  border-color: #67cea6 !important; }
.theme-clean .tt-suggestion.tt-is-under-cursor {
  background: #67cea6; }
.theme-clean .table-primary .dataTables_wrapper .DT-per-page {
  border-color: #4fa986 !important; }
.theme-clean .dropzone-box.dz-drag-hover {
  border-color: #67cea6; }
.theme-clean .dropzone-box:hover .fa.fa-cloud-upload {
  background: #67cea6; }
.theme-clean .md-editor.active {
  border-color: #67cea6; }
.theme-clean .widget-rating .active a {
  color: #67cea6; }

.theme-white {
  background: #fff; }
.theme-white .page-header {
  background: transparent;
  border-color: #e8e8e8; }
.theme-white .table,
.theme-white .panel,
.theme-white hr {
  border-color: #e5e5e5; }
.theme-white .bordered {
  border-color: #e5e5e5; }
.theme-white .bordered[class*='bg-'],
.theme-white [class*='bg-'] .border-t {
  border-color: #fff;
  border-color: rgba(0, 0, 0, 0.2); }
.theme-white .panel.panel-transparent > .panel-heading {
  border-bottom-color: #e5e5e5 !important; }
.theme-white .breadcrumb-page {
  background: transparent;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 6px;
  padding-top: 6px; }
.theme-white #main-navbar .navbar-header a {
  color: #777; }
.theme-white #main-navbar .navbar-header,
.theme-white #main-navbar .navbar-collapse {
  background: #eee;
  -webkit-box-shadow: none;
  box-shadow: none; }
.theme-white #main-navbar .navbar-collapse {
  border-top: 1px solid #e1e1e1; }
.theme-white #main-navbar .dropdown > a,
.theme-white #main-navbar .dropdown-menu > li > a,
.theme-white #main-navbar .nav > li > a {
  color: #777; }
.theme-white #main-navbar .navbar-toggle {
  color: #777; }
.theme-white #main-navbar #main-menu-toggle {
  background: #eee;
  color: #777; }
  .theme-white #main-navbar #main-menu-toggle .fa:before {
    color: #777; }
.theme-white #main-navbar li + li,
.theme-white #main-navbar .nav + .nav,
.theme-white #main-navbar .nav + .right {
  border-top: 1px solid #e1e1e1; }
.theme-white #main-navbar .dropdown.open > a {
  border-top: 1px solid #cfcfcf;
  border-bottom: 1px solid #dadada;
  margin-top: -1px;
  background: #e1e1e1; }
.theme-white #main-navbar form.navbar-form {
  -webkit-box-shadow: none;
  box-shadow: none; }
  .theme-white #main-navbar form.navbar-form .form-control {
    background: #fff;
    border-color: #eee;
    border-radius: 999999px; }
@media (min-width: 768px) {
  .theme-white #main-navbar li + li,
  .theme-white #main-navbar .nav + .nav,
  .theme-white #main-navbar .nav + .right {
    border-top: none; }
  .theme-white #main-navbar .navbar-collapse {
    border-top: none; }
  .theme-white #main-navbar .nav > li > a:hover {
    background: #e6e6e6; }
  .theme-white #main-navbar .dropdown.open > a {
    border-top: none;
    border-bottom: none;
    margin-top: 0;
    background: #e6e6e6; }
  .theme-white #main-navbar .dropdown-menu {
    border-radius: 2px;
    margin-top: 3px;
    padding: 5px 0; }
    .theme-white #main-navbar .dropdown-menu > li > a {
      color: #555; }
      .theme-white #main-navbar .dropdown-menu > li > a:hover {
        background: #f6f6f6; } }
.theme-white #main-menu-bg,
.theme-white .mmc-dropdown-open-ul {
  background: #fff; }
.theme-white #main-menu #main-menu-inner {
  padding-top: 10px; }
.theme-white #main-menu .navigation li a,
.theme-white #main-menu .mmc-dropdown-open-ul li a {
  color: #8b9298;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s; }
  .theme-white #main-menu .navigation li a:hover,
  .theme-white #main-menu .mmc-dropdown-open-ul li a:hover {
    color: #414549; }
.theme-white #main-menu .navigation li.active > a,
.theme-white #main-menu .mmc-dropdown-open-ul li.active > a {
  color: #414549;
  background: #f6f6f6; }
.theme-white #main-menu .mm-dropdown.active > a {
  background: none !important; }
.theme-white #main-menu .mmc-dropdown-open > a {
  color: #414549 !important; }
.theme-white #main-menu .mmc-title {
  color: #414549;
  background: #fff; }
.theme-white #main-menu .mmc-dropdown-open-ul {
  border: 1px solid #e8e8e8;
  margin-top: -1px; }
.theme-white #main-menu .menu-content {
  border-color: #e8e8e8; }
  .theme-white #main-menu .menu-content .btn-outline {
    border-color: #e8e8e8;
    color: #8b9298; }
    .theme-white #main-menu .menu-content .btn-outline:hover, .theme-white #main-menu .menu-content .btn-outline:active {
      border-color: transparent;
      color: #fff; }
  .theme-white #main-menu .menu-content.top {
    margin-top: -10px; }
.theme-white .mmc-dropdown-open .mm-dropdown.open > a, .theme-white.mme #main-menu .mm-dropdown.open > a {
  color: #414549 !important; }
@media (min-width: 768px) {
  .theme-white #main-menu .mm-dropdown.open > a {
    color: #414549; }
  .theme-white.mmc #main-menu .navigation > .mm-dropdown.open > a {
    color: #8b9298; } }
.theme-white #main-menu-bg,
.theme-white #main-menu .navigation li.active > a, .theme-white.right-to-left.main-menu-right #main-menu-bg, .theme-white.right-to-left.main-menu-right #main-menu .navigation li.active > a {
  border-left: none;
  border-right: 1px solid #e8e8e8; }
.theme-white #main-menu .mmc-dropdown-open-ul, .theme-white.right-to-left.main-menu-right #main-menu .mmc-dropdown-open-ul {
  margin-left: -1px !important;
  margin-right: 0 !important; }
.theme-white.right-to-left #main-menu-bg, .theme-white.right-to-left #main-menu .navigation li.active > a, .theme-white.main-menu-right #main-menu-bg, .theme-white.main-menu-right #main-menu .navigation li.active > a {
  border-left: 1px solid #e8e8e8;
  border-right: none; }
.theme-white.right-to-left #main-menu .mmc-dropdown-open-ul, .theme-white.main-menu-right #main-menu .mmc-dropdown-open-ul {
  margin-left: 0 !important;
  margin-right: -1px !important; }
.theme-white .demo-logo {
  background: #4ab6d5; }
.theme-white #main-navbar .navbar-brand div {
  background: #52b9d7; }
.theme-white #main-navbar .navbar-header a {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600; }
.theme-white.page-signin .signin-info, .theme-white.page-signup .signup-header {
  background: #4ab6d5;
  background: rgba(74, 182, 213, 0.8); }
.theme-white .timeline:before,
.theme-white .timeline:after,
.theme-white .tl-header,
.theme-white .tl-icon {
  background: #e6e6e6; }
.theme-white .tl-header,
.theme-white .tl-icon {
  box-shadow: 0 0 0 4px #fff; }
.theme-white .tl-entry:before {
  background: #4ab6d5;
  box-shadow: 0 0 0 3px #fff; }
.theme-white .tl-body:before {
  border-color: transparent #e5e5e5 transparent transparent; }
@media (min-width: 768px) {
  .theme-white .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent transparent transparent #e5e5e5; }
  .theme-white.right-to-left .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent #e5e5e5 transparent transparent; } }
.theme-white.right-to-left .tl-body:before {
  border-color: transparent transparent transparent #e5e5e5; }
.theme-white.page-mail .mail-nav,
.theme-white.page-mail .mail-nav:before {
  background: #fff; }
.theme-white.page-mail .mail-nav .sections li.active .label,
.theme-white.page-mail .mail-nav .sections li.active .badge {
  background: #4ab6d5; }
.theme-white.page-mail .mail-nav,
.theme-white.page-mail .mail-nav .compose-btn,
.theme-white.page-mail .mail-nav .sections li.active,
.theme-white.page-mail .mail-nav:before {
  border-color: #e5e5e5; }
.theme-white.page-mail .mail-nav .navigation.open .sections li.active {
  background: whitesmoke; }
.theme-white.page-mail .mail-nav li.divider {
  background: #e5e5e5; }
.theme-white.page-mail .mail-nav .sections li.active a {
  color: #4d4d4d; }
.theme-white.page-mail .mail-nav .sections li.active:after {
  color: #b3b3b3; }
.theme-white.page-mail .mail-nav .sections a {
  color: #737373; }
.theme-white.page-mail .mail-nav-header {
  color: #b3b3b3; }
.theme-white.page-mail .mail-nav .add-more a {
  color: #b3b3b3; }
@media (min-width: 992px) {
  .theme-white.page-mail .mail-nav .navigation .sections li.active {
    background: whitesmoke; }
  .theme-white.page-mail .mail-nav .sections a:hover {
    color: #4d4d4d; } }
.theme-white .nav-icon-btn.nav-icon-btn-primary .label {
  background: #4ab6d5; }
.theme-white .btn-primary {
  color: #fff; }
  .theme-white .btn-primary, .theme-white .btn-primary:focus {
    border-color: #35aed0;
    border-bottom-color: #2b9bbb;
    background: #4ab6d5;
    background-image: -webkit-linear-gradient(top, #4eb8d6 0%, #35aed0 100%);
    background-image: linear-gradient(to bottom, #4eb8d6 0%, #35aed0 100%);
    background-repeat: repeat-x; }
  .theme-white .btn-primary:hover {
    color: #fff;
    border-color: #2ea6c8;
    border-bottom-color: #25839e;
    background: #35aed0 !important;
    background-image: -webkit-linear-gradient(top, #42b3d3 0%, #2ea6c8 100%) !important;
    background-image: linear-gradient(to bottom, #42b3d3 0%, #2ea6c8 100%) !important;
    background-repeat: repeat-x; }
  .theme-white .btn-primary:active, .theme-white .btn-primary.active, .open .theme-white .btn-primary.dropdown-toggle {
    background: none;
    background: #39afd1 !important;
    border-color: #2991af;
    border-bottom-color: #2991af !important;
    color: #fff;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset; }
  .theme-white .btn-primary.btn-flat {
    background: #3eb1d2; }
    .theme-white .btn-primary.btn-flat:hover, .theme-white .btn-primary.btn-flat:active, .theme-white .btn-primary.btn-flat.active, .open .theme-white .btn-primary.btn-flat.dropdown-toggle {
      background: #3eb1d2 !important;
      color: #fff; }
  .theme-white .btn-primary.btn-outline:hover, .theme-white .btn-primary.btn-outline.btn-flat:hover {
    color: #fff;
    border-color: #2ea6c8;
    border-bottom-color: #25839e; }
  .theme-white .btn-primary.btn-outline:active, .theme-white .btn-primary.btn-outline.btn-flat:active {
    border-color: #2991af; }
  .btn-group .theme-white .btn-primary, .btn-group .theme-white .btn-primary:focus, .input-group-btn .theme-white .btn-primary, .input-group-btn .theme-white .btn-primary:focus {
    border-left-color: #2ea6c8;
    border-right-color: #2ea6c8; }
  .btn-group .theme-white .btn-primary:hover, .btn-group.open .theme-white .btn-primary.dropdown-toggle, .input-group-btn .theme-white .btn-primary:hover, .input-group-btn.open .theme-white .btn-primary.dropdown-toggle {
    border-color: #2ea6c8;
    border-bottom-color: #25839e; }
  .btn-group .theme-white .btn-primary:active, .btn-group .theme-white .btn-primary.active, .input-group-btn .theme-white .btn-primary:active, .input-group-btn .theme-white .btn-primary.active {
    border-left-color: #2991af;
    border-right-color: #2991af; }
.theme-white .open .btn.btn-primary.dropdown-toggle {
  color: #fff;
  border-bottom-color: #2991af !important;
  background: none;
  background: #39afd1 !important; }
.theme-white .progress-bar {
  background: #4ab6d5;
  border-color: #31accf; }
.theme-white .progress-striped .progress-bar {
  background-color: #4ab6d5;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px; }
.theme-white .label.label-primary {
  background: #4ab6d5; }
  .theme-white .label.label-primary[href]:hover, .theme-white .label.label-primary[href]:focus {
    background-color: #2c9fc0; }
  .theme-white .label.label-primary.label-tag {
    border: 1px solid #4ab6d5; }
    .theme-white .label.label-primary.label-tag:before {
      border-color: transparent #4ab6d5 transparent transparent; }
    .theme-white .label.label-primary.label-tag[href]:hover, .theme-white .label.label-primary.label-tag[href]:focus {
      border-color: #2c9fc0; }
    .theme-white .label.label-primary.label-tag[href]:hover:before, .theme-white .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent #2c9fc0 transparent transparent; }
    .right-to-left .theme-white .label.label-primary.label-tag:before {
      border-color: transparent transparent transparent #4ab6d5; }
    .right-to-left .theme-white .label.label-primary.label-tag[href]:hover:before, .right-to-left .theme-white .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent transparent transparent #2c9fc0; }
    .ie8 .right-to-left .theme-white .label.label-primary.label-tag:before, .ie9 .right-to-left .theme-white .label.label-primary.label-tag:before {
      border-color: transparent #4ab6d5 transparent transparent; }
    .ie8 .right-to-left .theme-white .label.label-primary.label-tag[href]:hover:before, .ie8 .right-to-left .theme-white .label.label-primary.label-tag[href]:focus:before, .ie9 .right-to-left .theme-white .label.label-primary.label-tag[href]:hover:before, .ie9 .right-to-left .theme-white .label.label-primary.label-tag[href]:focus:before {
      border-color: transparent #2c9fc0 transparent transparent; }
.theme-white.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent transparent transparent #4ab6d5; }
.theme-white.right-to-left .label.label-primary.label-tag:hover:before, .theme-white.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent transparent transparent #2c9fc0; }
.ie8 .theme-white.right-to-left .label.label-primary.label-tag:before, .ie9 .theme-white.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent #4ab6d5 transparent transparent; }
.ie8 .theme-white.right-to-left .label.label-primary.label-tag:hover:before, .ie8 .theme-white.right-to-left .label.label-primary.label-tag:focus:before, .ie9 .theme-white.right-to-left .label.label-primary.label-tag:hover:before, .ie9 .theme-white.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent #2c9fc0 transparent transparent; }
.theme-white .badge.badge-primary {
  background: #4ab6d5;
  border: 1px solid #4ab6d5; }
  .theme-white .badge.badge-primary[href]:hover, .theme-white .badge.badge-primary[href]:focus {
    background-color: #2c9fc0;
    border-color: #2c9fc0; }
.theme-white .table-primary table {
  border-top-color: #3b8fa6 !important; }
.theme-white .table-primary thead,
.theme-white .table-primary thead tr,
.theme-white .table-primary thead th,
.theme-white .table-primary .table-header {
  border-color: #3b8fa6 !important;
  color: #fff; }
.theme-white .table-primary thead tr,
.theme-white .table-primary thead th {
  background: #3ba6c5; }
.theme-white .table-primary .table-header {
  background: #4ab6d5; }
.theme-white .nav-tabs > li.active > a, .theme-white .nav-tabs > li.active > a:hover, .theme-white .nav-tabs > li.active > a:focus {
  background: #4ab6d5;
  border-bottom: 2px solid #35aed0; }
.theme-white .nav-tabs .nav-tabs.nav-justified > .active > a {
  border-bottom-color: #35aed0; }
.theme-white .nav-pills > li.active > a, .theme-white .nav-pills > li.active > a:hover, .theme-white .nav-pills > li.active > a:focus {
  background: #4ab6d5; }
.theme-white .dropdown-menu > li > a:hover,
.theme-white .dropdown-menu > li.active > a {
  background: #4ab6d5; }
.theme-white .dropdown-menu > li > a:hover {
  color: #fff; }
.theme-white .btn-group .btn-primary,
.theme-white .btn-group .btn-primary:focus,
.theme-white .input-group-btn .btn-primary,
.theme-white .input-group-btn .btn-primary:focus {
  border-left-color: #2ea6c8;
  border-right-color: #2ea6c8; }
.theme-white .btn-group .btn-primary:hover,
.theme-white .btn-group.open .btn-primary.dropdown-toggle,
.theme-white .input-group-btn .btn-primary:hover,
.theme-white .input-group-btn.open .btn-primary.dropdown-toggle {
  border-color: #2991af; }
.theme-white .btn-group .btn-primary:active,
.theme-white .btn-group .btn-primary.active,
.theme-white .input-group-btn .btn-primary:active,
.theme-white .input-group-btn .btn-primary.active {
  border-left-color: #2991af;
  border-right-color: #2991af; }
.theme-white .pagination > li > a:hover {
  border-color: #4ab6d5; }
.theme-white .pagination > li.active > a,
.theme-white .pagination > li.active > a:hover,
.theme-white .pagination > li.active > a:focus,
.theme-white .pagination > li.active > span {
  background: #4ab6d5;
  border-color: #4ab6d5; }
.theme-white .pager li > a:hover {
  border-color: #4ab6d5; }
.theme-white a.list-group-item.active {
  background: #4ab6d5;
  border-color: #4ab6d5; }
  .theme-white a.list-group-item.active .list-group-item-text {
    color: #f0f9fb; }
.theme-white .form-control:focus,
.theme-white .form-control.focus {
  border-color: #4ab6d5;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }
.gt-ie8 .theme-white input.px:focus + .lbl:before, .gt-ie8 .theme-white input.px + .lbl:hover:before {
  border-color: #4ab6d5; }
.gt-ie8 .theme-white input[type="checkbox"].px + .lbl:after {
  color: #4ab6d5; }
.gt-ie8 .theme-white input[type="radio"].px + .lbl:after {
  background: #4ab6d5; }
.theme-white .text-primary, .theme-white .text-primary:hover, .theme-white .text-primary:active, .theme-white .text-primary:focus,
.theme-white .text-primary a,
.theme-white .text-primary a:focus {
  color: #4ab6d5; }
.theme-white .text-primary a:hover,
.theme-white .text-primary a:active {
  color: #288eab; }
.theme-white .bg-primary {
  background: #4ab6d5 !important; }
  .theme-white .bg-primary[href]:hover {
    background: #45adcb !important; }
  .theme-white .bg-primary.darken {
    background: #45adcb !important; }
    .theme-white .bg-primary.darken[href]:hover {
      background: #39a8c8 !important; }
  .theme-white .bg-primary.darker {
    background: #39a8c8 !important; }
    .theme-white .bg-primary.darker[href]:hover {
      background: #359ebc !important; }
  .theme-white .bg-primary, .theme-white .bg-primary:hover, .theme-white .bg-primary:active, .theme-white .bg-primary:focus,
  .theme-white .bg-primary a,
  .theme-white .bg-primary a:focus {
    color: #fff; }
  .theme-white .bg-primary a:hover,
  .theme-white .bg-primary a:active {
    color: #d9d9d9; }
  .theme-white .bg-primary[href]:hover,
  .theme-white .bg-primary [href]:hover {
    color: #fff; }
  .theme-white .bg-primary * {
    border-color: #67c2dc; }
.theme-white .ui-slider-handle.ui-state-active:before {
  background: #4ab6d5; }
.theme-white .ui-slider-range {
  background: #4ab6d5; }
.theme-white .ui-autocomplete > li > a:hover,
.theme-white .ui-autocompletev > li.active > a {
  background: #4ab6d5; }
.theme-white .ui-autocomplete > li > a:hover {
  color: #fff; }
.theme-white .ui-autocomplete .ui-state-focus {
  background: #4ab6d5;
  color: #fff; }
.theme-white .ui-menu .ui-menu-item a:hover,
.theme-white .ui-menu .ui-menu-item a.ui-state-focus,
.theme-white .ui-menu .ui-menu-item a.ui-state-active {
  background: #4ab6d5; }
.theme-white .ui-progressbar-value {
  border-color: #31accf;
  background-color: #4ab6d5;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px; }
.theme-white .ui-tabs-nav > .ui-tabs-active > a, .theme-white .ui-tabs-nav > .ui-tabs-active > a:hover, .theme-white .ui-tabs-nav > .ui-tabs-active > a:focus {
  color: #fff;
  background: #4ab6d5;
  border-bottom: 2px solid #35aed0; }
.theme-white .ui-datepicker .ui-state-active {
  background: #4ab6d5 !important; }
.theme-white .ui-datepicker-title {
  background: #4ab6d5; }
.theme-white .ui-datepicker th {
  background: #4ea0b7; }
.theme-white .ui-datepicker-buttonpane button.ui-priority-primary {
  color: #fff; }
  .theme-white .ui-datepicker-buttonpane button.ui-priority-primary, .theme-white .ui-datepicker-buttonpane button.ui-priority-primary:focus {
    border-color: #35aed0;
    border-bottom-color: #2b9bbb;
    background: #4ab6d5;
    background-image: -webkit-linear-gradient(top, #4eb8d6 0%, #35aed0 100%);
    background-image: linear-gradient(to bottom, #4eb8d6 0%, #35aed0 100%);
    background-repeat: repeat-x; }
  .theme-white .ui-datepicker-buttonpane button.ui-priority-primary:hover {
    color: #fff;
    border-color: #2ea6c8;
    border-bottom-color: #25839e;
    background: #35aed0 !important;
    background-image: -webkit-linear-gradient(top, #42b3d3 0%, #2ea6c8 100%) !important;
    background-image: linear-gradient(to bottom, #42b3d3 0%, #2ea6c8 100%) !important;
    background-repeat: repeat-x; }
  .theme-white .ui-datepicker-buttonpane button.ui-priority-primary:active, .theme-white .ui-datepicker-buttonpane button.ui-priority-primary.active, .open .theme-white .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
    background: none;
    background: #39afd1 !important;
    border-color: #2991af;
    border-bottom-color: #2991af !important;
    color: #fff;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset; }
  .theme-white .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat {
    background: #3eb1d2; }
    .theme-white .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:hover, .theme-white .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:active, .theme-white .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.active, .open .theme-white .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.dropdown-toggle {
      background: #3eb1d2 !important;
      color: #fff; }
  .theme-white .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:hover, .theme-white .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:hover {
    color: #fff;
    border-color: #2ea6c8;
    border-bottom-color: #25839e; }
  .theme-white .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:active, .theme-white .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:active {
    border-color: #2991af; }
  .btn-group .theme-white .ui-datepicker-buttonpane button.ui-priority-primary, .btn-group .theme-white .ui-datepicker-buttonpane button.ui-priority-primary:focus, .input-group-btn .theme-white .ui-datepicker-buttonpane button.ui-priority-primary, .input-group-btn .theme-white .ui-datepicker-buttonpane button.ui-priority-primary:focus {
    border-left-color: #2ea6c8;
    border-right-color: #2ea6c8; }
  .btn-group .theme-white .ui-datepicker-buttonpane button.ui-priority-primary:hover, .btn-group.open .theme-white .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle, .input-group-btn .theme-white .ui-datepicker-buttonpane button.ui-priority-primary:hover, .input-group-btn.open .theme-white .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
    border-color: #2ea6c8;
    border-bottom-color: #25839e; }
  .btn-group .theme-white .ui-datepicker-buttonpane button.ui-priority-primary:active, .btn-group .theme-white .ui-datepicker-buttonpane button.ui-priority-primary.active, .input-group-btn .theme-white .ui-datepicker-buttonpane button.ui-priority-primary:active, .input-group-btn .theme-white .ui-datepicker-buttonpane button.ui-priority-primary.active {
    border-left-color: #2991af;
    border-right-color: #2991af; }
.theme-white .select2-drop-active,
.theme-white .select2-container-multi.select2-container-active .select2-choices,
.theme-white .select2-container-multi.select2-dropdown-open .select2-choices {
  border: 1px solid #4ab6d5; }
.theme-white .select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid #4ab6d5; }
.theme-white .select2-container-active .select2-choice,
.theme-white .select2-container-active .select2-choices {
  border: 1px solid #4ab6d5; }
.theme-white .select2-dropdown-open .select2-drop-above .select2-choice,
.theme-white .select2-dropdown-open .select2-drop-above .select2-choices {
  border: 1px solid #4ab6d5;
  border-top-color: #aaa; }
.theme-white .select2-results .select2-highlighted {
  background: #4ab6d5; }
.theme-white .select2-primary .select2-container-multi .select2-choices .select2-search-choice {
  background: #4ab6d5; }
.theme-white .select2-primary .select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #2c9fc0; }
.theme-white .select2-primary .select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  background: #4ab6d5 !important; }
.theme-white .switcher-primary .switcher-state-on {
  background: #4ab6d5 !important; }
.theme-white .pixel-file-input:hover {
  border-color: #4ab6d5; }
.theme-white .datepicker table tr td.selected, .theme-white .datepicker table tr td.selected:hover, .theme-white .datepicker table tr td.selected.disabled, .theme-white .datepicker table tr td.selected.disabled:hover {
  background: #4ab6d5; }
.theme-white .datepicker table tr td.active, .theme-white .datepicker table tr td.active:hover, .theme-white .datepicker table tr td.active.disabled, .theme-white .datepicker table tr td.active.disabled:hover {
  background: #4ab6d5; }
.theme-white .datepicker table tr td span.active, .theme-white .datepicker table tr td span.active:hover, .theme-white .datepicker table tr td span.active.disabled, .theme-white .datepicker table tr td span.active.disabled:hover {
  background: #4ab6d5; }
.theme-white .datepicker thead tr {
  background: #4ea0b7; }
  .theme-white .datepicker thead tr:first-child {
    background: #4ab6d5 !important;
    border-bottom-color: #4c8595 !important; }
.theme-white .datepicker thead th.prev,
.theme-white .datepicker thead th.next {
  background: #4ab6d5; }
.theme-white .datepicker thead th.prev {
  border-color: #4c8595; }
.theme-white .datepicker thead th.next {
  border-color: #4c8595; }
.theme-white .datepicker > .datepicker-days thead tr:first-child th.cw + .prev {
  border-color: #4c8595; }
.theme-white .datepicker > .datepicker-days thead tr:last-child th.cw + th {
  border-color: #4c8595; }
.theme-white .bootstrap-timepicker-widget table td a:hover {
  background: #4ab6d5;
  border-color: #4ab6d5; }
.theme-white .pagination a.dp-today,
.theme-white .pagination a.dp-today:hover {
  background: #73c7df;
  border-color: #5fbeda; }
.theme-white .pagination a.dp-today:hover {
  border-color: #4ab6d5 !important; }
.theme-white .pagination a.dp-selected {
  background: #4ab6d5 !important;
  border-color: #4ab6d5 !important; }
.theme-white .tt-suggestion.tt-is-under-cursor {
  background: #4ab6d5; }
.theme-white .table-primary .dataTables_wrapper .DT-per-page {
  border-color: #3b8fa6 !important; }
.theme-white .dropzone-box.dz-drag-hover {
  border-color: #4ab6d5; }
.theme-white .dropzone-box:hover .fa.fa-cloud-upload {
  background: #4ab6d5; }
.theme-white .md-editor.active {
  border-color: #4ab6d5; }
.theme-white .widget-rating .active a {
  color: #4ab6d5; }

/*# sourceMappingURL=themes.css.map */
